import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Permission } from "./permission_model";
import { v4 as uuidv4 } from 'uuid';
import { AdminRole } from "./admin_roles_enum";
import { Resource } from "./resources_enum";

@JsonObject()
export class AdminUser {
  @JsonProperty()
  private id: string;
  @JsonProperty()
  private deliveryAreaId: string;
  @JsonProperty()
  private firstName: string | null;
  @JsonProperty()
  private lastName: string | null;
  @JsonProperty()
  private email: string;
  @JsonProperty()
  private cellphone: string | null;
  @JsonProperty()
  private createdAtUtc: Date;
  @JsonProperty()
  private updatedAtUtc: Date | null;
  @JsonProperty({ type: Permission })
  private permissions: Permission[];
  @JsonProperty()
  private password: string;
  @JsonProperty()
  private isSuperAdmin: boolean;
  @JsonProperty()
  private isOwner: boolean;
  @JsonProperty()
  private hasTwoFactorKey: boolean;

  constructor(params?: {
    id?: string;
    deliveryAreaId: string;
    firstName?: string | null;
    lastName?: string | null
    email: string;
    cellphone?: string | null;
    createdAtUtc?: Date;
    updatedAtUtc?: Date;
    permissions?: Permission[];
    password?: string;
    isSuperAdmin?: boolean;
    isOwner?: boolean;
    hasTwoFactorKey?: boolean;
  }) {
    if (!params) return;
    this.id = params.id ?? uuidv4();
    this.deliveryAreaId = params.deliveryAreaId;
    this.firstName = params.firstName ?? null;
    this.lastName = params.lastName ?? null;
    this.email = params.email;
    this.cellphone = params.cellphone ?? null;
    this.createdAtUtc = params.createdAtUtc ?? new Date();
    this.updatedAtUtc = params.updatedAtUtc ?? null;
    this.permissions = params.permissions ?? [];
    this.password = params.password ?? "123456";
    this.isSuperAdmin = params.isSuperAdmin ?? false;
    this.isOwner = params.isOwner ?? false;
  }

  public getId() {
    return this.id;
  }

  public getName() {
    if (this.firstName != null) return `${this.firstName} ${this.lastName}`;
    return this.email?.split('@')[0] || 'Sem nome';
  }

  public getFirstName() {
    return this.firstName;
  }

  public getLastName() {
    return this.lastName;
  }

  public getEmail() {
    return this.email;
  }

  public getPhone() {
    return this.cellphone;
  }

  public getDeliveryAreaId() {
    return this.deliveryAreaId;
  }

  public getPermissions() {
    return this.permissions;
  }

  public getEmailName() {
    const atIndex = this.email.indexOf('@');
    return this.email.substring(0, atIndex);
  }

  public getIsSuperAdmin() {
    return this.isSuperAdmin;
  }

  public getIsOwner() {
    return this.isOwner;
  }

  public hasPermissions(permissions: Permission[]) {
    if (!permissions.length) return true;
    if (this.isOwner) return true;
    if (this.isSuperAdmin) return true;
    if (this.isOwner) return true; // TODO: this is temporary and should be reviewed
    return this.permissions.some(permission => permission.isSufficient(...permissions));
  }

  public hasPermissionsForRole(permissions: Permission[]): boolean {
    return permissions.every(permission => {
      const userPermission = this.permissions.find(userPerm =>
        userPerm.resource === permission.resource &&
        userPerm.read === permission.read &&
        userPerm.write === permission.write &&
        userPerm.delete === permission.delete
      );
      return !!userPermission;
    });
  }

  public getAdminRoleIndexByPermissions() {
    return Object.keys(AdminRole).indexOf(this.getAdminRoleByPermissions());
  }

  public getAdminRoleByPermissions() {
    if (this.hasPermissionsForRole(AdminUser.permissionsPerRole[AdminRole.SUPER_ADMIN])) return AdminRole.SUPER_ADMIN;
    if (this.hasPermissionsForRole(AdminUser.permissionsPerRole[AdminRole.ADMIN])) return AdminRole.ADMIN;
    if (this.hasPermissionsForRole(AdminUser.permissionsPerRole[AdminRole.ONLY_VIEWER])) return AdminRole.ONLY_VIEWER;
    return AdminRole.NO_PERMISSIONS;
  }

  public getImgUrl() {
    return '';
  }

  public getHasTwoFactorKey() {
    return this.hasTwoFactorKey;
  }

  public setIsSuperAdmin(isSuperAdmin: boolean) {
    this.isSuperAdmin = isSuperAdmin;
  }

  public setPermissions(permissions: Permission[]) {
    this.permissions = permissions;
  }

  public setPassword(password: string) {
    this.password = password;
  }

  public setHasTwoFactorKey(hasTwoFactorKey: boolean) {
    this.hasTwoFactorKey = hasTwoFactorKey;
  }

  static readonly permissionsPerRole = {
    [AdminRole.SUPER_ADMIN]: [
      new Permission({ read: true, write: false, delete: false, resource: Resource.DELIVERY_AREAS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.HR_ADMINS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.BASKET_PLANS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.USERS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.ADDRESSES }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.BRANCHES }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.CREDIT_CARD_PLANS }),
    ],
    [AdminRole.ADMIN]: [
      new Permission({ read: true, write: false, delete: false, resource: Resource.DELIVERY_AREAS }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.HR_ADMINS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.BASKET_PLANS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.USERS }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.ADDRESSES }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.BRANCHES }),
      new Permission({ read: true, write: true, delete: true, resource: Resource.CREDIT_CARD_PLANS }),
    ],
    [AdminRole.ONLY_VIEWER]: [
      new Permission({ read: true, write: false, delete: false, resource: Resource.DELIVERY_AREAS }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.HR_ADMINS }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.BASKET_PLANS }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.USERS }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.ADDRESSES }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.BRANCHES }),
      new Permission({ read: true, write: false, delete: false, resource: Resource.CREDIT_CARD_PLANS }),
    ],
    [AdminRole.NO_PERMISSIONS]: []
  }
}