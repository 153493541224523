import { BasketPlan } from "@/models/basket_plans/basket_plan_model";
import { ValueResult } from "@/models/value-result";
import { JsonSerializer } from "typescript-json-serializer";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";
import { AccountBalanceUpdate } from "@/models/users/account_balance_update";
import { PaginatedList } from "@/models/paginated_list";
import { Branch } from "@/models/branch_model";
import { AddressModel } from "@/models/address_model";
import { BranchAddresses } from "@/models/branch_addresses_model";

export class BasketPlansBFFBackofficeModule {

  static readonly controller = "basket-plans";
  static readonly serializer = new JsonSerializer();

  static async getBasketPlansBy(params: {
    populateDefaultProducts?: boolean;
    populateWithUpdatedUsersAt?: boolean;
    page?: number;
    pageSize?: number;
    deliveryAreaIds?: string[];
    ids?: string[];
    limitDateUtc?: Date;
    wasPaid?: boolean;
  }) {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}`, {
        params: {
          populateDefaultProducts: params.populateDefaultProducts ?? false,
          populateWithUpdatedUsersAt: true,
          populateWithUsersCount:true,
          page: params.page,
          pageSize: params.pageSize,
          deliveryAreaIds: params.deliveryAreaIds,
          ids: params.ids,
          limitDateUtc: params.limitDateUtc,
          wasPaid: params.wasPaid,
        }
      });

      const items = this.serializer.deserializeObjectArray(res.data.items, BasketPlan) as BasketPlan[];
      const brancheD = this.serializer.deserializeObjectArray(res.data.branches, Branch) as Branch[];
      const addressD = this.serializer.deserializeObjectArray(res.data.addresses, AddressModel) as AddressModel[];

      items.forEach((item) => {
        const branchPlan = brancheD.find((e) => e.getId() === item.getPickupBranchId());
        const address = addressD.find((e) => e.getId() === branchPlan?.getAddressId());
        item.setPickupBranch(branchPlan ?? null);
        item.setPickupAddress(address ?? null);
      });

      return new ValueResult({
        value: new PaginatedList({
          items: items,
          pageCount: res.data.pageCount,
        })
      });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async createBasketPlans(params: {
    deliveryAreaId: string;
    basketPlans: BasketPlan[],
  }): Promise<ValueResult<BasketPlan[] | null>> {
    try {
      const basketPlans = this.serializer.serialize(params.basketPlans);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/delivery-areas/${params.deliveryAreaId}/batch`, {
        basketPlans,
      });
      const item = this.serializer.deserializeObjectArray(res.data, BasketPlan) as BasketPlan[];
      return new ValueResult({
        value: item
      });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async requestRenewBasketPlan(
    params: {
    deliveryAreaId: string;
    basketPlanId: string;
    basketValue?: number;
    observation: string;
    deliveryDateUtc: string;
    branchId:string;
  }): Promise<ValueResult<boolean | null>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/request/renew/${params.basketPlanId}`, {
        params: {
          deliveryAreaId:params.deliveryAreaId,
          basketValue: params.basketValue,
          observation: params.observation,
          deliveryDateUtc: params.deliveryDateUtc,
          branchId:params.branchId
        }
      });
      return new ValueResult({ value: res.data });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async renewBasketPlan(params: {
    basketPlanId: string;
    data: {
      invoiceId?: string;
      operatorId?: string;
      basketName: string;
      newLimitDateUtc: Date;
      newDeliveryDateUtc: Date;
      value?: number;
      pickupBranchId?: string | null;
      deliveryType?: string | null;
    }
  }): Promise<ValueResult<AccountBalanceUpdate[] | null>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().patch(`/${this.controller}/renew/${params.basketPlanId}`, params.data);
      const info = this.serializer.deserializeObjectArray(res.data.usersAccountBalance, AccountBalanceUpdate) as AccountBalanceUpdate[];
      return new ValueResult({ value: info });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }
}