<script setup lang="ts">
import { defineProps } from 'vue';
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

defineProps<{
  usersCount: number;
}>();

</script>

<template>
  <div class="user-count-container">
    <div class="count-user">
      <FontAwesomeIcon class="information-icon" :fixed-width="true" :style="{
        width: '8px',
        height: '10px',
      }" :icon="Icons.imported.faUser" />
      <p class="count-text">{{ usersCount }}</p>
    </div>
  </div>
</template>

<style scoped>
.count-user {
  display: flex;
  padding: 4.5px 7px 4.5px 8px;
  align-items: center;
  gap: var(--semantic-size-semantic-spacing-inline-050, 4px);
  border-radius: 4px;
  border-radius: var(--semantic-size-semantic-border-radius-small, 4px);
  background: var(--semantic-color-tertiary-subtle, #FFE1EF);
  color: var(--semantic-color-tertiary-emphasis, #E02867);
  justify-content: center;
  width: fit-content;
}

.count-text {
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


</style>