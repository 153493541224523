<script setup lang="ts">
import { ref } from 'vue';
import { Icons } from '@/font-awesome';
import CommonButton from '../CommonButton.vue';
import { AdminUser } from '@/models/admins/admin_model';

const props = defineProps<{
  currentPage: number;
  adminUser: AdminUser;
  qrCodeUrl: string;
  secret: string;
}>();

const isCopied = ref(false);

function openUrl(url: string) {
  window.open(url, '_blank');
}

function handleCopyClick() {
  navigator.clipboard.writeText(props.secret);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 3000);
}

defineEmits<{
  (e: 'change-page', page: number): void
}>();

</script>

<template>
  <div class="qr-code-two-factor-content">
    <h1 class="title">Autenticação de 2 fatores</h1>
    <div style="height: var(--semantic-spacing-stack-100, 8px);"></div>
    <b class="sub-title" style="max-width: 360px;"><b>Escaneie o QR code</b> abaixo com o autenticador da sua preferência em seu telefone celular.</b>
    <div style="height: var(--semantic-spacing-stack-100, 8px);"></div>
    <img class="qrcode" :src="qrCodeUrl" />
    <div style="height: var(--semantic-spacing-stack-100, 8px);"></div>
    <p class="sub-title">Ou <b>utilize a chave abaixo no aplicativo:</b></p>
    <div style="height: var(--semantic-spacing-stack-100, 8px);"></div>
    <div class="secret-container">
      <h2 class="secret-text">{{ secret.toUpperCase() }}</h2>
      <CommonButton 
        class="copy-button"
        size="small" 
        action="cta"
        :icon="isCopied ? Icons.imported.faCheck : Icons.imported.faCopy"
        @click="handleCopyClick"
      ></CommonButton>
    </div>
    <div style="height: var(--semantic-spacing-stack-400, 32px);"></div>
    <p class="sub-title" style="font-size: 12px;">Ainda não tem um Autenticador?<b> Baixe agora:</b></p>
    <div style="height: var(--semantic-spacing-stack-100, 8px);"></div>
    <div class="app-stores">
      <img 
        @click=" () => openUrl('https://play.google.com/store/search?q=autenticador%202fa&c=apps')" 
        src="@/assets/icons/google_play_badge.svg" 
        alt="Google Play Store"
      />
      <img 
        @click="() => openUrl('https://www.apple.com/br/search/autenticador-2fa?src=globalnav')" 
        src="@/assets/icons/app_store_badge.svg" 
        alt="App Store"
      />
      <img 
        @click="() => openUrl('https://apps.microsoft.com/search?query=autenticador+2+fatores&hl=en-us&gl=BR')" 
        src="@/assets/icons/microsoft_badge.svg" 
        alt="Microsoft Store"
      />
    </div>
    <div style="height: var(--semantic-spacing-stack-300, 24px);"></div>
    <CommonButton 
      class="w-100" 
      style="max-width: 360px;" 
      @click="$emit('change-page', props.currentPage + 1)" 
      text="Já configurei meu autenticador"
    ></CommonButton>
  </div>
</template>

<style scoped>
.qr-code-two-factor-content {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--components-modal-color-default-title-color, #434049);
  text-align: center;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.sub-title {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sub-title b {
  font-weight: 600;
}

.secret-container {
  display: flex;
  padding-left: var(--semantic-spacing-inset-200, 16px);
  align-items: center;
  justify-content: center;
  gap: var(--semantic-spacing-inline-150, 12px);
  border-radius: var(--semantic-border-radius-default, 7px);
  background: var(--semantic-color-primary-subtle, #F7EDFF);
}

.secret-text {
  color: var(--semantic-color-primary-default, #8300C7);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.copy-button {
  height: 32px;
  width: 32px;
  padding: 0;
}

.qrcode {
  width: 185px;
  height: 185px;
  opacity: 0.7;
}

.app-stores {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--semantic-size-semantic-spacing-inline-100, 8px);
}

.app-stores img {
  cursor: pointer;
}

@media screen and (max-width: 390px) {

  .sub-title {
    font-size: 12px;
  }

  .secret-text {
    font-size: 13px;
  }

}


</style>