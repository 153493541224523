<script setup lang="ts">
import { PropType, ref } from 'vue';

const visible = ref(false);

defineProps({
  text: {
    type: String,
    required: true,
  },
  position: {
    type: String as PropType<'top' | 'bottom' | 'left' | 'right'>,
    default: 'top',
    required: false
  },
  canShow: {
    type: Boolean,
    default: true,
  }
});

function hideTooltip() {
  visible.value = false;
}

function showTooltip() {
  visible.value = true;
}
</script>

<template>
  <div class="tooltip-container" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <slot></slot>
    <span :class="['tooltiptext', position]" v-if="visible && canShow">{{ text }}</span>
  </div>
</template>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 120px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #555;
  background: #555;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 1.1;
  font-weight: 500;
}

.tooltip-container .tooltiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.tooltip-container .tooltiptext.top {
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container .tooltiptext.top::after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-color: #555 transparent transparent transparent;
}

.tooltip-container .tooltiptext.bottom {
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container .tooltiptext.bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-color: transparent transparent #555 transparent;
}

.tooltip-container .tooltiptext.left {
  top: 50%;
  right: 110%;
  transform: translateY(-50%);
}

.tooltip-container .tooltiptext.left::after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-color: transparent transparent transparent #555;
}

.tooltip-container .tooltiptext.right {
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
}

.tooltip-container .tooltiptext.right::after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-color: transparent #555 transparent transparent;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
