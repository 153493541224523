<script setup lang="ts">
import CommonButton from '@/components/CommonButton.vue';
import CommonToast from '@/components/CommonToast.vue';
import QRCode from '@/components/QRCode.vue';
import { Icons } from '@/font-awesome';
import { CommonToastInjection } from '@/infra/constants/injections';
import { useReqRenewBenefitsCardPlansStore } from '@/stores/benefits_card_plans/request_renew_benefits_cards_plan_store';
import { DOMUtils } from '@/utils/dom_utils';
import FormatUtils from '@/utils/format_utils';
import { defineProps, inject, Ref } from 'vue';

const reqRenewBenCardsPlanStore = useReqRenewBenefitsCardPlansStore;
const commonToast = inject<Ref<InstanceType<typeof CommonToast>>>(CommonToastInjection);

defineProps<{
  showScreen: boolean;
}>();

defineEmits<{
  (e: 'close'): void;
}>();

</script>

<template>
  <div
    class="payment-screen"
    :style="{
      display: showScreen ? 'flex' : 'none'
    }"
  >
    <div class="payment-card">
      <img src="@/assets/icons/clock_icon.svg" />
      <div style="height: var(--semantic-size-semantic-spacing-stack-200, 16px);"></div>
      <p class="big-bold-text">Aguardando pagamento</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-025, 2px);"></div>
      <p class="small-muted-text">A renovação será efetivada quando o pagamento for confirmado.</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px);"></div>
      <p class="plan-name-text">Plano {{ reqRenewBenCardsPlanStore.benefitsCardsPlan?.getPlanName() }}</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-025, 2px);"></div>
      <p class="monetary-text">R$ {{ FormatUtils.money(reqRenewBenCardsPlanStore.invoice?.getAmount()) }}</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px);"></div>

      <QRCode
        v-if="reqRenewBenCardsPlanStore.invoice?.getBrCode()"
        :value="reqRenewBenCardsPlanStore.invoice!.getBrCode()!"
      ></QRCode>

      <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px);"></div>
      <CommonButton
        :icon="Icons.imported.faCopy"
        text="Copiar código Pix"
        @click="() => {
          DOMUtils.copyTextToClipboard(reqRenewBenCardsPlanStore.invoice?.getBrCode());
          commonToast?.open({
            text: 'Código copiado para a área de transferência!',
          });
        }"
      ></CommonButton>
      <div style="height: var(--semantic-size-semantic-spacing-stack-200, 16px);"></div>
      <p class="small-muted-text">Pix Intermediado pela <b class="small-bold-text">Starkbank</b></p>
    </div>
    
    <div style="height: var(--semantic-size-semantic-spacing-stack-400, 32px);"></div>

    <div class="download-pdf-card">
      <img src="@/assets/icons/paper_plane_icon.svg" />
      <p class="big-bold-text">Precisa enviar pro financeiro aprovar?</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-025, 2px);"></div>
      <p class="small-bold-text">Baixe o PDF de pagamento para enviar!</p>
      <div style="height: 21px;"></div>
      <CommonButton
        :icon="Icons.imported.faCopy"
        action="secondary"
        type="outlined"
        text="Copiar link de pagamento"
        @click="() => {
          DOMUtils.copyTextToClipboard(reqRenewBenCardsPlanStore.invoice?.getLink());
          commonToast?.open({
            text: 'Link copiado para a área de transferência!',
          });
        }"
      ></CommonButton>
    </div>

    <div style="height: 48px;"></div>

    <div class="d-flex justify-content-between">
        <CommonButton
          @click="$emit('close'); reqRenewBenCardsPlanStore.$reset();"
          type="outlined"
          text="Fechar"
          action="secondary"
        ></CommonButton>
      </div>
  </div>
</template>

<style scoped>

.payment-screen {
  display: flex;
  flex-direction: column;
}

.payment-card {
  display: flex;
  padding: 32px 48px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: var(--semantic-size-semantic-border-radius-default, 7px);
  border: 1px solid var(--semantic-color-primary-default, #8300C7);
  background: var(--semantic-color-primary-subtle, #F7EDFF);
}

.download-pdf-card {
  display: flex;
  padding: 32px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 7px;
  border: 1px solid var(--semantic-color-neutral-border-emphasis, #CDC8D7);
}

.big-bold-text {
  color: var(--semantic-color-primary-default, #8300C7);
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.small-muted-text {
  color: var(--semantic-color-fg-muted, #6E6979);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.monetary-text {
  color: var(--semantic-color-tertiary-default, #FF508C);
  font-family: 'Montserrat';
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.small-bold-text {
  color: var(--semantic-color-fg-muted, #6E6979);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.plan-name-text {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

</style>