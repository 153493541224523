<script setup lang="ts">

defineProps<{
  color: string;
}>();

</script>

<template>
  <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3117_9918)">
      <path
        d="M-18.3846 -15.9632C-16.3468 -16.9512 -14.1963 -17.746 -11.9545 -18.3516C-14.3233 -18.0152 -16.5063 -17.2249 -18.3846 -15.9632Z"
        :fill="color ?? '#8300C7'" />
      <path
        d="M-36.564 27.7122C-36.2703 30.5125 -35.5131 33.4255 -34.3187 36.3325C-35.2764 33.4384 -36.0276 30.5565 -36.564 27.7122Z"
        :fill="color ?? '#8300C7'" />
      <path
        d="M-27.4098 -9.55594C-36.4812 -0.450397 -39.318 13.138 -36.5648 27.7121C-36.0284 30.5564 -35.2773 33.4383 -34.3195 36.3324C-34.3181 36.3338 -34.3165 36.3397 -34.3147 36.3444C-32.3556 41.0957 -29.2246 45.8287 -25.0121 50.0254C-12.7197 62.271 4.11254 65.3056 12.5828 56.8034C16.9707 52.3991 18.2547 45.7635 16.7628 38.6853C16.5282 37.9368 16.3432 37.189 16.2029 36.452C15.3579 31.9804 16.2201 27.8124 18.9839 25.0084L19.0219 24.9702C26.4773 17.4263 23.7243 2.56059 12.8555 -8.26751C5.24877 -15.8457 -4.31953 -19.4407 -11.9545 -18.3516C-14.1963 -17.746 -16.3468 -16.9512 -18.3846 -15.9632C-21.7203 -14.349 -24.7572 -12.2185 -27.4098 -9.55594Z"
        :fill="color ?? '#8300C7'" />
    </g>
    <defs>
      <clipPath id="clip0_3117_9918">
        <rect width="75.1671" height="68.0223" fill="white"
          transform="matrix(-0.708433 -0.705779 -0.705779 0.708433 45.2539 24.0095)" />
      </clipPath>
    </defs>
  </svg>  
</template>

<style scoped>
</style>