import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class BasketProductModel {

  @JsonProperty()
  awsId: string | null;

  @JsonProperty()
  sku: string;

  @JsonProperty()
  mandatoryQuantity: number;
  
  @JsonProperty()
  suggestedQuantity: number;

  @JsonProperty()
  price: number;

  constructor(params?: {
    awsId?: string;
    sku: string;
    mandatoryQuantity: number;
    suggestedQuantity: number;
    price: number;
  }) {
    if (!params) return;
    this.awsId = params.awsId ?? null;
    this.sku = params.sku;
    this.mandatoryQuantity = params.mandatoryQuantity;
    this.suggestedQuantity = params.suggestedQuantity;
    this.price = params.price;
  }

  public getTotalMandatoryPrice(): number {
    return this.mandatoryQuantity * this.price;
  }

  public getTotalPrice(): number {
    return this.suggestedQuantity * this.price;
  }

  static getSKUColumn() {
    return "SKU";
  }

  static getPriceColumn() {
    return "Preço";
  }

  static getSuggestedQuantityColumn() {
    return "Quantidade Sugerida";
  }
  
  static getMandatoryQuantityColumn() {
    return "Quantidade Obrigatoria";
  }
}