import BffFoodPassBackofficeApiRequester from "@/infra/bff_backoffice/bff_backoffice_api_requester";
import { AddressModel } from "@/models/address_model";
import { Branch } from "@/models/branch_model";
import { BranchAddresses } from "@/models/branch_addresses_model";
import { PaginatedList } from "@/models/paginated_list";
import { ValueResult } from "@/models/value-result";
import { JsonSerializer } from "typescript-json-serializer";

export class BranchAddressesBFFBackofficeModule {

  static readonly controller = "branch-addresses"
  static readonly serializer = new JsonSerializer()
  
  static async getBranchAddressesByDeliveryAreaId(params: {
    deliveryAreaId: string,
    page?: number,
    pageSize?: number
  }) {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}/${params.deliveryAreaId}`, {
        params: {
          page: params.page,
          pageSize: params.pageSize
        }
      });

      const branchCreated = this.serializer.deserializeObjectArray(res.data.branches, Branch) as Branch[];
      const addressesCreated = this.serializer.deserializeObjectArray(res.data.addresses, AddressModel) as AddressModel[];

      const branchAddresses = new BranchAddresses({
        branches: branchCreated,
        addresses: addressesCreated
      })

      return new ValueResult({
        value: {
          branchAddresses,
          pageCount: res.data.pageCount
        }
      });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async createBranchAddresses(params: {
    deliveryAreaId: string;
    branches: {
      branch: {
        name: string
      },
      address: AddressModel
    }[]
  }) {
    try {
      const branches = this.serializer.serialize(params.branches);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/${params.deliveryAreaId}/batch`, {
        branches
      });
 
      const branchCreated = this.serializer.deserializeObjectArray(res.data.branches, Branch) as Branch[];
      const addressesCreated = this.serializer.deserializeObjectArray(res.data.addresses, AddressModel) as AddressModel[];

      const branchAddresses = new BranchAddresses({
        branches: branchCreated,
        addresses: addressesCreated
      })

      return new ValueResult({
        value: branchAddresses
      });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async deleteBranchAddress(params: {
    deliveryAreaId: string;
    branchId: string;
  }) {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().delete(`/${this.controller}/${params.deliveryAreaId}/branches/${params.branchId}`);
      return new ValueResult({
        value: res.data
      });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

}