<script setup lang="ts">
import { PropType, defineProps } from 'vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
  icon: {
    type: Object as PropType<IconDefinition>,
    default: null,
  },
  iconTitle: {
    type: String,
    default: "",
  },
  size: {
    type: String as PropType<'small' | 'medium'>,
    default: "medium",
  },
  color: {
    type: String as PropType<'primary' | 'success' | 'error'>,
    default: "primary",
  },
});

defineEmits<{
  (e: 'edit'): void;
  (e: 'back-to-delivery-area'): void;
  (e: 'logout'): void;
}>();

function getColor() {
  switch (props.color) {
    case 'error':
      return {
        background: '#f6dede',
        color: '#b90b0b'
      }
    case 'success':
      return {
        background: '#DEF6E8',
        color: '#067E36'
      };
    default:
      return {
        background: 'var(--semantic-color-primary-subtle, #F7EDFF',
        color: 'var(--semantic-color-primary-default, #8300C7)'
      }
  }
}

function getSize() {
  switch (props.size) {
    case 'small':
      return '4.5px 7px 4.5px 8px';
    case 'medium':
      return 'var(--semantic-spacing-inset-100, 8px) var(--semantic-spacing-inset-200, 16px)';
  }
}

</script>

<template>
  <div class="tag-content" :style="{ background: getColor().background, color: getColor().color, padding: getSize() }">
    <div class="icon-container">
      <FontAwesomeIcon v-if="icon" :icon="icon" class="icon" ></FontAwesomeIcon>
      <p>{{ iconTitle }}</p>
    </div>
    <p>{{ message }}</p>
  </div>
</template>

<style scoped>
.tag-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--semantic-border-radius-small, 4px);

  font-size: 12px;
  font-weight: 300;

}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.icon-container p {
  font-size: 12px;
  font-weight: 600;
}

.icon {
  width: var(--semantic-size-icons-125, 10px);
  height: var(--semantic-size-icons-125, 10px);
}

</style>