import { AdminUser } from '@/models/admins/admin_model';
import { LoginResponseModel } from '@/models/login_response_model';
import { JsonSerializer } from 'typescript-json-serializer';

class LoggedAdminStorage {

  private static userKey = 'userInfo';
  private static tokenKey = 'tokenInfo';
  private static isTwoFactorAuthorized = 'isTwoFactorAuthorized';
  private static expiresAt = 'expiresAt';
  private static isCreatingPassword = 'isCreatingPassword';

  static setUser(loginData: LoginResponseModel) {
    const jsonSerializer = new JsonSerializer();
    const userValue = JSON.stringify(jsonSerializer.serialize(loginData.getUser()));
    if (!userValue) {
      return;
    }
    window.localStorage.setItem(LoggedAdminStorage.userKey, JSON.stringify(loginData.getUser()));
    window.localStorage.setItem(LoggedAdminStorage.tokenKey, loginData.getToken());
    window.localStorage.setItem(LoggedAdminStorage.expiresAt, loginData.getExpiresAt()?.getTime().toString() ?? '0');
    window.localStorage.setItem(LoggedAdminStorage.isTwoFactorAuthorized, loginData.getIsTwoFactorAuthorized()?.toString());
  }

  static updateUser(user: AdminUser) {
    const currentUser = LoggedAdminStorage.getUser();
    if (!currentUser) return;
    if (currentUser.getId() !== user.getId()) return; 
    
    const jsonSerializer = new JsonSerializer();
    const userValue = JSON.stringify(jsonSerializer.serialize(user));
    if (!userValue) {
      return;
    }
    window.localStorage.setItem(LoggedAdminStorage.userKey, JSON.stringify(user));
  }

  static clearUser() {
    window.localStorage.removeItem(LoggedAdminStorage.userKey);
    window.localStorage.removeItem(LoggedAdminStorage.tokenKey);
    window.localStorage.removeItem(LoggedAdminStorage.isTwoFactorAuthorized);
    window.localStorage.removeItem(LoggedAdminStorage.expiresAt);
    window.localStorage.removeItem(LoggedAdminStorage.isCreatingPassword);
  }

  static getUser(): AdminUser | null {
    const userStr = JSON.parse(window.localStorage.getItem(LoggedAdminStorage.userKey) ?? 'null');
    const jsonSerializer = new JsonSerializer();
    const user = jsonSerializer.deserializeObject(userStr, AdminUser);
    if (!user) return null;

    return user;
  }

  static getToken(): string | null {
    return window.localStorage.getItem(LoggedAdminStorage.tokenKey);
  }

  static setToken(token: string) {
    window.localStorage.setItem(LoggedAdminStorage.tokenKey, token);
  }

  static getExpiresAt(): Date | null {
    const expiresAt = window.localStorage.getItem(LoggedAdminStorage.expiresAt);
    if (!expiresAt) return null;
    return new Date(parseInt(expiresAt));
  }

  static getIsTwoFactorAuthorized(): boolean {
    const isTwoFactorAuthorized = window.localStorage.getItem(LoggedAdminStorage.isTwoFactorAuthorized) == 'true';
    return isTwoFactorAuthorized;
  }

  static setIsCreatingPassword(isCreatingPassword: boolean) {
    window.localStorage.setItem(LoggedAdminStorage.isCreatingPassword, isCreatingPassword.toString());
  }

  static getIsCreatingPassword(): boolean {
    return window.localStorage.getItem('isCreatingPassword') == 'true';
  }
}

export default LoggedAdminStorage;