<script setup lang="ts">
import { DOMUtils } from '@/utils/dom_utils';
import { onMounted, ref } from 'vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const isShowingContextMenu = ref(false);
const contextMenuElem = ref<HTMLDivElement>();
const quadrant = ref({
  isSuperior: false,
  isLeft: false,
});

const props = defineProps<{
  border?: boolean;
  buttons: {
    text: string;
    action: () => void;
    visible?: boolean;
    icon?: IconDefinition;
    color?: 'cta' | 'secondary' | 'error' | 'clear';
  }[]
}>();

defineExpose({
  getElement: () => contextMenuElem.value,
});

onMounted(() => {
  quadrant.value = DOMUtils.detectQuadrant(contextMenuElem.value!);
});

</script>

<template>
  <div v-if="props.buttons.length > 0 && props.buttons.some(button => button.visible ?? true)"
    class="context-menu-container" @mouseleave="isShowingContextMenu = false" ref="contextMenuElem" @click="(e: MouseEvent) => {
      isShowingContextMenu = !isShowingContextMenu;
      e.stopPropagation();
    }"
  >
    <i
      class="bi bi-three-dots-vertical open-context-menu"
    ></i>
    <div
      v-show="isShowingContextMenu"
      class="context-menu"
      :style="{
        top: quadrant.isSuperior ? '10px' : 'unset',
        bottom: quadrant.isSuperior ? 'unset' : '10px',
        left: quadrant.isLeft ? '0' : 'unset',
        right: quadrant.isLeft ? 'unset' : '0',
        boxShadow: isShowingContextMenu ? '0 0 5px 1px rgba(0, 0, 0, 0.1)' : 'none',
      }"
    >
      <p
        v-for="button in props.buttons"
        v-show="button.visible ?? true"
        :key="button.text"
        @click="(e: MouseEvent) => {
          e.stopPropagation();
          button.action();
          isShowingContextMenu = false;
        }"
        :style="button.color ? {color:`var(--components-button-color-${button.color}-filled-default-background-color)`}:{}"
        >
        <FontAwesomeIcon
          v-if="button.icon"
          :icon="button.icon"
          :style="{ marginRight: '8px' }"
        />
        {{ button.text }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.context-menu-container {
  position: relative;
  font-size: 16px;
}

.open-context-menu {
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  color: var(--token-semantic-color-fg-muted, #6E6979);
  cursor: pointer;
}

.open-context-menu:hover {
  background: var(--options-color-gray-100, #F2F1F3);
}

.context-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--token-semantic-color-bg-subtle);
  z-index: 5;
  padding: 12px;
  border-radius: 8px;
  gap: 10px;
  transition: all 0.2s ease-in-out;
}

.context-menu:hover {
  cursor: pointer;
}

.context-menu>p {
  cursor: pointer;
  padding: 16px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  text-wrap: nowrap;
}

.context-menu>p:hover {
  background-color: var(--token-semantic-color-bg-surface);
}
</style>