<script setup lang="ts">
import { inject, Ref, ref } from 'vue';
import { Icons } from '@/font-awesome';
import CommonButton from '../CommonButton.vue';
import { WhatsappUtils } from '@/utils/whatsapp_utils';
import Constants from '@/infra/constants/constants';
import { AdminUser } from '@/models/admins/admin_model';
import { useTwoFactorAuthStore } from '@/stores/two_factor_auth/two_factor_auth_store';
import { AlertModalInjectionV2, LoadingModalInjection, TwoFactorAuthModalInjection } from '@/infra/constants/injections';
import LoadingModal from '../modals/LoadingModal.vue';
import AlertModalV2 from '../modals/AlertModalV2.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import TwoFactorAuthModal from './TwoFactorAuthModal.vue';

const codeInputs = ref<string[]>(Array(6).fill(''));
const inputRefs = ref<Array<HTMLInputElement | null>>([]);
const maxLength = ref<number>(1);
const store = useTwoFactorAuthStore();
const errorText = ref<string | null>(null);
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>> | null>(LoadingModalInjection);
const alertModalV2 = inject<Ref<InstanceType<typeof AlertModalV2>>>(AlertModalInjectionV2);
const fixedIcon = Icons.imported.faCircleExclamation;
const twoFactorAuthModal = inject<Ref<InstanceType<typeof TwoFactorAuthModal>> | null>(TwoFactorAuthModalInjection);

const props = defineProps<{
  currentPage: number;
  adminUser: AdminUser;
  qrCodeUrl: string;
  secret: string;
}>();

const emit = defineEmits<{
  (e: 'on-code-input-change', code: string): void;
  (e: 'change-page', page: number): void;
}>();

async function validateCode() {
  try {
    loadingModal?.value?.open({ zIndex: 10000 } );
    const res = await store.submit();

    if (res.isError()) {
      alertModalV2?.value?.open({
        zIndex: 10000,
        title: 'Código Inválido',
        size: 'medium',
        icon: fixedIcon,
        modalType: 'error',
        content: res.getError() ?? 'Erro desconhecido'
      });
      return;
    }
    
    emit('change-page', props.currentPage + 1);
  } finally {
    loadingModal?.value?.close();
  }
}

function handlePaste(event: ClipboardEvent) {
  event.preventDefault();
  const pastedData = event.clipboardData?.getData('text') || '';
  const codeCharacters = pastedData.split('').slice(0, 6);
  codeCharacters.forEach((character, index) => {
    if (index < codeInputs.value.length) codeInputs.value[index] = character;
  });

  emit('on-code-input-change', codeInputs.value.join(''));
}

function handleKeyboardEvent(event: KeyboardEvent, index: number) {
  if (event.key === 'Backspace') {
    event.preventDefault();
    if (codeInputs.value[index] === '') {
      if (index > 0) inputRefs.value[index - 1]?.focus();
      codeInputs.value[index - 1] = '';
    }
    codeInputs.value[index] = '';
    emit('on-code-input-change', codeInputs.value.join(''));
  }
}

function handleInput(event: Event, index: number) {
  let inputValue = (event.target as HTMLInputElement).value;
  inputValue = inputValue.replace(/\D/g, '');
  if (inputValue.length > 1) codeInputs.value[index] = inputValue.charAt(0);
  else codeInputs.value[index] = inputValue;
  if (index < codeInputs.value.length - 1 && inputValue.length === 1) inputRefs.value[index + 1]?.focus();
}

function backButton() {
  const hasTwoFactorKey = props.adminUser.getHasTwoFactorKey();
  if (!hasTwoFactorKey) {
    return emit('change-page', props.currentPage - 1);
  }
  const twoFactorAuthModalInstance = twoFactorAuthModal?.value;
  if (twoFactorAuthModalInstance) {
    twoFactorAuthModalInstance.close();
  }
}

</script>

<template>
  <FontAwesomeIcon class="back-btn" :icon="Icons.imported.faArrowLeft" @click="backButton"/>
  <div class="code-two-factor-content">
    <img src="@/assets/icons/two_factor.svg" alt=" Two Factor"/>
    <div style="height: var(--semantic-spacing-stack-300, 24px);"></div>
    <div class="d-flex flex-column gap-2">
      <h1 class="title">Insira o código</h1>
      <b class="sub-title">Digite abaixo o <b>código</b> enviado ao aplicativo de <b>Autenticação</b> em seu telefone celular.</b>
    </div>
    <div style="height: var(--semantic-spacing-stack-400, 32px);"></div>
    <form class="input-qr-code-container" @submit.prevent autocomplete="new-password">
      <input 
        :maxlength="maxLength"
        v-for="(_, index) in codeInputs" 
        :key="index" 
        :ref="(el => inputRefs[index] = el as HTMLInputElement)"
        v-model="codeInputs[index]" 
        class="input-qr-code" 
        type="text" 
        @paste="handlePaste"
        @input="(e) => handleInput(e, index)"
        @keydown="(e) => handleKeyboardEvent(e, index)"
        @change="$emit('on-code-input-change', codeInputs.join(''))"
      />
    </form>
    <div style="height: var(--semantic-spacing-stack-600, 48px);">
      <p
        v-if="errorText"
        class="sub-title"
        :style="{ color: `#C63D3D`, margin: `8px` }"
      ><b>{{ errorText }}</b></p>
    </div>
    <CommonButton class="w-100" :action="codeInputs.join('').length === 6 ? 'cta' : 'disabled'" @click="validateCode()" text="Enviar código"></CommonButton>
    <div style="height: var(--semantic-spacing-stack-200, 16px);"></div>
    <div class="button-info">
      <CommonButton
        type="text"
        :icon="Icons.imported.faWhatsapp"
        text="Pedir ajuda ao suporte"
        @click="() => WhatsappUtils.textNumber(Constants.foodPassSupportNumber)"
        style="color: var(--semantic-color-fg-default, #5D5867);"
      ></CommonButton>
    </div>
  </div>
</template>

<style scoped>

.code-two-factor-content {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
}

.title {
  color: var(--components-modal-color-default-title-color, #434049);
  text-align: center;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.sub-title {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sub-title b {
  font-weight: 600;
}

.input-qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.input-qr-code-container input:nth-child(3) {
  margin-right: 8px;
}

.input-qr-code {
  display: flex;
  width: 49px;
  height: 49px;
  border-radius: var(--semantic-border-radius-default, 7px);
  border: 1px solid var(--components-input-default-border-color, #CDC8D7);
  background: var(--components-input-default-background-color, rgba(0, 0, 0, 0.00));
  text-align: center;
  color: var(--components-input-default-label-color, #6E6979);
}

.input-qr-code:hover {
  border: 1px solid var(--components-input-hovered-border-color, #5D5867)
}

.input-qr-code:focus {
  border: 1px solid var(--components-input-focused-border-color, #8300C7)
}

.button-info {
  display: flex;
  justify-content: center;
}

.back-btn {
  cursor: pointer;
  position: absolute;
  top: -33px;
  left: -53px;
  color: var(--semantic-color-fg-muted, #6E6979);
}

@media screen and (max-width: 395px) {

  .sub-title {
    font-size: 12px;
  }

  .input-qr-code-container input:nth-child(3) {
    margin-right: 4px;
  }

  .input-qr-code-container {
    gap: 6px;
  }

  .input-qr-code {
    width: 42px;
  }
  
}

</style>