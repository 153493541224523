
<script setup lang="ts">
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { defineProps, defineEmits } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps<{
  name: string;
  label: string;
  value: boolean;
  icon: IconDefinition;
}>();

const emit = defineEmits<{
  (e: 'change', evt: Event, value: boolean): void;
}>();

</script>

<template>
  <div class="text-checkbox-container">
  <FontAwesomeIcon v-if="icon" :icon="icon" class="icon" ></FontAwesomeIcon>
  </div>
</template>

<style scoped>
.text-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.text-checkbox-container:hover{
  cursor: pointer;
}

</style>
