import { RouteLocationNormalized } from "vue-router";
import { AuthRequirement, IRoute, routes } from '@/router';
import LoggedAdminStorage from "@/infra/local_storage/logged_admin_storage";

async function loginGuard(to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<string | null> {
  const toRouteData: IRoute | undefined = Object.values(routes).find(route => route.name === to.name);
  const fromRouteData: IRoute | undefined = Object.values(routes).find(route => route.name === from.name);
  const user = LoggedAdminStorage.getUser();

  // ROUTE PERMISSIONS
  const neededPermissions = toRouteData?.requiredPermissions ?? [];
  const hasPermissions = user?.hasPermissions(neededPermissions) ?? true;
  if (!hasPermissions || (!user?.getIsSuperAdmin() && toRouteData?.isSuperAdminRoute)) return routes.unauthorized.path;

  // LOGIN
  const tokenExpiration = (LoggedAdminStorage.getExpiresAt() ?? new Date(0));
  const isLogged = user !== null && tokenExpiration > new Date();
  const noAuthRoutes = Object.values(routes).filter(route => route.authType == AuthRequirement.NOT_LOGGED).map(route => route.name);
  const isGoingToNoAuthRoute = noAuthRoutes.includes(to.name as string);
  if (!isGoingToNoAuthRoute && toRouteData?.authType == AuthRequirement.DONT_CARE) return null;
  if (isGoingToNoAuthRoute && isLogged && user.getIsSuperAdmin()) return routes.deliveryAreas.path;
  if (isGoingToNoAuthRoute && isLogged && !user.getIsSuperAdmin()) return routes.welcome.path;
  if (toRouteData?.name !== routes.resetPassword.name && LoggedAdminStorage.getIsCreatingPassword()) return routes.resetPassword.path;
  if (!isGoingToNoAuthRoute && !isLogged) return routes.login.path;

  return null;
}

export default loginGuard;