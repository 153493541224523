import FormatUtils from "@/utils/format_utils";
import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class RenewalCardPlan {
  @JsonProperty()
  private amount: number;
  @JsonProperty()
  private name: string;
  @JsonProperty()
  private cnpj: string;
  @JsonProperty()
  private tags: string[];

  // return only
  @JsonProperty()
  private id?: string;
  @JsonProperty()
  private brCode?: string;
  @JsonProperty()
  private status?: string;
  @JsonProperty()
  private expirationDate?: Date;
  @JsonProperty()
  private link?: string;

  constructor(params: {
    amount: number;
    name: string;
    cnpj: string;
    tags: string[];
    id?: string;
    brCode?: string;
    status?: string;
    expirationDate?: Date;
    link?: string;
  }) {
    if (!params) return;
    this.amount = params.amount;
    this.name = params.name;
    this.cnpj = params.cnpj;
    this.tags = params.tags;

    this.id = params.id;
    this.brCode = params.brCode;
    this.status = params.status;
    this.expirationDate = params.expirationDate;
    this.link = params.link;
  }

  public getAmount() {
    return this.amount;
  }

  public getName() {
    return this.name;
  }

  public getCnpj() {
    return this.cnpj;
  }

  public getTags() {
    return this.tags;
  }

  public getId() {
    return this.id;
  }

  public getBrCode() {
    return this.brCode;
  }

  public getStatus() {
    return this.status;
  }

  public getExpirationDate() {
    return this.expirationDate;
  }

  public getLink() {
    return this.link;
  }
}