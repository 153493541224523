import { defineStore } from 'pinia';
import { ValueResult } from '@/models/value-result';
import { HRAdminsBFFBackofficeModule } from '@/infra/bff_backoffice/hr_admins/hr_admins_bff_backoffice_module';
import { AdminUser } from '@/models/admins/admin_model';
import FormatUtils from '@/utils/format_utils';

interface IState {
 firstName?: string;
 lastName?: string;
 nickName?: string;
 birthDate?: string;
 cpf?: string;
 phone?: string;
 cpfError?: string | null;
 firstNameError?: string | null;
 lastNameError?: string | null;
 birthDateError?: string | null;
 phoneError?: string | null;
 nickNameError?: string | null;
}

export const useEditHrAdmin = defineStore('edit_hr_admin', {
  state: (): IState => ({
    firstName: undefined,
    lastName: undefined,
    nickName: undefined,
    birthDate: undefined,
    cpf: undefined,
    phone: undefined,
    cpfError: null,
    firstNameError: null,
    lastNameError: null,
    birthDateError: null,
    phoneError: null,
    nickNameError: null
  }),
  actions: {
    async submit(): Promise<ValueResult<AdminUser | null > | void > {
        const result = this.validate();
        if (!result) return;
        const res = await HRAdminsBFFBackofficeModule.update({
            firstName: this.firstName,
            lastName: this.lastName,
            cellphone: this.phone
          })
        return res;
    },
    validate(): boolean {
      // if(!this.nickName?.length) {
      //   this.nickNameError = "Apelido inválido";
      // }
      if(!this.firstName?.length) {
        this.firstNameError = "Nome inválido";
      }
      if(!this.lastName?.length) {
        this.lastNameError = "Sobrenome inválido";
      }
      // if(!FormatUtils.isValidCpf(this.cpf)) {
      //   this.cpfError = "CPF inválido";
      // }
      if(!FormatUtils.isValidCellphone(this.phone)) {
        this.phoneError = "Número de telefone inválido";
      }
      // if(!this.birthDate?.length) {
      //   this.birthDateError = "Data de nascimento inválida";
      // }

    return this.isValid();
  },
  isValid(): boolean {
    // if (this.nickNameError !== null) return false;
    if (this.firstNameError !== null) return false;
    if (this.lastNameError !== null) return false;
    //if (this.birthDateError !== null) return false;
    if (this.phoneError !== null) return false;
    return true;
  },

    setFirstName(firstName: string) {
      this.firstName = firstName;
    },
    setLastName(lastName: string) {
      this.lastName = lastName;
    },
    setPhone(phone: string) {
      this.phone = phone;
    }
  },
});