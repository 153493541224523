import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { DeliveryType } from "../delivery_type_enum";

@JsonObject()
export class RenewRequest {

  @JsonProperty()
  private basketPlanId?: string | null;

  @JsonProperty()
  private deliveryType: DeliveryType | undefined;

  @JsonProperty()
  private observation?: string | null;

  @JsonProperty({ type: Date })
  private deliveryDateUtc?: Date | null;

  @JsonProperty()
  private branchId: string | null;

  @JsonProperty()
  private basketValue?: number;

  constructor(params?: {
    basketPlanId: string,
    observation?: string,
    deliveryDateUtc: Date,
    deliveryType?: DeliveryType,
    basketValue: number,
    branchId?: string | null,
  }) {
    if (!params) return;
    this.basketPlanId = params.basketPlanId;
    this.deliveryType = params.deliveryType;
    this.observation = params.observation ?? null;
    this.deliveryDateUtc = params.deliveryDateUtc;
    this.branchId = params.branchId ?? null;
    this.basketValue = params.basketValue;

  }

  public getBasketPlanId() {
    return this.basketPlanId;
  }

  public getObservation() {
    return this.observation;
  }

  public getDeliveryType() {
    return this.deliveryType;
  }

  public getValue() {
    return this.basketValue;
  }

  public getDeliveryDateUtc() {
    return this.deliveryDateUtc;
  }

  public getBranchId() {
    return this.branchId;
  }

}