import { ValueResult } from "@/models/value-result";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";
import { AdminUser } from "@/models/admins/admin_model";
import { JsonSerializer } from "typescript-json-serializer";
import { LoginResponseModel } from "@/models/login_response_model";

export class HRAdminsBFFBackofficeModule {

  static readonly controller = "hr-admins";
  static readonly serializer = new JsonSerializer();

  static async login(params: {
    email: string;
    password: string;
  }): Promise<ValueResult<LoginResponseModel | null>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/login`, params);
      const value = this.serializer.deserializeObject(res.data, LoginResponseModel);
      return new ValueResult({ value });
    } catch (e: any) {
      const response = JSON.parse(e.request.response);
      const errorMessage = response.error.message ?? "Login invalido!";
      return new ValueResult({
        value: null,
        error: errorMessage,
      });
    }
  }

  static async create(admin: AdminUser): Promise<ValueResult<AdminUser | null>> {
    try {
      const json = this.serializer.serializeObject(admin);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}`, json);
      const user = this.serializer.deserializeObject(res.data, AdminUser) as AdminUser;
      return new ValueResult({
        value: user,
      });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async update(params: {
    password?: string;
    firstName?: string;
    lastName?: string;
    cellphone?: string;
    isSuperAdmin?: boolean;
  }) {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().patch(`/${this.controller}`, params);
      const value = this.serializer.deserializeObject(res.data[0], AdminUser) as AdminUser;
      return new ValueResult({ value });
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async resetPassword(params: {
    email: string;
  }) {
    try {
      await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/reset-password`, params);
      return new ValueResult<void>();
    } catch (e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async me(): Promise<ValueResult<LoginResponseModel | null>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}/me`);
      const value = this.serializer.deserializeObject(res.data, LoginResponseModel);
      return new ValueResult({ value });
    } catch(e: any) {
      return new ValueResult({
        value: null,
        error: "Erro ao carregar perfil!",
      });
    }
  }

}