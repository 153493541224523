import { defineStore } from 'pinia';
import { BasketPlansBFFBackofficeModule } from '@/infra/bff_backoffice/basket_plans/basket_plans_backoffice_module';
import { BasketPlan } from '@/models/basket_plans/basket_plan_model';
import { BranchAddressesBFFBackofficeModule } from '@/infra/bff_backoffice/branch_addresses/branch_addresses_backoffice_module';
import { BranchAddresses } from '@/models/branch_addresses_model';
import { DeliveryType } from '@/models/delivery_type_enum';
import { GlobalData } from '@/global';
import FormatUtils from '@/utils/format_utils';

interface IState {
  planName: string;
  filterAlcoholics: boolean;
  autoGenDefaultBasket: boolean;
  invoiceType: string;
  basketValue: string;
  deliveryAreaId: string;
  deliveryType: DeliveryType | null;
  pickupBranchId: string | null;
  branchAddresses: BranchAddresses | null;
  planNameError: string | null;
  basketValueError: string | null;
}

export const useCreateBasketPlanStore = defineStore('create_basket_plan', {
  state: (): IState => ({
    planName: "",
    filterAlcoholics: true,
    autoGenDefaultBasket: true,
    invoiceType: "SERVICE_INVOICE",
    basketValue: "",
    deliveryAreaId: "",
    deliveryType: null,
    pickupBranchId: null,
    branchAddresses: null,
    planNameError: null,
    basketValueError: null,
  }),
  actions: {

    async fetchBranches(params: {
      deliveryAreaId: string,
    }) {
      const branchAddresses = await BranchAddressesBFFBackofficeModule.getBranchAddressesByDeliveryAreaId({
        deliveryAreaId: params.deliveryAreaId,
      });
      if (branchAddresses.isError()) return;
      this.branchAddresses = branchAddresses.getValue()?.branchAddresses ?? null;
    },
    async submit(params: {
      deliveryAreaId: string,
    }) {
      this.basketValue = FormatUtils.formatNumberText(this.basketValue);
      const validation = this.validate();
      if (!validation) return validation;

      const basketPlan = new BasketPlan({
        basketValue: parseFloat(this.basketValue.replaceAll(",", ".")),
        filterAlcoholics: this.filterAlcoholics,
        autoGenDefaultBasket: this.autoGenDefaultBasket,
        invoiceType: this.invoiceType as "SERVICE_INVOICE",
        planName: this.planName,
        deliveryAreaId: this.deliveryAreaId,
        pickupBranchId: this.pickupBranchId,
        deliveryType: this.deliveryType as DeliveryType,
        createdAtUtc: new Date(),
      });
      const result = await BasketPlansBFFBackofficeModule.createBasketPlans({
        basketPlans: [
          basketPlan,
        ],
        deliveryAreaId: params.deliveryAreaId,
      });
      return result;
    },
    validate(): boolean {
      if (this.planName.length < 6) {
        this.planNameError = "O nome do plano deve conter pelo menos 6 caracteres.";
      }
      if (isNaN(parseFloat(this.basketValue)) || parseFloat(this.basketValue) <= 0) {
        this.basketValueError = "Preencha com um valor maior que R$ 0,00.";
      }
      return !(this.planNameError || this.basketValueError);
    },
    canCreate(): boolean {
      if (!this.planName) return false;
      if (!this.basketValue) return false;
      if (!this.deliveryType) return false;
      if (!this.pickupBranchId && this.deliveryType !== DeliveryType.Porta_a_porta) return false;
      return true;
    }
  },
})(GlobalData.Pinia);
