<script setup lang="ts">
import { Ref, ref, inject, defineExpose } from 'vue';
import { AlertModalInjection, LoadingModalInjection } from '@/infra/constants/injections';
import CommonModal from './CommonModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import CommonInput from '@/components/CommonInput.vue';
import CommonButton from '@/components/CommonButton.vue';
import AvatarCard from '@/components/AvatarCard.vue';
import { useCreateBranch } from '@/stores/settings/create_branch_store';
import { BranchAddresses } from '@/models/branch_addresses_model';
import CommonToggle from '@/components/CommonToggle.vue';

const createBranchModal = ref<InstanceType<typeof CommonModal>>();
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);

const createBranch = useCreateBranch();

const onCreateMethod = ref<((newBranch: BranchAddresses | null) => void) | null>(null);

defineExpose({
  open: async (params?: {
    zIndex?: number;
    onCreate?: (newBranch: BranchAddresses | null) => void;
  }) => {
    onCreateMethod.value = params?.onCreate ?? null;
    await createBranchModal.value?.open({
      zIndex: params?.zIndex ?? 2,
    });
  },
  close: () => {
    createBranchModal.value?.close();
  },
});

async function createNewBranch() {
  try {
    loadingModal?.value?.open({ zIndex: 1000 });
    const res = await createBranch.submit();
    if (res.isError()) alertModal?.value?.open({ title: 'Erro', content: res.getError() ?? 'Erro desconhecido' });
    else {
      onCreateMethod.value?.call(null, res.getValue() ?? null);
      clearAll();
      createBranchModal.value?.close();
    }
  } finally {
    loadingModal?.value?.close();
  }
}

async function avatarEdit() {
  return null;
}

function clearAll() {
  createBranch.$reset();
}

</script>

<template>
  <CommonModal ref="createBranchModal" :is-full-screen="true">
    <main class="container">
      <header class="header">
        <h1 class="title">Cadastrar nova sede</h1>
      </header>
      <form class="form">
        <div class="d-flex align-items-center gap-3 mb-4"> 
          <AvatarCard :item="{
            name: createBranch.getName(),
            image: createBranch.getImgUrl()
          }" :size="'large'" @on-edit="avatarEdit"/>
          <CommonInput label="Nome" class="w-100" :model-value="createBranch.branchName" @update:model-value="(v) => createBranch.branchName = v" @tap-trailing="createBranch.branchName = ''"/>
        </div>
        <CommonInput mask="#####-###" label="CEP" class="w-30" :model-value="createBranch.address.getCep()"
          @update:model-value="(v) => createBranch.address.setCep(v)" @tap-trailing="createBranch.address.setCep('')" />
        <div class="form-section">
          <CommonInput label="Estado" class="w-35" :model-value="createBranch.address.getState()"
            @update:model-value="(v) => createBranch.address.setState(v)"
            @tap-trailing="createBranch.address.setState('')" />
          <CommonInput label="Cidade" class="w-100" :model-value="createBranch.address.getCity()"
            @update:model-value="(v) => createBranch.address.setCity(v)"
            @tap-trailing="createBranch.address.setCity('')" />
        </div>    
        <div class="form-section">
          <CommonInput label="Bairro" class="w-35" :model-value="createBranch.address.getVicinity()"
            @update:model-value="(v) => createBranch.address.setVicinity(v)"
            @tap-trailing="createBranch.address.setVicinity('')" />
            <CommonInput label="Endereço" class="w-100" :model-value="createBranch.address.getStreet()"
            @update:model-value="(v) => createBranch.address.setStreet(v)"
            @tap-trailing="createBranch.address.setStreet('')" />
        </div>
        <div class="form-section">
          <CommonInput :state="`${createBranch.hasNumber ? 'default' : 'disabled'}`" label="Número" class="w-35"
            :model-value="createBranch.address.getStreetNumber()"
            @update:model-value="(v) => createBranch.address.setStreetNumber(v)"
            @tap-trailing="createBranch.address.setStreetNumber('')" />
            <CommonInput label="Complemento" class="w-100" :model-value="createBranch.address.getComplement()"
            @update:model-value="(v) => createBranch.address.setComplement(v)"
            @tap-trailing="createBranch.address.setComplement('')" />  
        </div>
        <div class="toggle">
          <CommonToggle label="Endereço sem número" name="autoGenDefaultBasket" :value="!createBranch.hasNumber"
            @change="(_, value) => createBranch.hasNumber = !value" />
        </div>
      </form>
      <footer class="footer">
        <CommonButton type="outlined" border-radius="small" action="secondary" @click="()=>{createBranchModal?.close(); clearAll();}"
          text="Cancelar cadastro"></CommonButton>
        <CommonButton type="filled" border-radius="small" @click="createNewBranch()" text="Cadastrar"></CommonButton>
      </footer>
    </main>
  </CommonModal>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.toggle {
  padding: 4px 0px;
}

.form-section :first-child{
  flex:0 0 30%;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--option-color-gray-800, #434049);
  font-size: 24px;
  font-weight: 800;
}

.header i {
  font-size: 36px;
  cursor: pointer;
}

.header i:hover {
  transition: 0.3s;
  transform: scale(1.1);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.form-section {
  display: flex;
  gap: 16px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
}

.footer button {
  width: 200px;
}
</style>
