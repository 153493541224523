import { defineStore } from "pinia";
import { ValueResult } from "@/models/value-result";
import { BranchAddressesBFFBackofficeModule } from "@/infra/bff_backoffice/branch_addresses/branch_addresses_backoffice_module";
import { useDeliveryAreasStore } from "@/stores/delivery_areas/delivery_areas_store";
import { AddressModel } from "@/models/address_model";
import FormatUtils from "@/utils/format_utils";

interface IState {
  branchName: string;
  branchImgUrl: string;
  hasNumber: boolean;
  address: AddressModel
}

const deliveryAreasStore = useDeliveryAreasStore();

export const useCreateBranch = defineStore("createBranch", {
  state: (): IState => ({
    branchName: "",
    branchImgUrl: "",
    hasNumber: true,
    address: new AddressModel({
      street: "",
      streetNumber: "",
      complement: "",
      vicinity: "",
      city: "",
      country: "BRASIL",
      state: "",
      cep: "",
      lat: 0,
      long: 0
    })
  }),
  actions: {
    async submit() {
      const validation = this.validate();
      if (validation.isError()) return validation;
      return await BranchAddressesBFFBackofficeModule.createBranchAddresses({
        deliveryAreaId: deliveryAreasStore.selectedDeliveryArea!.getId(),
        branches: [{
          branch: {
            name: this.branchName
          },
          address: this.address as AddressModel
        }]
      });
    },

    validate(): ValueResult<null> {
      if (this.branchName.length < 6) {
        return new ValueResult({
          error: "O nome da filial deve conter pelo menos 6 caracteres",
        });
      }
      if (this.address.getCep().length < 8 || !FormatUtils.isValidCEP(this.address.getCep())) {
        return new ValueResult({
          error: "O CEP da area deve conter pelo menos 8 caracteres e ser no formato XXXXX-XXX.",
        });
      }
      if (this.address.getCity().length < 3) {
        return new ValueResult({
          error: "A Cidade da area deve conter pelo menos 3 caracteres",
        });
      }
      if (this.address?.getLat()) {
        return new ValueResult({
          error: "A Latitude da area deve ser um numero",
        });
      }
      if (this.address?.getLong()) {
        return new ValueResult({
          error: "A Longitude da area deve ser um numero",
        });
      }
      if (this.address.getState().length < 2) {
        return new ValueResult({
          error: "O UF da area deve conter pelo menos 2 caracteres",
        });
      }
      if (this.address.getStreet().length < 3) {
        return new ValueResult({
          error: "O Nome da Rua da area deve conter pelo menos 3 caracteres",
        });
      }
      if (this.address.getVicinity().length < 3) {
        return new ValueResult({
          error: "O Bairro da area deve conter pelo menos 3 caracteres",
        });
      }
      if (!this.address.getStreetNumber()) {
        if (this.hasNumber) {
          return new ValueResult({
            error: "O numero da rua deve ser preenchido ou marque o campo 'Endereço sem número'.",
          })
        }
        this.address.setStreetNumber("S/N");
      }
      return new ValueResult();
    },

    getName() {
      return this.branchName;
    },

    getImgUrl() {
      return this.branchImgUrl;
    }
  },
});
