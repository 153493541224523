import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { PaymentMethodTypes } from "./payment_method_types_enum";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

@JsonObject()
export class PaymentMethod {
  @JsonProperty()
  type: PaymentMethodTypes;
  @JsonProperty()
  name: string;
  @JsonProperty()
  icon: IconDefinition;
  @JsonProperty()
  msg?: string;

  constructor(params: {
    type: PaymentMethodTypes;
    name: string;
    icon: IconDefinition;
    msg?: string;
  }) {
    if (!params) return;
    this.type = params.type;
    this.name = params.name;
    this.icon = params.icon;
    this.msg = params.msg;
  }
}