<script setup lang="ts">
import { onMounted, ref } from 'vue';
import CommonInput from '@/components/CommonInput.vue';
import CurrencyValue from '@/components/CurrencyValue.vue';
import PersonalizeToggle from '@/components/PersonalizeToggle.vue';
import FormatUtils from '@/utils/format_utils';
import { Icons } from '@/font-awesome';

defineProps<{
  basketValue: string;
}>();

const emit = defineEmits<{
  (e: 'update:basketValue', value: string): void;
  (e: 'reset:basketValue', value: string): void;
}>();

defineExpose({
  resetHasAlteration: () => {
    hasAlterationValue.value = false;
  },
  getHasAlteration: () => {
    return hasAlterationValue;
  }
});

const hasAlterationValue = ref(false);


</script>

<template>
  <div  class="plan-value">
    <CurrencyValue  class="margin-top-value" color="pink" v-if="!hasAlterationValue"
      :value="FormatUtils.parseFormattedNumber(basketValue ?? '') ?? 0" :fontReal="40" :fontSize="24" />
    <CommonInput class="margin-top-input" v-if="hasAlterationValue" @keydown.enter="hasAlterationValue = false"
      :iconButton="Icons.imported.faRotateLeft" label="Valor do plano" state="default" type="text"
      :prefixIcon="Icons.imported.faDollar"
      :supportingText="'O valor utilizado dever ser um número, podendo conter apenas virgula e ponto'"
      :mask="FormatUtils.formatMoney(basketValue)" :modelValue="basketValue ?? ''"
      @update:model-value="(v) => emit('update:basketValue', v)" 
      @tap-trailing=" emit('reset:basketValue', basketValue ?? '');"
      @change:value="(v) => hasAlterationValue = !v" />
    <div style="width: 16px;"></div>
    <div class="plan-button">
      <PersonalizeToggle class="icon-toggle" v-show="!hasAlterationValue" label="" name="autoGenDefaultBasket"
        :value="hasAlterationValue" :icon="Icons.imported.faPenToSquare"
        @click="hasAlterationValue = !hasAlterationValue" />
    </div>
  </div>
</template>

<style scoped>
.plan-value {
  display: flex;
  flex-direction: row;
  color: var(--option-color-pink-dark, #E02867);
  align-items: baseline;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.margin-top-value {
  padding-top: 8px;
}

.margin-top-input {
  padding-top: 16px;
}

.plan-button {
  align-self: center;
}

.icon-toggle {
  font-size: 15px;
  color: var(--components-input-default-label-color, #6E6979);
}

@media screen and (max-width: 385px) {
  .plan-value {
    flex-direction: column-reverse;
  }

  .plan-button {
    align-self: flex-start;
    padding-bottom: 4px;
  }
}
</style>
