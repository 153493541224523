import { DeliveryAreaModel } from "@/models/delivery_area_model";
import { JsonSerializer } from "typescript-json-serializer";

export class SelectedDeliveryArea {

  private static readonly serializer = new JsonSerializer();
  private static readonly selectedDeliveryArea = 'selectedDeliveryArea';

  static setSelectedDeliveryArea(deliveryArea: DeliveryAreaModel) {
    const json = SelectedDeliveryArea.serializer.serializeObject(deliveryArea);
    window.localStorage.setItem(SelectedDeliveryArea.selectedDeliveryArea, JSON.stringify(json));
  }

  static clearSelectedDeliveryArea() {
    window.localStorage.removeItem(SelectedDeliveryArea.selectedDeliveryArea);
  }

  static getSelectedDeliveryArea(): DeliveryAreaModel | null {
    const json = window.localStorage.getItem(SelectedDeliveryArea.selectedDeliveryArea);
    if (!json) return null;
    const deliveryArea = SelectedDeliveryArea.serializer.deserializeObject(json, DeliveryAreaModel) as DeliveryAreaModel | null;
    return deliveryArea;
  }
}