<script setup lang="ts">
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ref, computed, onMounted, defineProps, defineEmits, defineExpose, onUnmounted } from 'vue';

const labelLeft = 20;
const inputBefore = ref<HTMLElement | null>(null);
const labelElem = ref<HTMLElement | null>(null);
const currentLabelSize = ref(0);
const props = defineProps<{
  icon?: IconDefinition,
  iconButton?: IconDefinition;
  modelValue: string,
  label: string,
  supportingText?: string,
  state?: 'disabled' | 'error' | 'default',
  type?: string,
}>();

defineEmits<{
  (e: 'update:model-value', value: string): void,
  (e: 'tap-trailing', evt: Event): void,
  (e: 'leave-focus', value: string): void,
  (e: 'key-down', evt: KeyboardEvent): void,
  (e: 'on-paste', evt: ClipboardEvent): void,
  (e: 'change:value', evt: Event, value: boolean): void;
}>()

defineExpose({
  setFocus: (v: boolean) => v ? textField.value?.focus() : textField.value?.blur(),
});

const state = computed(() => props.state ?? 'default');
const isFocused = ref(false);
const isHovered = ref(false);
const floatOn = computed(() => isFocused.value || props.modelValue.length > 0)
const textField = ref<HTMLTextAreaElement | null>(null);
const inputBackgroundColor = computed(() => `var(--components-input-${state.value}-background-color, rgba(0, 0, 0, 0.00))`);

function getState() {
  if (isFocused.value) {
    return "focused";
  }
  if (isHovered.value && state.value != "disabled") {
    return "hovered";
  }
  return `${state.value}`;
}

let resizeObserver: ResizeObserver | null = null;
onMounted(() => {
  textField.value!.onkeyup = () => {
    textField.value?.blur();
    textField.value?.focus();
  };

  resizeObserver = new ResizeObserver(() => {
    currentLabelSize.value = labelElem.value?.offsetWidth ?? 0;
  });
  resizeObserver.observe(labelElem.value!);
});

onUnmounted(() => {
  resizeObserver?.disconnect();
});

</script>
<template>
  <div class="input-father">
    <div class="input">
      <textarea
       @mouseover="isHovered = true"
       @mouseleave="isHovered = false"
        :disabled="state == 'disabled'"
        ref="textField"
         class="text-input" 
         :type="type ?? 'text'" 
         @focus="isFocused = true"
          @blur="isFocused = false"
        :value="modelValue" 
        :style="{
          border: `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
          borderTop: isFocused || modelValue.length ? `none` : `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
          resize: 'none',
          minHeight: '100px'
        }"
        @input="$emit('update:model-value', ($event.target as HTMLTextAreaElement).value)"
        @focusout="$emit('leave-focus', ($event.target as HTMLTextAreaElement).value)"
        @paste="$emit('on-paste', ($event))" @keydown="$emit('key-down', ($event))" />
      <div 
      ref="inputBefore" 
      class="input-before"
       v-if="(isFocused || modelValue.length) && labelElem" 
       :style="{
        backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
      }"></div>
      <div 
       class="input-after" 
       v-if="(isFocused || modelValue.length) && labelElem" 
       :style="{
        backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
        width: `calc(100% - ${labelLeft}px - (var(--semantic-border-radius-default, 7px) * 2) - ${currentLabelSize}px)`,
      }"></div>

      <div ref="labelElem" :class="[
        'semantic-typography-body-regular-default',
        'input-field-label',
        {
          'input-field-label-focused': floatOn,
          'semantic-typography-body-bold-small': floatOn
        }
      ]" :style="{
        color: `var(--components-input-${getState()}-label-color, #6E6979)`,
        left: labelLeft,
      }">
        <p>
          {{ label }}
        </p>
      </div>
    </div>
    <p class="supporting-text" :style="`color: var(--components-input-${getState()}-supporting-text-color)`"
      v-if="supportingText">
      {{ supportingText }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.input-father {
  display: flex;
  flex-direction: column;
  gap: var(--semantic-spacing-stack-100, 8px);
  width: 100%;
}

.text-input {
  color: var(--token-input-default-text-color);
  border-radius: var(--semantic-border-radius-default, 7px);
  padding: var(--semantic-spacing-inset-200, 16px);
  font-size: 16px;
  padding-left: 24px;
  width: 100%;
  background-color: v-bind('inputBackgroundColor');
  border-top: none;
  position: relative;
}

.text-input:focus {
  outline: none; 
}

.input {
  position: relative;
}

.input-after,
.input-before {
  position: absolute;
  top: 0;
  height: .1px;
}

.input-before {
  left: calc(var(--semantic-border-radius-default, 7px) / 2);
  width: 10px;
}

.input-after {
  right: calc(var(--semantic-border-radius-default, 7px) / 2);
}

.input-field-icon {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 17px;
  right: 20px;
  cursor: pointer;
  color: var(--components-input-default-right-icon-color, #6E6979);
}

.input-field-label {
  position: absolute;
  left: 21px;
  top: 50%;
  line-height: 16px;
  transition: all 0.1s;
  pointer-events: none;
  display: flex;
  padding: 0px var(--semantic-spacing-stack-025, 2px);
  gap: var(--semantic-spacing-inline-100, 8px);
  align-items: center;
  transform: translate(0%, -50%);
}

.input-field-label-focused {
  position: absolute;
  top: 0%;
  font-size: 12px;
  gap: var(--semantic-spacing-inline-100, 4px);
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.supporting-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 300;
}
</style>