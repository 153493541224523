import Theme from "./theme_enum";

class DarkMode {

  private static storageKey = 'selected_theme';
  private static elementId = 'link_theme';
  
  public static get theme(): Theme {
    const theme = window.localStorage.getItem(DarkMode.storageKey) as Theme;
    if (!theme) window.localStorage.setItem(DarkMode.storageKey, Theme.LIGHT);
    return theme || Theme.LIGHT;
  }

  public static setTheme(theme: Theme) {
    window.localStorage.setItem(DarkMode.storageKey, theme);
    DarkMode.updateTheme();
  }

  public static async updateTheme() {
    const themeCSS = DarkMode.getThemeElement();
    themeCSS.href = DarkMode.theme === Theme.LIGHT ? './generated_style.css' : './generated_style_dark.css';
  }

  private static getThemeElement(): HTMLLinkElement {
    if (document.getElementById(DarkMode.elementId)) {
      return document.getElementById(DarkMode.elementId) as HTMLLinkElement;
    }
    const themeCSS = document.createElement('link');
    themeCSS.id = DarkMode.elementId;
    themeCSS.rel = 'stylesheet';
    themeCSS.type = 'text/css';
    document.head.appendChild(themeCSS);
    return themeCSS;
  }

}

export default DarkMode;