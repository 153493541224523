export const AlertModalInjection = Symbol('AlertModal');
export const AlertModalInjectionV2 = Symbol('AlertModalV2');
export const LoadingModalInjection = Symbol('LoadingModal');
export const LoadingModalV2Injection = Symbol('LoadingModalV2');
export const CommonToastInjection = Symbol('CommonToast');
export const TwoFactorAuthModalInjection = Symbol('TwoFactorAuthModal');
export const ReqRenBasketPlanModalInjection = Symbol('ReqRenBasketPlanModal');
export const ReqRenBenfitsCardsPlanInjection = Symbol('ReqRenBenfitsCardsPlan')
export const WelcomeModalInjection = Symbol('WelcomeModal');
// export const LinkUsersModalInjection = Symbol('LinkUsersModal');
