// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2710%27 height=%2710%27 viewBox=%270 0 12 12%27 fill=%27none%27%3E%3Cg clip-path=%27url%28%23clip0_1281_2830%29%27%3E%3Cpath d=%27M11.0295 2.47034C11.3225 2.76331 11.3225 3.23909 11.0295 3.53206L5.02949 9.53206C4.73652 9.82502 4.26074 9.82502 3.96777 9.53206L0.967773 6.53206C0.674805 6.23909 0.674805 5.76331 0.967773 5.47034C1.26074 5.17737 1.73652 5.17737 2.02949 5.47034L4.4998 7.93831L9.97012 2.47034C10.2631 2.17737 10.7389 2.17737 11.0318 2.47034H11.0295Z%27 fill=%27white%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27clip0_1281_2830%27%3E%3Crect width=%2710.5%27 height=%2712%27 fill=%27white%27 transform=%27translate%280.75%29%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\ninput {\r\n  margin: 0;\n}\n.text-checkbox-container {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 8px;\n}\n.label {\r\n  color: var(--input-default-supporting-text-color, #6B7280);\r\n  font-feature-settings: 'clig' off, 'liga' off;\r\n  font-family: Montserrat;\r\n  font-size: 12px;\r\n  font-style: normal;\r\n  font-weight: 400;\n}\n.custom-checkbox:checked::before {\r\n  content: \"\";\r\n  display: flex;\r\n  position: absolute;\r\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  width: 13px;\r\n  height: 13px;\r\n  padding: 0px 0.75px;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-color: var(--option-color-purple, #8A2BE2);\r\n  border-radius: 2px;\n}\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
