<script setup lang="ts">
import FormatUtils from '@/utils/format_utils';
import { defineProps } from 'vue';

const props = defineProps<{
  value: number;
  fontReal: number;
  fontSize: number;
  color?: 'primary' | 'pink',
}>();

function getColor() {
  switch (props.color) {
    case 'pink': return  'var(--option-color-pink-dark, #E02867)';
    default: return 'var(--semantic-color-primary-default, #8300C7)';
  }
}

</script>
<template>
  <h3 class="value" :style="{ color: getColor() }">
    <b class="default-font" :style="`font-size:${fontSize}px`">R$ </b>
    <b class="default-font" :style="`font-size:${fontReal}px`">{{ FormatUtils.beforeComma(value, true) }}</b>
    <b class="default-font" :style="`font-size:${fontSize}px`">,{{ FormatUtils.afterComma(value) }}</b>
  </h3>
</template>

<style scoped>

.default-font {
  font-weight: 800;
  font-family: 'Montserrat';
}

</style>