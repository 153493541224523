import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "qrcode",
    style: _normalizeStyle({
    backgroundColor: $setup.backgroundColor,
  })
  }, [
    _createVNode($setup["QrcodeVue"], {
      size: 153,
      "render-as": "canvas",
      level: "L",
      value: $props.value,
      foreground: $setup.foregroundColor
    }, null, 8 /* PROPS */, ["value"])
  ], 4 /* STYLE */))
}