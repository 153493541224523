import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class Coordinate {
  @JsonProperty()
  lat: number;
  @JsonProperty()
  long: number;

  constructor(params: {
    lat: number;
    long: number;
  }) {
    if (!params) return;
    this.lat = params.lat;
    this.long = params.long;
  }
}