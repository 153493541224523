<script setup lang="ts">
import { defineProps } from 'vue';

defineProps<{
  value: boolean;
}>();

defineEmits<{
  (e: 'change', value: boolean): void
}>();

</script>

<template>
  <div class="toggle-info" :style="{
    background: value ? 'var(--semantic-color-primary-default, #8300C7)' : 'var(--semantic-color-bg-muted, #E6E4EB)',
    justifyContent: value ? 'flex-end' : 'flex-start',
  }" @click="() => $emit('change', !value)">
    <div class="circle-data"></div>
  </div>
</template>

<style scoped>
.toggle-info {
  display: flex;
  min-width: 48px;
  padding: 2px;
  border-radius: 10000px;
  cursor: pointer;
  transition: 0.3s ease all;
  height: fit-content;
}

.circle-data {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 10000px;
  background: #FFF;
}
</style>update:model-valueupdate:model-value