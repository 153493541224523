import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5aa01685"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "content-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$setup.props.hideDelivery)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["CustomSelector"], {
          ref: "deliveryTypeSelector",
          icon: $setup.Icons.imported.faTruck,
          "empty-message": "Tipo de entrega",
          onChange: _cache[0] || (_cache[0] = (_, index) => {
        $setup.deliveryType = index !== null ? $setup.DeliveryType[Object.keys($setup.DeliveryType)[index]] : null;
        if ($setup.deliveryType === $setup.DeliveryType.Porta_a_porta) {
          $setup.pickupBranchId = null;
          _ctx.$emit('update:pickup-branch-id', $setup.pickupBranchId);
        }
        _ctx.$emit('update:delivery-type', $setup.deliveryType);
      }),
          options: Object.keys($setup.DeliveryType).map((key) => ({
      title: key.replaceAll('_', ' '),
      }))
        }, null, 8 /* PROPS */, ["icon", "options"]),
        ($setup.props.branchAddresses && $setup.deliveryType !== $setup.DeliveryType.Porta_a_porta )
          ? (_openBlock(), _createBlock($setup["BranchSelector"], {
              key: 0,
              ref: "branchSelector",
              "z-index": $props.zIndex ?? 1001,
              onChange: _cache[1] || (_cache[1] = (_, index) => {
        $setup.pickupBranchId = $setup.props.branchAddresses?.getIndex(index)?.branch?.getId() ?? null;
        _ctx.$emit('update:pickup-branch-id', $setup.pickupBranchId);
      }),
              onCreate: _cache[2] || (_cache[2] = (_, branches) => {
        $setup.props.branchAddresses?.concat(branches);
        $setup.pickupBranchId = branches.getIndex(0)?.branch?.getId() ?? null;
        const currentBranches = $setup.props.branchAddresses?.getBranches() ?? [];
        const index = currentBranches.findIndex((branch) => branch.getId() === branches.getIndex(0)?.branch?.getId()) ?? 0;
        $setup.branchSelector?.setSelected(index);
      }),
              branches: ($setup.props.branchAddresses as BranchAddresses)
            }, null, 8 /* PROPS */, ["z-index", "branches"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}