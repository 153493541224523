<script setup lang="ts">
import CommonButton from '@/components/CommonButton.vue';
import CommonInput from '@/components/CommonInput.vue';
import { WhatsappUtils } from '@/utils/whatsapp_utils';
import Constants from '@/infra/constants/constants';
import AlertModal from '@/components/modals/AlertModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { Icons } from '@/font-awesome';
import { AlertModalInjection, LoadingModalInjection } from '@/infra/constants/injections';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';
import { routes } from '@/router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useUserStore } from '@/stores/users/user_store';
import { Ref, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { CardCategoryType } from '../models/benefits_cards/card_category_type';

const userStore = useUserStore();
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);

const router = useRouter();

async function login() {
  try {
    loadingModal?.value.open();
    userStore.emailError = '';
    userStore.cpfError = '';
    const res = await userStore.login();
    if (res?.isError()) {
      alertModal?.value?.open({
        icon: Icons.imported.faCircleExclamation,
        title: 'Erro ao logar',
        titleColor: 'error',
        content: res.getError() ?? 'Erro desconhecido',
      });
      return;
    }
    if (res) {
      const user = LoggedAdminStorage.getUser();
      router.push({
        name: user?.getIsSuperAdmin() ? routes.deliveryAreas.name : routes.welcome.name,
        params: {
          deliveryAreaId: user?.getDeliveryAreaId(),
        }
      });
    }

  } finally {
    loadingModal?.value.close();
  }
}

</script>
<template>
  <main class="login-main">
    <div class="background-gradient">
      <div class="position-img">
        <img class="beans-login" src="@/assets/image/beans_login.svg" alt="">
        <img class="login-screens" src="@/assets/image/login_screens.png" alt="">
        <img class="responsive-login" src="@/assets/image/responsive_design_login.svg" alt="">
      </div>
    </div>
    <div class="form-container">
      <div class="login-form">
        <div class="logo">
          <span class="degrade-title">Acesse</span>
          <span class="title"> sua <br> conta Foodpass</span>
        </div>
        <div class="spacing-600"></div>
        <CommonInput :supportingText="userStore.emailError" :state="userStore.emailError ? 'error' : 'default'"
          label="Email" type="text" placeholder="Email" @update:model-value="userStore.email = $event"
          :model-value="userStore.email" @tap-trailing="userStore.email = ''" @key-down="(e) => {
            if (e.key === 'Enter') login();
          }" />
        <div class="spacing-200"></div>
        <CommonInput :supportingText="userStore.cpfError" :state="userStore.cpfError ? 'error' : 'default'"
          label="Senha" type="password" placeholder="Senha" @update:model-value="userStore.password = $event"
          :model-value="userStore.password" @key-down="(e) => {
            if (e.key === 'Enter') login();
          }" />
        <div class="spacing-400"></div>
        <CommonButton :action="'cta'" :type="'filled'" text="Entrar" @click="login()"></CommonButton>
        <div class="spacing-300"></div>
        <div @click="router.push({
          name: routes.forgotPassword.name,
        })" class="forgot-password-container">
          <FontAwesomeIcon :icon="Icons.imported.faUnlock" :style="{
            width: '12px',
            height: '12px',
          }" class="icon" />
          <p class="forgot-password">Esqueci minha senha</p>
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap w-100 gap-2 footer">
        <div class="text-footer-container">
          <div class="footer-text">
            <FontAwesomeIcon :style="{
              width: '16px',
              height: '16px',
            }" :icon="Icons.imported.faEnvelope" class="icon" />
            <p>{{ Constants.suportEmail }}</p>
          </div>
          <div class="footer-text">
            <FontAwesomeIcon :style="{
              width: '16px',
              height: '16px',
            }" :icon="Icons.imported.faWhatsapp" class="icon" />
            <p style="text-wrap: nowrap;">{{ Constants.foodPassSupportNumber.replace(/^\+55/, "") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="whatsapp-button" @click="() => WhatsappUtils.textNumber(Constants.foodPassSupportNumber)">
        <FontAwesomeIcon :style="{
          width: '30px',
          height: '30px',
          backgroundColor: '#25D366',
          borderRadius: '1000px',
          padding: '11px',
          color: 'var(--token-semantic-color-bg-surface)'
        }" :icon="Icons.imported.faWhatsapp" class="icon" />
    </div>
  </main>

</template>

<style scoped>
.login-main {
  display: flex;
  align-items: center;
  background-color: #FFF;
}

.spacing-600 {
  padding-bottom: var(--semantic-spacing-inset-600, 48px);
}

.spacing-400 {
  padding-bottom: var(--semantic-spacing-inset-400, 32px);
}

.spacing-300 {
  padding-bottom: var(--semantic-spacing-inset-300, 24px);
}

.spacing-200 {
  padding-bottom: var(--semantic-spacing-inset-200, 16px);
}

.foodpass {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  gap: 10px;
}

.whatsapp-button {
  display: flex;
  position: fixed;
  right: 102px;
  bottom: 41px;
}

.whatsapp-button :hover {
  cursor: pointer;
}

.forgot-password-container {
  align-items: center;
}

.forgot-password-container :hover {
  cursor: pointer;
}

.text-footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.footer-text {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  gap: 8px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.login-row {
  display: flex;
  flex-direction: row;
}

.background-gradient {
  background: linear-gradient(90deg, rgba(131, 0, 199, 1) 10%, rgba(255, 80, 140, 0.9752275910364145) 100%);
  height: 100%;
  width: 100%;
  flex: 0 0 55%;
}

.position-img {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.beans-login {
  position: absolute;
  z-index: 1000;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login-screens {
  width: 631px;
  height: 578px;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.responsive-login {
  display: none;
}

.degrade-title {
  background: linear-gradient(90deg, rgba(131, 0, 199, 1) 10%, rgba(255, 80, 140, 0.9752275910364145) 100%);
  font-size: 48px;
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.title {
  color: var(--semantic-color-fg-ghost, #CDC8D7);
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.form-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  padding-left: 156px;
  padding-right: 156px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 0 0 45%;
  height: 100%;
}

.logo {
  align-self: start;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.forgot-password {
  font-size: 14px;
  color: var(--semantic-color-fg-muted, #6E6979);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.icon {
  font-size: 14px;
  color: var(--semantic-color-fg-muted, #6E6979);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.forgot-password-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  top: 70.5px;
}

@media screen and (min-width: 1201px) {
  .login-main {
    height: 100vh;
  }
}

@media screen and (max-width: 1200px) {
  .login-main {
    flex-direction: column;
  }

  .background-gradient {
    padding: 41px;
  }
}

@media screen and (max-width: 800px) {
  .login-main {
    flex-direction: column;
  }

  .form-container {
    padding-left: 78px;
    padding-right: 138px;
  }

  .foodpass {
    width: 165px;
  }

  .footer {
    flex-direction: column;
    align-items: start;
  }

  .degrade-title {
    font-size: 32px;
  }

  .title {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  .text-footer-container {
    flex-direction: column;
    margin-bottom: 30px;
    align-self: center;
    align-items: center;
  }

  .responsive-login {
    display: block;
  }

  .beans-login {
    display: none;
  }

  .login-screens {
    display: none;
  }

  .form-container {
    padding-left: 39px;
    padding-right: 39px;
    position: relative;
    top: -6vw;
    border-radius: 30px 30px 0px 0px;
    background: var(--semantic-color-bg-surface, #FFF);
  }

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 32px 32px 32px;
  }

  .logo {
    text-align: center;
    align-self: center;
    font-size: 24px;
  }

  .whatsapp-button {
    right: 51px;
    bottom: 20px;
  }
}

@media screen and (max-width: 375px) {
  .form-container {
    top: -10vw;
  }

  .text-footer-container {
    flex-direction: column;
  }

  .foodpass {
    width: 80px;
  }

  .foodpass img {
    width: 80px;
  }

  .degrade-title {
    font-size: 24px;
  }

  .title {
    font-size: 24px;
  }

  .whatsapp-button {
    right: 25px;
  }
}
</style>