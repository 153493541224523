import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1ef0aead"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn common-button",
    disabled: $setup.props.action === 'disabled',
    onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('change-page')),
    type: "button",
    style: _normalizeStyle($setup.cssVars),
    onMouseleave: _cache[3] || (_cache[3] = ($event: any) => ($setup.showOptions = false)),
    ref: "commonButtonElem"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`btn-content typography-${$setup.props.size}`)
    }, [
      ($props.icon)
        ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
            key: 0,
            icon: $props.icon
          }, null, 8 /* PROPS */, ["icon"]))
        : _createCommentVNode("v-if", true),
      _createTextVNode(" " + _toDisplayString($props.text) + " ", 1 /* TEXT */),
      (_ctx.$slots.default)
        ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
            key: 1,
            icon: $setup.Icons.imported.faChevronDown,
            class: "icon",
            style: _normalizeStyle({
          transform: $setup.showOptions ? 'rotate(180deg)' : 'rotate(0deg)',
        }),
            onClick: _cache[0] || (_cache[0] = (e) => {
          e.stopPropagation();
          $setup.showOptions = !$setup.showOptions;
        }
      )
          }, null, 8 /* PROPS */, ["icon", "style"]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    ($setup.showOptions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "options",
          onClick: _cache[1] || (_cache[1] = (mouseEvent) => mouseEvent.stopPropagation()),
          style: _normalizeStyle({
        top: $setup.quadrant.isSuperior ? '100%' : '0%',
        transform: $setup.quadrant.isSuperior ? 'translateY(0)' : 'translateY(-100%)',
        maxHeight: $setup.maxHeight + 'px',
        left: $setup.quadrant.isLeft ? '0' : 'unset',
        right: $setup.quadrant.isLeft ? 'unset' : '0',
      })
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
}