<script setup lang="ts">
import { defineProps, defineEmits, getCurrentInstance } from 'vue';
import { Icons } from '@/font-awesome';
import ContextMenu from '../ContextMenu.vue';
import FormatUtils from '@/utils/format_utils';
import CurrencyValue from '@/components/CurrencyValue.vue';
import { BenefitsCardPlan } from '@/models/benefits_cards/benefits_card_plan_model';
// import ToggleActive from '@/components/ToggleActive.vue';
import Tooltip from '@/components/Tooltip.vue';
import CardTag from '../CardTag.vue';
import CardBlob from '../CardBlob.vue';
import { CardCategoryType, CardCategoryUtils } from '@/models/benefits_cards/card_category_type';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';

const thisInstance = getCurrentInstance();

const props = defineProps<{
  benefitsCardPlan: BenefitsCardPlan;
  hideControls?: boolean;
}>();

const emit = defineEmits<{
  (e: 'change-page'): void;
  (e: 'renew'): void;
  (e: 'remove'): void;
}>();

const textEllipsisSize = 14;

</script>

<template>
  <div class="card-father" :class="{
    'card-father-hover': thisInstance?.vnode?.props?.changePage,
  }" @click="() => $emit('change-page')">
    <div class="card-content">
      <div class="d-flex flex-column gap-3 align-items-center">
        <img src="@/assets/icons/credit_card_plan.svg" alt="Blob pattern" />
        <div class="d-flex flex-column gap-1 align-items-center">
          <CurrencyValue :value="benefitsCardPlan.getPlanValue()" :fontReal="28" :fontSize="16"/>
          <Tooltip :text="benefitsCardPlan.getPlanName()" v-if="benefitsCardPlan.getPlanName().length >= textEllipsisSize">
            <h3 class="name">{{ FormatUtils.ellipsis(benefitsCardPlan.getPlanName(), textEllipsisSize) }}</h3>
          </Tooltip>
          <h3 class="name" v-else>{{ benefitsCardPlan.getPlanName() }}</h3>
        </div>
        <div class="d-flex gap-2">
          <CardTag :icon="Icons.imported.faUser" :message="(benefitsCardPlan.getUserCount()).toString()" size="small"/>
          <!-- <ToggleActive :active="true" :message="true ? 'Ativo' : 'Inativo'"/> -->
        </div>
      </div>
      <div class="categories-tags">
        <CardTag
          v-for=" (category, index) in props.benefitsCardPlan.getCategories() "
          v-bind:key="index"
          :icon="CardCategoryUtils.getIconFromCategory(category.getCategory())"
          :iconTitle="category.getCustomCategory().toString()"
          :message="FormatUtils.money(category.getValue(), true)"
          />
      </div>
    </div>
    <ContextMenu
      v-if="!hideControls"
      class="context-menu"
      :buttons="[
        {
          text: 'Renovar',
          action: () => $emit('renew'),
          visible: !LoggedAdminStorage.getUser()?.getIsSuperAdmin() && Boolean(benefitsCardPlan.getUserCount()),
        },
        {
          text: 'Excluir',
          action: () => $emit('remove'),
          visible: true
        }
      ]"
    ></ContextMenu>
    <CardBlob class="blob"/>
  </div>
</template>

<style scoped>

.card-father {
  width: 100%;
  min-height: 323px;
  min-width: 261px;
  padding: var(--semantic-spacing-inset-400, 32px);
  border-radius: var(--semantic-border-radius-default, 7px);
  border: 1px #F2F1F3;
  background: #FFF;
  box-shadow: 0px 0px 16px 0px rgba(81, 77, 85, 0.07);
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.card-father:hover:not(:has(.context-menu:hover)) {
  box-shadow: 0px 0px 24px 0px rgba(81, 77, 85, 0.20);
  cursor: pointer;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: var(--semantic-spacing-stack-600, 48px);
  z-index: auto;
}

.card-father-hover:hover {
  background-color: #F6F6F6;
  border: 1px solid #F6F6F6;
  cursor: pointer;
}

.card-father .name {
  color: var(--semantic-color-fg-emphasis, #434049);
  font-size: 14px;
  font-weight: 300;
}

.context-menu {
  position: absolute;
  right: 12px;
  top: 12px;
}

.blob {
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 6px;
}

.categories-tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--semantic-spacing-stack-100, 8px);
  align-self: stretch;
}

</style>