import { defineStore } from 'pinia';
import { TwoFactorAuthBFFBackofficeModule } from '@/infra/bff_backoffice/two_factor_auth/two_factor_auth_bff_backoffice';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';
import { AdminUser } from '@/models/admins/admin_model';
import { ValueResult } from '@/models/value-result';

interface IState {
  secret?: string;
  qrCodeUrl?: string;
  code: string;
  errorText?: string;
}

export const useTwoFactorAuthStore = defineStore('two_factor_auth_store', {
  state: (): IState => ({
    secret: '',
    qrCodeUrl: '',
    code: '',
    errorText: ''
  }),
  actions: {

    async initialize(params?:{ enable?: boolean}) {
      const res = await TwoFactorAuthBFFBackofficeModule.initializeTwoFactorAuth({ enable: params?.enable ?? true });
      if (res.isError()) return res;
      this.secret = res.getValue()?.secret ?? '';
      this.qrCodeUrl = res.getValue()?.qrCodeUrl ?? '';
      return res;
    },

    async submit(): Promise<ValueResult<AdminUser | boolean | null>> {
      const res = await TwoFactorAuthBFFBackofficeModule.verifyTwoFactorAuth({
        code: this.code
      });
      if (res.getError()) {
        const errorText = res.getError();
        this.setErrorText(errorText);
        return new ValueResult({ error: res.getError() });
      }
      const loginData = res.getValue()!;
      LoggedAdminStorage.setUser(loginData);
      return new ValueResult({value: true});
    },

    setCode(code: string) {
      this.code = code;
    },
    setErrorText(error: string | null) {
      //this.error = error;
    }
  },
});