<script setup lang="ts">
import Divider from '@/components/Divider.vue'
import FormatUtils from '@/utils/format_utils';
import { Icons } from '@/font-awesome';
import { CardCategoryType } from '@/models/benefits_cards/card_category_type';
import { LoadingModalV2Injection } from '@/infra/constants/injections';
import { ReqBenCardsRenewScreens, useReqRenewBenefitsCardPlansStore } from '@/stores/benefits_card_plans/request_renew_benefits_cards_plan_store';
import { inject, Ref } from 'vue';
import CommonButton from '@/components/CommonButton.vue';
import LoadingModalV2 from '../../LoadingModalV2.vue';
import BenefitTypeValue from '@/components/card_benefits_plan/BenefitTypeValue.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BenefitsCardPlan } from '@/models/benefits_cards/benefits_card_plan_model';

const reqRenewBenCardsPlanStore = useReqRenewBenefitsCardPlansStore;

const props = defineProps<{
  showScreen: boolean;
}>();

defineEmits<{
  (e: 'close'): void;
}>();

const loadingModalV2 = inject<Ref<InstanceType<typeof LoadingModalV2>>>(LoadingModalV2Injection);

async function updatePlan() {
  if (!reqRenewBenCardsPlanStore.validateBenefits({ setErrors: true })) return;
  const res = reqRenewBenCardsPlanStore.updateBenefitsPlan();
  loadingModalV2?.value?.open({
    promise: res,
  });
  const valueResult = await res;
  if (!(valueResult.getValue() instanceof BenefitsCardPlan)) loadingModalV2?.value.closeImmediately();
  if (valueResult.isError()) return;
  reqRenewBenCardsPlanStore.currentScreen = ReqBenCardsRenewScreens.VALUE_CONFIRMATION
}

</script>

<template>
  <div
    class="flex-column"
    :style="{
      display: props.showScreen ? 'flex' : 'none'
    }"
  >
      <p class="plan-name">Plano {{ reqRenewBenCardsPlanStore.benefitsCardsPlan?.getPlanName() }}</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px);"></div>
      <div class="d-flex align-items-center">
        <FontAwesomeIcon :icon="Icons.imported.faCalendarRegular" :style="{
          width: 'var(--semantic-size-semantic-size-icons-200, 16px)',
          height: 'var(--semantic-size-semantic-size-icons-200, 16px)',
          color: 'var(--semantic-color-fg-muted, #6E6979)',
        }"></FontAwesomeIcon>
        <div style="width: var(--semantic-size-semantic-spacing-inline-100, 8px);"></div>
        <p class="plan-text">O plano será renovado assim que o pagamento for confirmado.</p>
      </div>
      <div style="height: var(--semantic-size-semantic-spacing-stack-600, 48px);"></div>
      <div class="benefits-values">
        <BenefitTypeValue
          ref="benefitFoodVoucher"
          :icon="Icons.imported.faCartShopping"
          title="Vale alimentação"
          subtitle="VA"
          :value="FormatUtils.formatMoney(reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.VA))"
          :initial-is-enabled="reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.VA)?.length > 0"
          :input-icon="Icons.imported.faDollarSign"
          :error-text="reqRenewBenCardsPlanStore.getBenefitCategoryError(CardCategoryType.VA) ?? undefined"
          :is-enabled="reqRenewBenCardsPlanStore.getBenefitCategoryActive(CardCategoryType.VA)"
          @set-is-enabled="(v) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.VA,
            value: '',
            isActive: v,
          })"
          @change="(v, isEnabled) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.VA,
            value: FormatUtils.formatMoney(v, true),
            isActive: isEnabled
          })"
        />
        <BenefitTypeValue
          ref="benefitMealVoucher"
          :icon="Icons.imported.faPizzaSlice"
          title="Vale refeição"
          subtitle="VR"
          :value="FormatUtils.formatMoney(reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.VR))"
          :initial-is-enabled="reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.VR)?.length > 0"
          :input-icon="Icons.imported.faDollarSign"
          :error-text="reqRenewBenCardsPlanStore.getBenefitCategoryError(CardCategoryType.VR) ?? undefined"
          :is-enabled="reqRenewBenCardsPlanStore.getBenefitCategoryActive(CardCategoryType.VR)"
          @set-is-enabled="(v) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.VR,
            value: '',
            isActive: v,
          })"
          @change="(v, isEnabled) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.VR,
            value: FormatUtils.formatMoney(v, true),
            isActive: isEnabled,
          })"
        />
        <BenefitTypeValue
          ref="benefitMultiVoucher"
          :icon="Icons.imported.faVanShuttle"
          title="Auxílio mobilidade"
          :value="FormatUtils.formatMoney(reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.AM))"
          :initial-is-enabled="reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.AM)?.length > 0"
          :input-icon="Icons.imported.faDollarSign"
          description="Aceito em qualquer meio de locomoção"
          :error-text="reqRenewBenCardsPlanStore.getBenefitCategoryError(CardCategoryType.AM) ?? undefined"
          :is-enabled="reqRenewBenCardsPlanStore.getBenefitCategoryActive(CardCategoryType.AM)"
          @set-is-enabled="(v) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.AM,
            value: '',
            isActive: v,
          })"
          @change="(v, isEnabled) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.AM,
            value: FormatUtils.formatMoney(v, true),
            isActive: isEnabled,
          })"
        />
        <BenefitTypeValue
          ref="benefitMultiVoucher"
          :icon="Icons.imported.faEarthAmericas"
          title="Vale flexível"
          :value="FormatUtils.formatMoney(reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.MULTI))"
          :initial-is-enabled="reqRenewBenCardsPlanStore.getBenefitCategory(CardCategoryType.MULTI)?.length > 0"
          :input-icon="Icons.imported.faDollarSign"
          description="Aceito em qualquer lugar"
          :error-text="reqRenewBenCardsPlanStore.getBenefitCategoryError(CardCategoryType.MULTI) ?? undefined"
          :is-enabled="reqRenewBenCardsPlanStore.getBenefitCategoryActive(CardCategoryType.MULTI)"
          @set-is-enabled="(v) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.MULTI,
            value: '',
            isActive: v,
          })"
          @change="(v, isEnabled) => reqRenewBenCardsPlanStore.setBenefitCategory({
            category: CardCategoryType.MULTI,
            value: FormatUtils.formatMoney(v, true),
            isActive: isEnabled,
          })"
        />
      </div>

      <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px);"></div>
      <Divider />
      <div style="height: var(--semantic-size-semantic-spacing-stack-200, 16px);"></div>
      
      <div class="d-flex flex-column align-items-end">
        <p class="total-by-collab">Total por colaborador</p>
        <p class="value-text" v-if="reqRenewBenCardsPlanStore.benefitsCardsPlan?.getUserCount()">R$ {{ FormatUtils.money(reqRenewBenCardsPlanStore.getTotalValuePerUser()) }}</p>
        <p class="value-text" v-if="!reqRenewBenCardsPlanStore.benefitsCardsPlan?.getUserCount()">Não há colaboradores no plano</p>
        <p class="value-text" v-if="!reqRenewBenCardsPlanStore.benefitsCardsPlan?.getTotalPlanValue()">O plano deve ter saldo maior que R$ 0,00</p>
      </div>
      
      <div style="height: var(--semantic-size-semantic-spacing-stack-600, 48px);"></div>
      
      <div class="d-flex justify-content-between">
        <CommonButton @click="$emit('close'); reqRenewBenCardsPlanStore.$reset();" type="outlined" text="Cancelar" action="secondary"></CommonButton>
        <CommonButton
          :action="reqRenewBenCardsPlanStore.benefitsCardsPlan?.getUserCount() && reqRenewBenCardsPlanStore.canRenew() ? 'cta' : 'disabled'"
          @click="updatePlan"
          text="Confirmar valores do benefício"
        ></CommonButton>
      </div>
    </div>
</template>

<style scoped>

/* #region INITIAL */

.plan-name {
  color: var(--semantic-color-fg-emphasis, #434049);
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.benefits-values {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 32px;
  width: 100%;
}

.total-by-collab {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.value-text {
  color: var(--semantic-color-tertiary-default, #FF508C);
  font-family: 'Montserrat';
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.plan-text {
  color: var(--semantic-color-fg-default, #5D5867);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

/* #endregion */

</style>