<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { vMaska } from "maska"
import { ref, computed, onMounted, defineProps, defineEmits, defineExpose, onUnmounted, getCurrentInstance, VueElement} from 'vue';

const thisInstance = getCurrentInstance();

const labelLeft = 20;
const inputBefore = ref<HTMLElement | null>(null);
const labelElem = ref<HTMLElement | null>(null);
const currentLabelSize = ref(0);

const props = defineProps<{
  prefixIcon?: IconDefinition,
  icon?: IconDefinition,
  iconButton?: IconDefinition;
  modelValue: string,
  label: string,
  supportingText?: string,
  state?: 'disabled' | 'error' | 'default' | 'default-money-benefit',
  type?: string,
  mask?: string;
  tokenMask?: string;
  isStatic?: boolean,
}>();

defineEmits<{
  (e: 'update:model-value', value: string): void,
  (e: 'tap-trailing', evt: Event): void,
  (e: 'leave-focus', value: string): void,
  (e: 'key-down', evt: KeyboardEvent): void,
  (e: 'on-paste', evt: ClipboardEvent): void,
  (e: 'change:value', evt: Event, value: boolean): void;
}>()

defineExpose({
  setFocus: (v: boolean) => v ? inputField.value?.focus() : inputField.value?.blur(),
});

const state = computed(() => props.state ?? 'default');
const isFocused = ref(false);
const isHovered = ref(false);
const floatOn = computed(() => isFocused.value || props.modelValue.length > 0)
const inputField = ref<HTMLInputElement | null>(null);
const inputBackgroundColor = computed(() => `var(--components-input-${state.value}-background-color, rgba(0, 0, 0, 0.00))`);

const showPassword = ref(false);

function getState() {
  if (state.value == 'error') return state.value;
  if (isFocused.value) {
    return "focused";
  }
  if (isHovered.value && state.value != "disabled") {
    return "hovered";
  }
  return `${state.value}`;
}

function isStatic(){
  if(props.isStatic === undefined || !props.isStatic){
    return false;
  }
  return true;
}
let resizeObserver: ResizeObserver | null = null;
onMounted(() => {
  resizeObserver = new ResizeObserver(() => {
    currentLabelSize.value = labelElem.value?.offsetWidth ?? 0;
  });
  resizeObserver.observe(labelElem.value!);
});

onUnmounted(() => {
  resizeObserver?.disconnect();
});



</script>

<template>
  <div class="input-father">
    <div class="input">
      <FontAwesomeIcon v-if="prefixIcon" class="icon-money icon" :icon="prefixIcon"
        @click="$emit('tap-trailing', $event)" /> 
      <input
        :readonly="isStatic()"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        :disabled="state == 'disabled'"
        ref="inputField"
        class="common-input"
        v-maska 
        :data-maska-tokens="tokenMask"
        :data-maska="mask"
        :type="showPassword && type === 'password' ? 'text' : type ?? 'text'"
        @focus="isFocused = true"
        @blur="isFocused = false"
        :value="modelValue"
        :style="{
          border: `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
          color: `var(--components-input-${state ?? 'default'}-text-color, #CDC8D7)`,
          fontWeight: `var(--components-input-${state}-font-weight, 800)`,
          borderTop: isFocused || modelValue.length ? `none` : `1px solid var(--components-input-${getState()}-border-color, #CDC8D7)`,
          paddingRight: `calc(22px${props.type == 'password' ? ' + 22px' : ''}${thisInstance?.vnode?.props?.onTapTrailing ? ' + 22px' : ''})`,
        }" @input="$emit('update:model-value', ($event.target as HTMLInputElement).value)"
        @focusout="$emit('leave-focus', ($event.target as HTMLInputElement).value)" @paste="$emit('on-paste', ($event))"
        @keydown="$emit('key-down', ($event))" />
      <div ref="inputBefore" class="input-before" v-if="(isFocused || modelValue.length) && labelElem" :style="{
        backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
      }"></div>
      <div class="input-after" v-if="(isFocused || modelValue.length) && labelElem" :style="{
        backgroundColor: `var(--components-input-${getState()}-border-color, #CDC8D7)`,
        width: `calc(100% - ${labelLeft}px - (var(--semantic-border-radius-default, 7px) * 2) - ${currentLabelSize}px)`,
      }"></div>

      <div ref="labelElem" :class="[
        'semantic-typography-body-regular-default',
        'input-field-label',
        {
          'input-field-label-focused': floatOn,
          'semantic-typography-body-bold-small': floatOn
        }
      ]" :style="{
          color: `var(--components-input-${getState()}-label-color, #6E6979)`,
          left: labelLeft,
        }">
        <FontAwesomeIcon v-if="icon" :icon="icon" />
        <p>
          {{ label }}
        </p>
      </div>

      <div v-if="modelValue.length" @mouseover="isHovered = true" class="input-field-icons-container">
        <FontAwesomeIcon
          v-if="thisInstance?.vnode?.props?.onTapTrailing"
          :icon="iconButton ?? Icons.imported.faXmark"
          class="input-field-icon"
          :style="{
            color: `var(--components-input-${getState()}-right-icon-color, #6E6979)`,
          }"
          @click="$emit('tap-trailing', $event)"
        />
        <FontAwesomeIcon
          v-if="type === 'password'"
          :icon="showPassword ? Icons.imported.faEyeSlash : Icons.imported.faEye"
          class="input-field-icon" @click="() => showPassword = !showPassword"
        />
      </div>
    </div>
    <p class="supporting-text" :style="`color: var(--components-input-${getState()}-supporting-text-color)`"
      v-if="supportingText">
      {{ supportingText }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.input-father {
  display: flex;
  flex-direction: column;
  gap: var(--semantic-spacing-stack-100, 8px);
  width: 100%;
}

.icon-money {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.icon {
  color: var(--components-input-default-right-icon-color, #AAA4B6);
}

.common-input {
  border-radius: var(--semantic-border-radius-default, 7px);
  padding: var(--semantic-spacing-inset-200, 16px);
  font-size: 16px;
  padding-left: 24px;
  width: 100%;
  background-color: v-bind('inputBackgroundColor');
  border-top: none;
  position: relative;
}

.input {
  position: relative;
}

.input-after,
.input-before {
  position: absolute;
  top: 0;
  height: .1px;
}

.input-before {
  left: calc(var(--semantic-border-radius-default, 7px) / 2);
  width: 10px;
}

.input-after {
  right: calc(var(--semantic-border-radius-default, 7px) / 2);
}

.input-field-icons-container {
  position: absolute;
  display: flex;
  gap: 4px;
  align-items: center;
  bottom: 18px;
  right: 20px;
}

.input-field-icon {
  cursor: pointer;
  width: 20px;
}

.input-field-label {
  position: absolute;
  left: 21px;
  top: 50%;
  line-height: 16px;
  transition: all 0.1s;
  pointer-events: none;
  display: flex;
  padding: 0px var(--semantic-spacing-stack-025, 2px);
  gap: var(--semantic-spacing-inline-100, 8px);
  align-items: center;
  transform: translate(0%, -50%);
}

.input-field-label-focused {
  position: absolute;
  top: 0%;
  font-size: 12px;
  gap: var(--semantic-spacing-inline-100, 4px);
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.supporting-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 300;
}
</style>