<script setup lang="ts">
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { defineProps, ref } from 'vue';
import CommonInput from '../CommonInput.vue';
import CommonSwitch from '../CommonSwitch.vue';
import FormatUtils from '@/utils/format_utils';

const props = defineProps<{
  icon: IconDefinition;
  inputIcon?: IconDefinition;
  title: string;
  subtitle?: string;
  description?: string;
  value: string;
  isEnabled: boolean;
  errorText?: string;
}>();

const emit = defineEmits<{
  (e: 'change', value: string, isEnabled: boolean): void;
  (e: 'set-is-enabled', value: boolean): void;
}>();

function getState() {
  if (props.errorText) return 'error';
  return Boolean(props.value) || props.isEnabled ? 'default-money-benefit' : 'disabled';
}

</script>

<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <FontAwesomeIcon class="icon" :icon="icon" />
        <div style="width: 12px;"></div>
        <div>
          <div class="d-flex">
            <p class="title">{{ title }}</p>
            <div style="width: 4px;"></div>
            <p v-if="subtitle" class="subtitle">({{ subtitle }})</p>
          </div>
          <p v-if="description" class="description">{{ description }}</p>
        </div>
      </div>
      <CommonSwitch
        :value="isEnabled"
        @change="(value) => {
          emit('set-is-enabled', value);
        }"
      />
    </div>
    <div style="height: 8px;"></div>
    <CommonInput
      :modelValue="value"
      :icon="inputIcon"
      :supporting-text="errorText"
      label="Valor do benefício"
      :mask="FormatUtils.formatMoney(value, true)"
      :state="getState()"
      @update:model-value="(e) => {
        $emit('change', e, isEnabled);
      }"
      @tap-trailing="$emit('change', '', isEnabled)"
    />
  </div>
</template>

<style scoped>
.title {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.subtitle {
  color: var(--semantic-color-fg-subtle, #AAA4B6);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.description {
  color: var(--semantic-color-fg-subtle, #AAA4B6);
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.icon {
  width: var(--semantic-size-icons-175, 14px);
  height: var(--semantic-size-icons-175, 14px);
  color: var(--semantic-color-fg-default, hsla(260, 8%, 37%, 1));
}

</style>