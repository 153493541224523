<script setup lang="ts">
import { computed, onMounted, ref, type PropType } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import { DOMUtils } from '@/utils/dom_utils';

const commonButtonElem = ref<HTMLElement | null>(null);
const quadrant = ref({
  isSuperior: false,
  isLeft: false,
});
const maxHeight = ref(0);

const props = defineProps(
  {
    icon: {
      type: Object as PropType<IconDefinition>,
      default: null,
    },
    text: {
      type: String,
      default: "",
    },
    action: {
      type: String as PropType<'cta' | 'error' | 'secondary' | 'disabled'>,
      default: "cta",
    },
    type: {
      type: String as PropType<'filled' | 'outlined' | 'text'>,
      default: "filled",
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large'>,
      default: "medium",
    }
  },
);

defineEmits<{
  (e: 'change-page'): void,
}>();

const cssVars = computed(
  () => {
    return {
      "--btn-border-color": `var(--components-button-color-${props.action}-${props.type}-default-border-color)`,
      "--btn-border-color-hover": `var(--components-button-color-${props.action}-${props.type}-hover-border-color)`,
      "--btn-border-color-active": `var(--components-button-color-${props.action}-${props.type}-active-border-color)`,

      "--btn-background": `var(--components-button-color-${props.action}-${props.type}-default-background-color, #E6E4EB)`,
      "--btn-background-hover": `var(--components-button-color-${props.action}-${props.type}-hover-background-color)`,
      "--btn-background-active": `var(--components-button-color-${props.action}-${props.type}-active-background-color)`,

      "--btn-text-color": `var(--components-button-color-${props.action}-${props.type}-default-text-color)`,
      "--btn-text-color-hover": `var(--components-button-color-${props.action}-${props.type}-hover-text-color)`,
      "--btn-text-color-active": `var(--components-button-color-${props.action}-${props.type}-active-text-color)`,

      "--btn-gap": `calc(var(--components-button-spacing-large-gap) * 1px)`,
      borderRadius: `calc(var(--components-button-border-radius-${props.size}) * 1px)`,

          
      "--btn-padding": `calc(var(--components-button-spacing-${props.size}-padding-vertical) * 1px${props.action !== 'disabled' ? ' - 1px' : ''}) calc(var(--components-button-spacing-${props.size}-padding-horizontal${props.icon ? "-with-icon" : ""}) * 1px)`,
      "--btn-padding-left": props.icon ? `calc(var(--components-button-spacing-${props.size}-padding-horizontal-with-icon) * 1px)` : `calc(var(--components-button-spacing-${props.size}-padding-horizontal) * 1px)`,
    };
  }
);

onMounted(updateData);
window.addEventListener('resize', updateData);

function updateData() {
  if (!commonButtonElem.value) return;
  quadrant.value = DOMUtils.detectQuadrant(commonButtonElem.value!);
  const margin = 10;
  maxHeight.value = DOMUtils.getDistanceToTopBottom(commonButtonElem.value!) - commonButtonElem.value!.clientHeight - margin;
}

defineExpose({
  getElement: () => commonButtonElem.value,
});

const showOptions = ref(false);

</script>

<template>
  <button
    class="btn common-button"
    :disabled="props.action === 'disabled'"
    v-on:click="() => $emit('change-page')"
    type="button"
    :style="cssVars"
    @mouseleave="showOptions = false"
    ref="commonButtonElem"
  >
    <div :class="`btn-content typography-${props.size}`">
      <FontAwesomeIcon v-if="icon" :icon="icon"></FontAwesomeIcon>
      {{ text }}
      <FontAwesomeIcon
        :icon="Icons.imported.faChevronDown"
        v-if="$slots.default"
        class="icon"
        :style="{
          transform: showOptions ? 'rotate(180deg)' : 'rotate(0deg)',
        }"
        @click="(e: MouseEvent) => {
          e.stopPropagation();
          showOptions = !showOptions;
        }
      ">
      </FontAwesomeIcon>
    </div>
    <div
      class="options"
      v-if="showOptions"
      @click="(mouseEvent: MouseEvent) => mouseEvent.stopPropagation()"
      :style="{
        top: quadrant.isSuperior ? '100%' : '0%',
        transform: quadrant.isSuperior ? 'translateY(0)' : 'translateY(-100%)',
        maxHeight: maxHeight + 'px',
        left: quadrant.isLeft ? '0' : 'unset',
        right: quadrant.isLeft ? 'unset' : '0',
      }"
    >
      <slot></slot>
    </div>
  </button>
</template>

<style scoped>
button.common-button>.btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--btn-gap);
}

button.common-button {
  text-align: center;
  border-radius: var(--components-button-border-radius-medium, 7px);
  outline: none;
  border: none;
  font-weight: 600;
  background-color: var(--btn-background);
  border: 1px solid var(--btn-border-color);
  color: var(--btn-text-color);
  padding: var(--btn-padding);
  padding-left: var(--btn-padding-left);
  position: relative;
}

button:hover {
  cursor: pointer;
  background-color: var(--btn-background-hover);
  border: 1px solid var(--btn-border-color-hover);
  color: var(--btn-text-color-hover);
}

button:active {
  background-color: var(--btn-background-active);
  border: 1px solid var(--btn-border-color-active);
  color: var(--btn-text-color-active);
}

.icon {
  transition: transform 0.3s ease;
}

/* #region  Button Typography */

.typography-small {
  font-family: var(--components-button-typography-small-font-family);
  font-weight: var(--components-button-typography-small-font-weight);
  line-height: var(--components-button-typography-small-line-height);
  font-size: calc(var(--components-button-typography-small-font-size) * 1px);
  letter-spacing: var(--components-button-typography-small-letter-spacing);
  margin-bottom: calc(var(--components-button-typography-small-paragraph-spacing) * 1px);
  text-decoration: var(--components-button-typography-small-text-decoration);
  text-transform: var(--components-button-typography-small-text-case);
}

.typography-medium {
  font-family: var(--components-button-typography-medium-font-family);
  font-weight: var(--components-button-typography-medium-font-weight);
  line-height: var(--components-button-typography-medium-line-height);
  font-size: calc(var(--components-button-typography-medium-font-size) * 1px);
  letter-spacing: var(--components-button-typography-medium-letter-spacing);
  margin-bottom: calc(var(--components-button-typography-medium-paragraph-spacing) * 1px);
  text-decoration: var(--components-button-typography-medium-text-decoration);
  text-transform: var(--components-button-typography-medium-text-case);
}

.typography-large {
  font-family: var(--components-button-typography-large-font-family);
  font-weight: var(--components-button-typography-large-font-weight);
  line-height: var(--components-button-typography-large-line-height);
  font-size: calc(var(--components-button-typography-large-font-size) * 1px);
  letter-spacing: var(--components-button-typography-large-letter-spacing);
  margin-bottom: calc(var(--components-button-typography-large-paragraph-spacing) * 1px);
  text-decoration: var(--components-button-typography-large-text-decoration);
  text-transform: var(--components-button-typography-large-text-case);
}

/* #endregion */

.options {
  position: absolute;
  background-color: var(--token-semantic-color-bg-subtle);
  color: var(--semantic-color-gray-default, var(--option-color-gray-800, #434049));
  font-weight: 500;
  padding: 8px 4px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);

  display: flex;
  flex-direction: column;

  min-width: 100%;
  left: 0px;
  overflow: auto;
  z-index: 2;
}

.options p {
  padding: 4px 8px;
  border-radius: 7px;
}

.options p:hover {
  cursor: pointer;
  background-color: var(--token-semantic-color-bg-surface);
}

</style>
