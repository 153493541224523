import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { v4 as uuidv4 } from 'uuid';
import { BenefitsCardPlanCategory } from "./benefits_card_plan_category_model";
import { CardCategoryType } from "./card_category_type";

@JsonObject()
export class BenefitsCardPlan {

  @JsonProperty()
  private id: string;

  @JsonProperty()
  private planName: string;

  @JsonProperty()
  private deliveryAreaId: string;

  @JsonProperty()
  private branchId: string | null;

  @JsonProperty()
  private createdAtUtc: Date;

  @JsonProperty()
  private updatedAtUtc: Date | null;

  @JsonProperty({type: BenefitsCardPlanCategory})
  private categories: BenefitsCardPlanCategory[];

  @JsonProperty()
  private userCount: number;

  constructor(params?: {
    id?: string,
    planName: string,
    deliveryAreaId: string,
    branchId?: string | null
    createdAtUtc?: Date;
    updatedAtUtc?: Date;
    categories?: BenefitsCardPlanCategory[];
    userCount?: number
  }) {
    if (!params) return;
    this.id = params.id ?? uuidv4();
    this.planName = params.planName;
    this.deliveryAreaId = params.deliveryAreaId;
    this.branchId = params.branchId ?? null;
    this.createdAtUtc = params.createdAtUtc ?? new Date();
    this.updatedAtUtc = params.updatedAtUtc ?? null;
    this.categories = params.categories ?? [];
    this.userCount = params.userCount ?? 0;
  }

  public getId() {
    return this.id;
  }

  public getPlanName() {
    return this.planName;
  }

  public getDeliveryAreaId() {
    return this.deliveryAreaId;
  }

  public getBranchId() {
    return this.branchId;
  }

  public getCategories() {
    return this.categories;
  }

  public getReduceCategories() {
    return this.categories.map(value => value.getCustomCategory());
  }

  public getUserCount() {
    return this.userCount;
  }

  public setUserCount(userCount: number) {
    this.userCount = userCount;
    return this;
  }

  public getTotalPlanValue() { 
    return this.categories.reduce((total, category) => total + category.getValue(), 0) * this.userCount;
  }

  public getPlanValue() {
    return this.categories.reduce((total, category) => total + category.getValue(), 0);
  }

  public getCategoryValue(category: CardCategoryType) {
    return this.categories.find(c => c.getCategory() === category)?.getValue() ?? 0;
  }

  public getCreationDate() {
    return this.createdAtUtc;
  }

  public getUpdateDate() {
    return this.updatedAtUtc;
  }

  public compare(categories: BenefitsCardPlanCategory[]) {
    if (this.categories.length !== categories.length) return false;
    return this.categories.every(category => {
      const categoryInArray = categories.find(c => c.getCategory() === category.getCategory());
      return categoryInArray && category.getValue() === categoryInArray?.getValue();
    });
  }
  
}