import { GlobalData } from "./global";

import "./registerServiceWorker";
import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import DarkMode from "./infra/dark_mode/dark_mode";
import "@vuepic/vue-datepicker/dist/main.css";

DarkMode.updateTheme();

import "./assets/base.css";
import { Icons } from "./font-awesome";
import VueGtag from "vue-gtag";
import Constants from "./infra/constants/constants";

Icons.import();

const app = createApp(App);

if (Constants.stage === "prod") {
  app.use(
    VueGtag,
    {
      config: { id: Constants.analyticsTag },
    },
    router
  );
}

app.use(GlobalData.Pinia);
app.use(router);

app.mount("#app");
