<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { OpenModalsGlobal } from '@/infra/globals/open_modals_global';
import { MinimizedType, useMinimizedModalStore } from '@/stores/minimized_modal_store/minimized_modal_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { onUnmounted, ref } from 'vue';
import { defineProps, defineEmits, defineExpose } from 'vue';

const minimizedModalStore = useMinimizedModalStore;

const props = defineProps({
  minimizedData: {
    type: Object as () => {
      type: MinimizedType;
      name?: string;
    },
    default: {
      name: "Modal",
      type: MinimizedType.DEFAULT,
    },
  },
  canClose: {
    type: Boolean,
    default: true,
  },
   isTop:{
    type: Boolean,
    default: false,
   },
  canMinimize: {
    type: Boolean,
    default: false,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
  teleport: { 
    type: Boolean,
    default: true,
  }
});

defineEmits<{
  (e: 'on-close-by-button'): void;
  (e: 'on-minimize-by-button'): void;
}>();

const blackout = ref<HTMLDivElement>();
const commonModal = ref<HTMLDivElement>();
const zIndexRef = ref<number>(2);

const promise = ref<Promise<any>>();
const resolve = ref<(value: any) => void>();
const subscribed = ref<boolean>(false);

const minimizedId = ref<string | null>(null);

const modalName = ref<string | null>(null);

defineExpose({
  open: open,
  close: closeButton,
  minimize: minimizeButton,
  scrollTo: (params: ScrollToOptions) => {
    commonModal.value?.scrollTo(params);
  },
  getModalElement: () => commonModal.value,
});

async function open<T>(params: {
  name?: string;
  zIndex?: number;
} = {}): Promise<T> {
  modalName.value = params.name ?? props.minimizedData?.name ?? null;
  if (minimizedId.value) minimizedModalStore.removeMinimizedById(minimizedId.value);
  if (blackout.value) blackout.value?.classList.add("open");
  zIndexRef.value = params?.zIndex ?? 2;
  promise.value = new Promise<T>((res) => resolve.value = res);
  if (!subscribed.value) OpenModalsGlobal.incrementModalCounter();
  subscribed.value = true;
  return await promise.value;
}

function maximize() {
  if (!minimizedId.value) return;
  if (blackout.value) blackout.value?.classList.add("open");
  minimizedModalStore.removeMinimizedById(minimizedId.value);
  if (!subscribed.value) OpenModalsGlobal.incrementModalCounter();
  subscribed.value = true;
  minimizedId.value = null;
}

function minimizeButton() {
  if (!props.canMinimize) return;
  if (props.minimizedData) minimizedId.value = minimizedModalStore.addMinimized({
    name: modalName.value ?? 'Modal',
    type: props.minimizedData.type,
    onClose: closeButton,
    onMaximize: maximize,
  });
  if (blackout.value) blackout.value?.classList.remove("open");
  if (subscribed.value) OpenModalsGlobal.decrementModalCounter();
  subscribed.value = false;
}

function closeButton<T>(value?: T) {
  if (blackout.value) blackout.value?.classList.remove("open");
  if (subscribed.value) OpenModalsGlobal.decrementModalCounter();
  subscribed.value = false;
  
  resolve.value?.call(null, value);
}

onUnmounted(() => {
  if (minimizedId.value) minimizedModalStore.removeMinimizedById(minimizedId.value);
  closeButton();
});

</script>

<template>
  <Teleport to="body" :disabled="!teleport">
    <div class="blackout" :style="{
      zIndex: zIndexRef
    }" ref="blackout">
      <div ref="commonModal" class="common-modal" :style="isFullScreen ? { width: '100%', height: '100%', borderRadius: '0', overflowY:'auto', padding: '0' } : {}">
        <div :class="isFullScreen ? 'modal-fullscreen-content' : ''" :style=" isFullScreen && isTop ? { alignSelf:'start', height: '100%'} : {}"> 
          <slot></slot>
        </div>
        <div class="buttons-controller">
          <FontAwesomeIcon :icon="Icons.imported.faWindowMinimize" class="control-btn" v-if="canMinimize"
            v-on:click="() => {
              minimizeButton();
              $emit('on-minimize-by-button');
            }"
          />
          <FontAwesomeIcon :icon="Icons.imported.faXmark" class="control-btn" v-if="canClose"
            v-on:click="() => {
              closeButton(undefined);
              $emit('on-close-by-button');
            }"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.blackout.open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--token-modal-color-background-color);
  border-radius: var(--components-modal-border-radius-default, 7px);
  padding: 16px;
  z-index: 3;
  color: var(--token-modal-color-text-color);
  display: grid;
  overflow: auto;
  padding:  var(--semantic-size-components-modal-spacing-container-padding-horizontal, 64px) var(--semantic-size-components-modal-spacing-container-padding-bottom-with-button, 64px);
  padding-top: var(--semantic-size-components-modal-spacing-container-padding-vertical, 80px);
}

.modal-fullscreen-content {
  max-width: 700px;
  width: 100%;
  padding: 64px 0;
  align-self: center;
  justify-self: center;
}

.buttons-controller {
  position: absolute;
  top: 28px;
  right: 26px;
  display: flex;
  align-items: flex-start;
  gap: var(--semantic-size-semantic-spacing-inline-300, 24px);
  z-index: 3;
  transform: translate(0%, 0%);
}

.control-btn {
  width: 12px;
  cursor: pointer;
  color: var(--token-modal-color-close-icon-color);
}

@media screen and (max-width: 768px) {
  .common-modal {
    width: calc(100% - 32px);
    padding: 16px 16px;
  }
}

</style>

