import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class Branch {

  @JsonProperty()
  private id: string;
  
  @JsonProperty()
  private name: string;

  @JsonProperty()
  private deliveryAreaId: string;

  @JsonProperty()
  private addressId: string;

  @JsonProperty()
  private createdBy: string;

  @JsonProperty()
  private createdAtUtc: string;

  @JsonProperty()
  private imgUrl?: string;

  constructor(params?: {
    id: string;
    name: string;
    deliveryAreaId: string;
    addressId: string;
    createdBy: string;
    createdAtUtc: string;
    imgUrl?: string;
  }) {
    if (!params) return;
    this.id = params.id;
    this.name = params.name;
    this.deliveryAreaId = params.deliveryAreaId;
    this.addressId = params.addressId;
    this.createdBy = params.createdBy;
    this.createdAtUtc = params.createdAtUtc;
    this.imgUrl = params.imgUrl;
  }

  public getId() {
    return this.id;
  }

  public getName() {
    return this.name;
  }

  public getAddressId() {
    return this.addressId;
  }

  public getImgUrl() {
    return this.imgUrl;
  }

}