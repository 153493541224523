export enum StatusPlans {
  Renovated_for = 'Renovado para',
  Renovated_until = 'Renovado até',
  Replace_balance = 'Substituir saldo',
  Almost_expiring = 'Quase expirando!',
  Not_yet_renovated = 'Ainda não renovado!',
  Not_renovated_for_two_months = 'Não renovado há 2 meses!',
  Not_renovated_for_months = 'Não renovado há meses',
  Never_renovated = 'Nunca renovado',
  Delivered = 'Entregue',
}
