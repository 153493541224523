import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class AccountBalanceUpdate {

  @JsonProperty()
  debit: number;

  @JsonProperty()
  credit: number;

  constructor(params?: {
    debit: number;
    credit: number;
  }) {
    if (!params) return;
    this.debit = params.debit;
    this.credit = params.credit;
  }

}