import { JsonSerializer } from "typescript-json-serializer";
import { ValueResult } from "@/models/value-result";
import { PaginatedList } from "@/models/paginated_list";
import { BenefitsCardPlan } from "@/models/benefits_cards/benefits_card_plan_model";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";
import { BenefitsCardPlanCategory } from "@/models/benefits_cards/benefits_card_plan_category_model";
import { UpdateCardPlan } from "@/models/benefits_cards/update_card_plan_model";
import { CreateCardPlan } from "@/models/benefits_cards/create_card_plan_model";
import { RenewalCardPlan } from "@/models/benefits_cards/renewal_card_plan_model";
import { RequestRenew } from "@/models/benefits_cards/request_renew_model";

export class CreditCardPlansBFFBackofficeModule {

  static readonly controller = "credit-card-plans";
  static readonly serializer = new JsonSerializer();

  static async getBenefitsCardPlansBy(params: {
    page?: number;
    pageSize?: number;
    deliveryAreaId?: string;
    ids?: string[];
  }) {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}`, {
        params: {
          page: params.page,
          pageSize: params.pageSize,
          deliveryAreaId: params.deliveryAreaId,
          ids: params.ids,
          populateUserCount: true
        }
      });
      const items = this.serializer.deserializeObjectArray(res.data.items, BenefitsCardPlan) as BenefitsCardPlan[];
      return new ValueResult({
        value: new PaginatedList({
          items: items,
          pageCount: res.data.pageCount,
        })
      });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async createBenefitsCardPlans(data: CreateCardPlan): Promise<ValueResult<BenefitsCardPlan | null>> {
    try {
      const json = this.serializer.serializeObject(data);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}`, json);
      const item = this.serializer.deserializeObject(res.data, BenefitsCardPlan);
      return new ValueResult({
        value: item
      });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async renew(params: RequestRenew): Promise<ValueResult<RenewalCardPlan | null>> {
    try {
      if (Boolean(params.cardPlanIds) && Boolean(params.userIds)) {
        throw new Error("Não é possível renovar planos e usuários ao mesmo tempo");
      }
      const json = this.serializer.serializeObject(params);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/renew`, json);
      const value = this.serializer.deserializeObject(res.data, RenewalCardPlan)!;
      return new ValueResult<RenewalCardPlan>({ value });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async patchBenefitsCardPlan(params: {
    id: string;
    data: UpdateCardPlan,
  }): Promise<ValueResult<BenefitsCardPlan | null>> {
    try {
      const json = this.serializer.serializeObject(params.data);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().patch(`/${this.controller}/${params.id}`, json);
      const item = this.serializer.deserializeObject(res.data, BenefitsCardPlan);
      return new ValueResult({ value: item });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async deleteBenefitsCardPlan(params: {
    id: string;
  }) {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().delete(`/${this.controller}/${params.id}`);
      const count = res.data;
      return new ValueResult({ value: count });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }
}