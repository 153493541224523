import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h3", {
    class: "value",
    style: _normalizeStyle({ color: $setup.getColor() })
  }, [
    _createElementVNode("b", {
      class: "default-font",
      style: _normalizeStyle(`font-size:${$props.fontSize}px`)
    }, "R$ ", 4 /* STYLE */),
    _createElementVNode("b", {
      class: "default-font",
      style: _normalizeStyle(`font-size:${$props.fontReal}px`)
    }, _toDisplayString($setup.FormatUtils.beforeComma($props.value, true)), 5 /* TEXT, STYLE */),
    _createElementVNode("b", {
      class: "default-font",
      style: _normalizeStyle(`font-size:${$props.fontSize}px`)
    }, "," + _toDisplayString($setup.FormatUtils.afterComma($props.value)), 5 /* TEXT, STYLE */)
  ], 4 /* STYLE */))
}