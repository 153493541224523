<script setup lang="ts">
import { Icons } from '@/font-awesome';
import CustomSelector from './CustomSelector.vue';
import { BranchAddresses } from '@/models/branch_addresses_model';
import { ref } from 'vue';
import { DeliveryType } from '@/models/delivery_type_enum';
import BranchSelector from './BranchSelector.vue';

const deliveryTypeSelector = ref<InstanceType<typeof CustomSelector>>();
const branchSelector = ref<InstanceType<typeof BranchSelector>>();
const pickupBranchId = ref<string | null>(null);
const deliveryType = ref<DeliveryType | null>(null);

const props = defineProps<{
  hideDelivery?: boolean;
  branchAddresses?: BranchAddresses | null;
  zIndex?: number;
}>();

defineExpose({
  clearSelection: () => {
    branchSelector.value?.clearSelection();
    deliveryTypeSelector.value?.clearSelection();
    deliveryType.value = null;
    pickupBranchId.value = null;
  },
  setSelected: (value: number) => {
    deliveryTypeSelector.value?.setSelected(value);
  }
});

defineEmits<{
  (e: 'update:delivery-type', value: DeliveryType | null): void,
  (e: 'update:pickup-branch-id', value: string | null): void,
}>()

</script>

<template>
  <div v-if="!props.hideDelivery" class="content-info">
    <CustomSelector ref="deliveryTypeSelector" :icon="Icons.imported.faTruck" empty-message="Tipo de entrega"
      @change="(_, index) => {
        deliveryType = index !== null ? DeliveryType[Object.keys(DeliveryType)[index]] : null;
        if (deliveryType === DeliveryType.Porta_a_porta) {
          pickupBranchId = null;
          $emit('update:pickup-branch-id', pickupBranchId);
        }
        $emit('update:delivery-type', deliveryType);
      }"
      :options="Object.keys(DeliveryType).map((key) => ({
      title: key.replaceAll('_', ' '),
      }))" />
    <BranchSelector ref="branchSelector"
      v-if="props.branchAddresses && deliveryType !== DeliveryType.Porta_a_porta "
      :z-index="zIndex ?? 1001"
      @change="(_, index) => {
        pickupBranchId = props.branchAddresses?.getIndex(index)?.branch?.getId() ?? null;
        $emit('update:pickup-branch-id', pickupBranchId);
      }"
      @create="(_, branches) => {
        props.branchAddresses?.concat(branches);
        pickupBranchId = branches.getIndex(0)?.branch?.getId() ?? null;
        const currentBranches = props.branchAddresses?.getBranches() ?? [];
        const index = currentBranches.findIndex((branch) => branch.getId() === branches.getIndex(0)?.branch?.getId()) ?? 0;
        branchSelector?.setSelected(index);
      }"
      :branches="(props.branchAddresses as BranchAddresses)" />
  </div>
</template>

<style scoped>
.content-info {
  display: flex;
  flex-direction: column;
  gap: var(--semantic-spacing-stack-200, 16px);
}
</style>