import axios, { AxiosInstance } from 'axios';
import Constants from '../constants/constants';
import LoggedAdminStorage from '../local_storage/logged_admin_storage';

class BffFoodPassBackofficeApiRequester {

  private static axios: AxiosInstance | null = null;

  public static getAxios(): AxiosInstance {
    if (BffFoodPassBackofficeApiRequester.axios) return BffFoodPassBackofficeApiRequester.axios;
    BffFoodPassBackofficeApiRequester.axios = axios.create({
      baseURL: Constants.getBffEndpoint()
    });
    BffFoodPassBackofficeApiRequester.axios.interceptors.request.use(async (config) => {
      config.headers.Authorization = `Bearer ${LoggedAdminStorage.getToken()}`;
      return config;
    });
    return BffFoodPassBackofficeApiRequester.axios;
  }
}

export default BffFoodPassBackofficeApiRequester;