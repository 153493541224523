import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4ccce1c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-father" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = ["readonly", "disabled", "data-maska-tokens", "data-maska", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.prefixIcon)
        ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
            key: 0,
            class: "icon-money icon",
            icon: $props.prefixIcon,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('tap-trailing', $event)))
          }, null, 8 /* PROPS */, ["icon"]))
        : _createCommentVNode("v-if", true),
      _withDirectives(_createElementVNode("input", {
        readonly: $setup.isStatic(),
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($setup.isHovered = true)),
        onMouseleave: _cache[2] || (_cache[2] = ($event: any) => ($setup.isHovered = false)),
        disabled: $setup.state == 'disabled',
        ref: "inputField",
        class: "common-input",
        "data-maska-tokens": $props.tokenMask,
        "data-maska": $props.mask,
        type: $setup.showPassword && $props.type === 'password' ? 'text' : $props.type ?? 'text',
        onFocus: _cache[3] || (_cache[3] = ($event: any) => ($setup.isFocused = true)),
        onBlur: _cache[4] || (_cache[4] = ($event: any) => ($setup.isFocused = false)),
        value: $props.modelValue,
        style: _normalizeStyle({
          border: `1px solid var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
          color: `var(--components-input-${$setup.state ?? 'default'}-text-color, #CDC8D7)`,
          fontWeight: `var(--components-input-${$setup.state}-font-weight, 800)`,
          borderTop: $setup.isFocused || $props.modelValue.length ? `none` : `1px solid var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
          paddingRight: `calc(22px${$setup.props.type == 'password' ? ' + 22px' : ''}${$setup.thisInstance?.vnode?.props?.onTapTrailing ? ' + 22px' : ''})`,
        }),
        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:model-value', ($event.target as HTMLInputElement).value))),
        onFocusout: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('leave-focus', ($event.target as HTMLInputElement).value))),
        onPaste: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('on-paste', ($event)))),
        onKeydown: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('key-down', ($event))))
      }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_3), [
        [$setup["vMaska"]]
      ]),
      (($setup.isFocused || $props.modelValue.length) && $setup.labelElem)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            ref: "inputBefore",
            class: "input-before",
            style: _normalizeStyle({
        backgroundColor: `var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
      })
          }, null, 4 /* STYLE */))
        : _createCommentVNode("v-if", true),
      (($setup.isFocused || $props.modelValue.length) && $setup.labelElem)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "input-after",
            style: _normalizeStyle({
        backgroundColor: `var(--components-input-${$setup.getState()}-border-color, #CDC8D7)`,
        width: `calc(100% - ${$setup.labelLeft}px - (var(--semantic-border-radius-default, 7px) * 2) - ${$setup.currentLabelSize}px)`,
      })
          }, null, 4 /* STYLE */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        ref: "labelElem",
        class: _normalizeClass([
        'semantic-typography-body-regular-default',
        'input-field-label',
        {
          'input-field-label-focused': $setup.floatOn,
          'semantic-typography-body-bold-small': $setup.floatOn
        }
      ]),
        style: _normalizeStyle({
          color: `var(--components-input-${$setup.getState()}-label-color, #6E6979)`,
          left: $setup.labelLeft,
        })
      }, [
        ($props.icon)
          ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
              key: 0,
              icon: $props.icon
            }, null, 8 /* PROPS */, ["icon"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("p", null, _toDisplayString($props.label), 1 /* TEXT */)
      ], 6 /* CLASS, STYLE */),
      ($props.modelValue.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            onMouseover: _cache[11] || (_cache[11] = ($event: any) => ($setup.isHovered = true)),
            class: "input-field-icons-container"
          }, [
            ($setup.thisInstance?.vnode?.props?.onTapTrailing)
              ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                  key: 0,
                  icon: $props.iconButton ?? $setup.Icons.imported.faXmark,
                  class: "input-field-icon",
                  style: _normalizeStyle({
            color: `var(--components-input-${$setup.getState()}-right-icon-color, #6E6979)`,
          }),
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('tap-trailing', $event)))
                }, null, 8 /* PROPS */, ["icon", "style"]))
              : _createCommentVNode("v-if", true),
            ($props.type === 'password')
              ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
                  key: 1,
                  icon: $setup.showPassword ? $setup.Icons.imported.faEyeSlash : $setup.Icons.imported.faEye,
                  class: "input-field-icon",
                  onClick: _cache[10] || (_cache[10] = () => $setup.showPassword = !$setup.showPassword)
                }, null, 8 /* PROPS */, ["icon"]))
              : _createCommentVNode("v-if", true)
          ], 32 /* NEED_HYDRATION */))
        : _createCommentVNode("v-if", true)
    ]),
    ($props.supportingText)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "supporting-text",
          style: _normalizeStyle(`color: var(--components-input-${$setup.getState()}-supporting-text-color)`)
        }, _toDisplayString($props.supportingText), 5 /* TEXT, STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}