import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Branch } from "@/models/branch_model";
import { AddressModel } from "@/models/address_model";

@JsonObject()
export class BranchAddresses {

  @JsonProperty()
  private branches: Branch[] = [];

  @JsonProperty()
  private addresses: AddressModel[] = [];

  constructor(params?: {
    branches: Branch[];
    addresses: AddressModel[];
  }) {
    if (!params) return;
    this.branches = params.branches ?? [];
    this.addresses = params.addresses ?? [];
    this.sort();
  }

  public getAddress(addressId: string): AddressModel | undefined {
    return this.addresses.find((address) => address.getId() === addressId);
  }

  public getIndex(index: number | null): {
    branch: Branch | null;
    address: AddressModel | null;
  } | null {
    if (index === null) return null;
    return {
      branch: this.branches[index],
      address: this.getAddress(this.branches[index].getAddressId()) ?? null,
    };
  }

  public getBranches(): Branch[] {
    return this.branches;
  }

  public sort() {
    this.branches = this.branches.sort((a, b) => a.getAddressId().localeCompare(b.getAddressId()));
    this.addresses = this.addresses.sort((a, b) => a.getId().localeCompare(b.getId()));
  }

  public concat(branches: BranchAddresses) {
    this.branches = this.branches.concat(branches.branches);
    this.addresses = this.addresses.concat(branches.addresses);
    this.sort();
  }
}