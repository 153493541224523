import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tooltip-container",
    onMouseover: $setup.showTooltip,
    onMouseleave: $setup.hideTooltip
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    ($setup.visible && $props.canShow)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['tooltiptext', $props.position])
        }, _toDisplayString($props.text), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true)
  ], 32 /* NEED_HYDRATION */))
}