import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["CommonModal"], {
    ref: "loadingV2Modal",
    "can-close": false,
    "is-full-screen": true
  }, {
    default: _withCtx(() => [
      _createVNode($setup["LoaderAnimation"], {
        ref: "loaderAnimation",
        onEndAnimation: $setup.closeImmediately
      }, null, 512 /* NEED_PATCH */)
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}