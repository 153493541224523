import { HRAdminsBFFBackofficeModule } from '@/infra/bff_backoffice/hr_admins/hr_admins_bff_backoffice_module';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';
import { ValueResult } from '@/models/value-result';
import FormatUtils from '@/utils/format_utils';
import { defineStore } from 'pinia';
import { SelectedDeliveryArea } from '@/infra/local_storage/selected_delivery_area';
import { LoginResponseModel } from '@/models/login_response_model';
import { useMinimizedModalStore } from '../minimized_modal_store/minimized_modal_store';
interface IState {
  email: string;
  password: string;
  emailError?: string;
  cpfError?: string;
}
const minizedModalStore = useMinimizedModalStore
export const useUserStore = defineStore('user_store', {
  state: (): IState => ({
    email: "",
    password: "",
  }),
  actions: {
    reset() {
      this.email = "";
      this.password = "";
    },
    async login(): Promise<ValueResult<string> | null> {
      const isValid = this.validate()
      if (!isValid) return null;
      const res = await HRAdminsBFFBackofficeModule.login({
        email: this.email,
        password: this.password,
      });
      if (res.getError()) return new ValueResult({ error: res.getError() });
      const loggedData = res.getValue()!;
      LoggedAdminStorage.setUser(loggedData);
      return new ValueResult();
    },
    async loginWithToken(token: string): Promise<ValueResult<LoginResponseModel | null>> {
      LoggedAdminStorage.setToken(token);
      const res = await HRAdminsBFFBackofficeModule.me();
      if (res.isError()) return res;
      const loggedData = res.getValue()!;
      LoggedAdminStorage.setUser(loggedData);
      return res;
    },
    validate() {
      if (!FormatUtils.isValidEmail(this.email)) {
        this.emailError = "Email inválido.";
      }
      if (!FormatUtils.isValidPassword(this.password)) {
        this.cpfError = "Senha inválida."
      }
      return this.emailError?.length || this.cpfError?.length ? false : true;
    },
    async logout(): Promise<ValueResult<string | null>> {
      try {
        minizedModalStore.$reset();
        LoggedAdminStorage.clearUser();
        SelectedDeliveryArea.clearSelectedDeliveryArea();
        return new ValueResult();
      } catch (e: any) {
        console.log(e);
        return new ValueResult({
          error: "Erro de logout.",
        });
      }
    }
  },
});