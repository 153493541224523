export class DOMUtils {
  static detectQuadrant(elem: HTMLElement): {
    isSuperior: boolean;
    isLeft: boolean;
  } {
    if (!elem) return { isSuperior: false, isLeft: false };
    const rectangle = elem.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const isSuperior = ((rectangle.top + rectangle.bottom) / 2) <= windowHeight / 2;
    const isLeft = ((rectangle.left + rectangle.right) / 2) <= windowWidth / 2;
    return { isSuperior, isLeft };
  }

  static getDistanceToTopBottom(elem: HTMLElement) {
    if (!elem) return 0;
    const quadrant = this.detectQuadrant(elem);
    const windowHeight = window.innerHeight;
    const boundingClientRect = elem.getBoundingClientRect();
    const positionTop = quadrant.isSuperior ? boundingClientRect.top : boundingClientRect.bottom;
    const res = windowHeight - positionTop;
    return quadrant.isSuperior ? res : windowHeight - res;
  }

  static copyTextToClipboard(text?: string | null) {
    if (!text) return;
    navigator.clipboard.writeText(text);
  }

  static printDOM(elem?: HTMLElement) {
    window.print();
  }
}