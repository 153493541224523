<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';

defineProps<{
  name: string;
  label?: string;
  value: boolean;
}>();

defineEmits<{
  (e: 'change', evt: Event, value: boolean): void;
}>();

</script>

<template>
  <div class="text-checkbox-container">
    <input type="checkbox" :checked="value" class="custom-checkbox"
      @change="$emit('change', $event, ($event.target as HTMLInputElement).checked)" :name="name" />
    <p v-if="label" class="label">{{ label }}</p>
  </div>
</template>

<style>
input {
  margin: 0;
}

.text-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  color: var(--input-default-supporting-text-color, #6B7280);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.custom-checkbox:checked::before {
  content: "";
  display: flex;
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 12 12' fill='none'%3E%3Cg clip-path='url(%23clip0_1281_2830)'%3E%3Cpath d='M11.0295 2.47034C11.3225 2.76331 11.3225 3.23909 11.0295 3.53206L5.02949 9.53206C4.73652 9.82502 4.26074 9.82502 3.96777 9.53206L0.967773 6.53206C0.674805 6.23909 0.674805 5.76331 0.967773 5.47034C1.26074 5.17737 1.73652 5.17737 2.02949 5.47034L4.4998 7.93831L9.97012 2.47034C10.2631 2.17737 10.7389 2.17737 11.0318 2.47034H11.0295Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1281_2830'%3E%3Crect width='10.5' height='12' fill='white' transform='translate(0.75)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  width: 13px;
  height: 13px;
  padding: 0px 0.75px;
  justify-content: center;
  align-items: center;
  background-color: var(--option-color-purple, #8A2BE2);
  border-radius: 2px;
}
</style>