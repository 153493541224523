<script setup lang="ts">
import { AdminUser } from '@/models/admins/admin_model';
import CommonButton from '@/components/CommonButton.vue';

const props = defineProps<{
  currentPage: number;
  secret: string;
  qrCodeUrl: string;
  adminUser: AdminUser,
}>();

defineEmits<{
  (e: 'change-page', page: number): void
  (e: 'on-initialize'): void
}>();

</script>

<template>
  <div class="welcome-two-factor-content">
    <div>
    <img src="@/assets/icons/boas_vindas.svg" alt="Boas-vindas" v-if="!$props.adminUser?.getFirstName()"/>
    <img src="@/assets/icons/unauthorized.svg" height="257px" alt="Sem autenticação" v-else/>
    <div class="d-flex flex-column gap-2">
      <div style="height: var(--semantic-spacing-stack-300, 32px);"></div>
       <h1 class="title" v-if="!$props.adminUser?.getFirstName()">Boas Vindas!</h1>
       <h1 class="title2" v-else>Opa, você ainda não configurou a autenticação de 2 fatores!</h1>
       <div v-if="!$props.adminUser?.getFirstName()">
        <p class="sub-title">Precisamos configurar sua senha e a autentificação de 2 fatores, para garantir a segurança da sua conta!</p>
        <p class="sub-title"><b>Pronto pra começar?</b></p>
      </div>
      <div v-else>
        <p class="sub-title">É fundamental para <b>garantirmos segurança máxima do Foodpass da sua empresa!</b></p>
      </div>
    </div>
    <div style="height: var(--semantic-spacing-stack-600, 48px);"></div>
    <CommonButton
      class="w-100"
      @click="$emit('on-initialize')" 
      :text="$props.adminUser?.getFirstName() ? 'Configurar autenticação' : 'Começar configuração'"
     />
 </div>
</div>

</template>

<style scoped>
.welcome-two-factor-content {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 360px;
}
.title {
  color: var(--components-modal-color-default-title-color, #434049);
  font-family: Montserrat;
  text-align: center;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.title2 {
  color: var(--components-modal-color-default-title-color, #434049);
  font-family: Montserrat;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  align-self: stretch;
}

.sub-title {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sub-title b {
  font-weight: 600;
}

@media screen and (max-width: 395px) {

  .sub-title {
    font-size: 12px;
  }

}
</style>
