<script setup lang='ts'>
import { defineProps } from 'vue';
import { BasketPlan } from '@/models/basket_plans/basket_plan_model';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Icons } from '@/font-awesome';
import { BasketPlanStatus } from '@/models/basket_plans/basket_plan_status_enum';
import FormatUtils from '@/utils/format_utils';
import moment from 'moment';

const props = defineProps<{
  basketPlan: BasketPlan;
  isVisible?: boolean;
  size?: 'small' | 'medium' | 'large';
  sizeLine?: 'small' | 'medium';
}>();

const isVisible = props.isVisible;

const icons = [
  Icons.imported.faBoxOpen,
  Icons.imported.faFileCircleCheck,
  Icons.imported.faHouseCircleCheck,
];
</script>

<template>
  <div class='status-container'>
    <div class='status-icons'>
      <div v-for="(index, i) in icons" :key="basketPlan.getIcon(i).iconName" class="status-icons">
        <div v-if="props.basketPlan.getStatus() !== null" class="align-icons">
          <FontAwesomeIcon
            :style="{
              color: basketPlan.getColor(i)
            }"
            :class="size === 'medium' ? 'icons-medium' : 'icons-small'"
            :icon="basketPlan.getIcon(i)"
          />
          <div v-if=" basketPlan.getTitle(i) && isVisible"  class="title"
            :style="{
              color: basketPlan.getColor(i)
            }">
            {{ basketPlan.getTitle(i) }}
          </div>
          <div v-if="isVisible && i <= props.basketPlan.getStatus()" class="d-flex gap-1 justify-content-center">
            <p v-if="basketPlan.getDatetoShow(i)" class="day-month"> {{ FormatUtils.humanLocalizedDate(basketPlan.getDatetoShow(i) as Date) }}</p>
            <p v-if="basketPlan.getDatetoShow(i)" class="date-hour"> {{ moment(basketPlan.getDatetoShow(i)).format("HH:mm") }} </p>
          </div>
          <div v-if="i > props.basketPlan.getStatus() && isVisible" class="d-flex gap-1 justify-content-center style-null-date">
            <p>- - - -</p>
          </div>
        </div>
        <div v-if="i < icons.length - 1"
          class="line"
        ></div>
      </div>
    </div>
    <div v-if="!props.isVisible">
      <p class="status-name">{{ props.basketPlan.getStatusName() }}</p>
      <div style="height: 2px"></div>
      <div v-if="basketPlan.getDatetoShow(props.basketPlan.getStatus())"class="d-flex gap-1 justify-content-center">
        <p class="day-month">{{ FormatUtils.humanLocalizedDate( basketPlan.getDatetoShow(props.basketPlan.getStatus()) as Date) }}</p>
        <p class="date-hour"> {{ moment(basketPlan.getDatetoShow(props.basketPlan.getStatus())).format("HH:mm") }}</p>
      </div>
      <div style="height: 16px"></div>
      <p class="delivery-date" v-if=" basketPlan.getDeliveryDate() && props.basketPlan.getStatus() !== BasketPlanStatus.DELIVERED">
        Previsão de entrega:
        <b>{{ FormatUtils.date(basketPlan.getDeliveryDate(), false) }}</b>
      </p>
      <div style="height: 36px" v-if="!( basketPlan.getDeliveryDate() && props.basketPlan.getStatus() !== BasketPlanStatus.DELIVERED)"></div>
    </div>
  </div>
</template>

<style scoped>
.icons-small {
  width: 16px;
  height: 16px;
  padding: 10px;
}
.icons-medium {
  width: 32px;
  height: 32px;
}
.status-container {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.status-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.line{
  height: 1px;
  width: 21px;
  background-color: var(--option-color-gray-300, #cdc8d7);
}

.status-name {
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: #8300c7;
}

.day-month {
  color: var(--semantic-color-gray-default,var(--option-color-gray-800, #434049));
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.delivery-date {
  color: var(--semantic-color-primary-default, var(--option-color-purple-600, #8300c7));
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 7px;
  background: #f7edff;
  padding: 10px;
  margin: 0;
}

.date-hour {
  color: var(--semantic-color-gray-default, var(--option-color-gray-800, #434049));
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.align-icons {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: var(--semantic-spacing-stack-050, 4px);
  flex-direction: column;
}
.title {
  color: var(--semantic-color-primary-default, #8300c7);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: center;
  gap: var(--semantic-spacing-stack-050, 4px);
}
.style-null-date{
  color: var(--semantic-color-fg-subtle, #AAA4B6);

}
@media screen and (min-width: 100px) and (max-width: 540px) {
  .media-small {
    flex-direction: column;
    display: flex;
    margin: 0 auto;
  }
  .status-icons {
    flex-direction: column;
  }
  .line-small {
    transform: rotateZ(90deg);
    margin: 10px 0 10px 0;
  }
  .line-medium {
    transform: rotateZ(90deg);
    margin: 40px 0 40px 0;
  }
}


@media screen and (max-width: 1110px) {
  .status-icons {
    flex-direction: column;
  }
  .line-small {
    transform: rotateZ(90deg);
    margin: 10px 0 10px 0;
  }
  .line-medium {
    transform: rotateZ(90deg);
    margin: 40px 0 40px 0;
  }

  .line {
    width: 1px;
    height: 21px;
  }
}
</style>