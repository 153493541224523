import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { v4 as uuidv4 } from 'uuid';
import { BasketProductModel } from "../basket_product_model";
import { BasketPlanStatus } from "./basket_plan_status_enum";
import { DeliveryType } from "../delivery_type_enum";
import { Branch } from "../branch_model";
import { AddressModel } from "../address_model";
import FormatUtils from "@/utils/format_utils";
import { Icons } from "@/font-awesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { StatusPlans } from "../status_plans_enum";
import moment from "moment";

@JsonObject()
export class BasketPlan {

  @JsonProperty()
  private id: string;

  @JsonProperty()
  private planName: string;

  @JsonProperty()
  private deliveryAreaId: string;

  @JsonProperty({ type: Date })
  private limitDate: Date | null;

  @JsonProperty({ type: Date })
  private renewDate: Date | null;

  @JsonProperty({ type: Date })
  private deliveryDate: Date | null;
  
  @JsonProperty({ type: Date })
  private usersListUpdatedAt: Date | null;

  @JsonProperty({ type: Date })
  private createdAtUtc: Date;

  @JsonProperty({ type: Date })
  private updatedAtUtc: Date | null;

  @JsonProperty()
  private basketValue: number;

  @JsonProperty()
  private filterAlcoholics: boolean;

  @JsonProperty()
  private autoGenDefaultBasket: boolean;

  @JsonProperty()
  private invoiceType: "SERVICE_INVOICE";

  @JsonProperty()
  private wasPaid: boolean | null;

  @JsonProperty({ type: BasketProductModel, })
  private defaultCart: BasketProductModel[];

  @JsonProperty()
  private pickupBranchId: string | null;

  @JsonProperty()
  private deliveryType: DeliveryType;
  
  private pickupBranch: Branch | null;

  private pickupAddress: AddressModel | null;

  @JsonProperty()
  private usersCount: number | null;

  constructor(params?: {
    id?: string,
    planName: string,
    pickupBranch?: Branch,
    pickupAddress?: AddressModel,
    deliveryAreaId: string,
    limitDate?: Date | null,
    renewDate?: Date | null,
    deliveryDate?: Date | null,
    createdAtUtc: Date,
    updatedAtUtc?: Date | null,
    basketValue: number,
    filterAlcoholics: boolean,
    autoGenDefaultBasket: boolean,
    invoiceType: "SERVICE_INVOICE",
    usersListUpdatedAt?: Date | null
    pickupBranchId?: string | null,
    deliveryType?: DeliveryType,
    wasPaid?: boolean,
    usersCount?: number;
  }) {
    if (!params) return;
    this.id = params.id ?? uuidv4();
    this.planName = params.planName;
    this.pickupBranch = params.pickupBranch ?? null;
    this.pickupAddress = params.pickupAddress ?? null;
    this.deliveryAreaId = params.deliveryAreaId;
    this.limitDate = params.limitDate ?? null;
    this.renewDate = params.renewDate ?? null;
    this.deliveryDate = params.deliveryDate ?? null;
    this.createdAtUtc = params.createdAtUtc;
    this.updatedAtUtc = params.updatedAtUtc ?? null;
    this.basketValue = params.basketValue;
    this.filterAlcoholics = params.filterAlcoholics;
    this.autoGenDefaultBasket = params.autoGenDefaultBasket;
    this.invoiceType = params.invoiceType;
    this.wasPaid = params.wasPaid ?? null;
    this.usersListUpdatedAt = params.usersListUpdatedAt ?? null;
    this.pickupBranchId = params.pickupBranchId ?? null;
    this.deliveryType = params.deliveryType ?? DeliveryType.Entrega_na_empresa;
    this.usersCount = params.usersCount ?? null;
  }

  public getId() {
    return this.id;
  }

  public getMonthString() {
    if (this.getStatusPlan() == StatusPlans.Renovated_for || this.getStatusPlan() == StatusPlans.Renovated_until ) {
      return FormatUtils.getMonthName(this.renewDate?.getMonth())
    } else {
      return '';
    }  
  }

  public getDiferenceDays() {
    const today = new Date();
    const expiredIn = moment(this.renewDate).add(1, 'months').toDate();
    const diffTime = expiredIn.getTime() - today.getTime();
    const diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diff;
}

  public getStatusPlan(){
    if (this.renewDate == null) {
      return StatusPlans.Never_renovated;   
    } 

    if (this.getDiferenceDays() <= 5 && this.isActive() && this.getDiferenceDays() >= 0 ) {
      return StatusPlans.Almost_expiring;
    }

    if (this.renewDate != null && !this.isActive() && this.getDiferenceDays() <= -60  && this.getDiferenceDays() >= -180) {
      return StatusPlans.Not_renovated_for_two_months ;  
    }

    if (this.renewDate != null && !this.isActive() && this.getDiferenceDays() < 0 && this.getDiferenceDays() >= -60 ) {
      return StatusPlans.Not_yet_renovated;    
    }

    if (this.renewDate != null && !this.isActive() && this.getDiferenceDays() <= -180) {
      return StatusPlans.Not_renovated_for_months;  
    }

    if (this.renewDate != null && this.isActive() && this.getStatus() == BasketPlanStatus.DELIVERED ) {
      return StatusPlans.Delivered;  
    }

    if (this.renewDate != null && this.isActive()) {
      return StatusPlans.Renovated_for;  
    }

    else {
      return StatusPlans.Renovated_until; 
    }
  }

  public getTextColor(): string {
    let color: string;
  
    switch (this.getStatusPlan()) {
      case StatusPlans.Renovated_for:
      case StatusPlans.Renovated_until:
        color = 'var(--semantic-color-success-default, #067E36)';
        break;
      case StatusPlans.Almost_expiring:
        color = 'var(--semantic-color-warning-default, #A18100)';
        break;
      case StatusPlans.Not_yet_renovated:  
      case StatusPlans.Not_renovated_for_two_months :
        color = 'var(--semantic-color-error-default, #C63D3D)';
        break;
      case StatusPlans.Not_renovated_for_months:
      case StatusPlans.Never_renovated:  
        color = 'var(--semantic-color-neutral-default, #8C8799)';
        break;
      default:
        color = 'var(--semantic-color-primary-default, #8300C7)'; 
    }  
    return color;
  }

  public getBackGroundColor(): string {
    let color: string;
    switch (this.getStatusPlan()) {
      case StatusPlans.Renovated_for:
      case StatusPlans.Renovated_until:
        color = 'var(--semantic-color-success-subtle, #EBF7F0)';
        break;
      case StatusPlans.Almost_expiring:
        color = 'var(--options-color-yellow-lighter, #FAF7ED)';
        break;
      case StatusPlans.Not_yet_renovated:  
      case StatusPlans.Not_renovated_for_two_months :
        color = 'var(--semantic-color-error-subtle, #FCF0F0)';
        break;
      case StatusPlans.Not_renovated_for_months:
      case StatusPlans.Never_renovated:  
        color = 'var(--semantic-color-neutral-subtle, #F2F1F3)';
        break;
      default:
        color = 'var(--semantic-color-primary-subtle, #F7EDFF)';
    }
    return color;
  }

  public getStatusIcon() {
    if (this.getStatusPlan() == StatusPlans.Renovated_until || this.getStatusPlan() == StatusPlans.Renovated_for) {
      return Icons.imported.faCircleCheck;
    } else {
      return Icons.imported.faCircleExclamation;
    }
  }

  public getName() {
    return this.planName;
  }

  public getUsersCount() {
    return this.usersCount;
  }

  public getValue() {
    return this.basketValue;
  }

  public getDefaultCart() {
    return this.defaultCart;
  }

  public getUsersListUpdatedAt(){
    return this.usersListUpdatedAt;
  }

  public isActive() {
    if (!this.renewDate || !this.deliveryDate || !this.limitDate) return false;
    if (!this.hasPassedDeliveryDate()) return true;
    const currentDate = new Date();
    const time = this.renewDate?.getTime() ?? 0;
    const differenceInMillis = currentDate.getTime() - time;
    if (differenceInMillis < 0) return true;
    const differenceInMonths = differenceInMillis / this.getMillisecondsInMonth();
    return differenceInMonths < 1;
  }

  public getActiveString() {
    const expiredIn = FormatUtils.getMonthName(new Date((this.getRenewalDate()?.getTime() ?? 0) + this.getMillisecondsInMonth()).getMonth());
    const renewedFor = FormatUtils.getMonthName(this.getDeliveryDate()?.getMonth());
    return this.isActive() ? `Renovado para ${renewedFor}` : `Expirado em ${expiredIn}`;
  }

  public isFilteringAlcoholics() {
    return this.filterAlcoholics;
  }

  public getLimitDate() {
    return this.limitDate;
  }

  public getRenewalDate() {
    return this.renewDate;
  }

  public getDeliveryDate() {
    return this.deliveryDate;
  }

  public getCreatedAtUtc() {
    return this.createdAtUtc;
  }

  public getUpdaatedAtUtc() {
    return this.updatedAtUtc;
  }

  public getWasPaid() {
    return this.wasPaid;
  }

  public getDeliveryAreaId() {
    return this.deliveryAreaId;
  }

  public hasPassedDeliveryDate() {
    return (this.deliveryDate ?? new Date(0)) < new Date();
  }

  public hasPassedLimitDate() {
    return (this.limitDate ?? new Date(0)) < new Date();
  }

  public getColor(currentIndex: number) {
    if (currentIndex < this.getStatus()) return "#434049";
    if (currentIndex > this.getStatus()) return "#CDC8D7";
    return 'var(--semantic-color-primary-default, var(--option-color-purple-600, #8300C7))';
  }

  public getDatetoShow(status: BasketPlanStatus) {
    switch (status) {
      case BasketPlanStatus.OPEN:
        return this.getRenewalDate();
      case BasketPlanStatus.CONFIRMED:
        return this.getDeliveryDate();
      case BasketPlanStatus.DELIVERED:
        return this.getDeliveryDate();
    }
  }

  public getIcon(type: BasketPlanStatus): IconDefinition {
    switch (type) {
      case BasketPlanStatus.OPEN:
        return Icons.imported.faBoxOpen;
      case BasketPlanStatus.CONFIRMED:
        return Icons.imported.faFileCircleCheck;
      case BasketPlanStatus.DELIVERED:
        return Icons.imported.faHouseCircleCheck;
    }
  }
  public getTitle(type: BasketPlanStatus): string {
    switch (type) {
      case BasketPlanStatus.OPEN:
        return "Abertura da personalização";
      case BasketPlanStatus.CONFIRMED:
        return "Confirmação da cesta";
      case BasketPlanStatus.DELIVERED:
        return "Entrega";
    }
  }
  public getStatus(): BasketPlanStatus {
    if (this.wasPaid && !this.hasPassedLimitDate()) return BasketPlanStatus.OPEN;
    if (!this.wasPaid && this.hasPassedDeliveryDate()) return BasketPlanStatus.DELIVERED;
    return BasketPlanStatus.CONFIRMED;
  }

  public getStatusName() {
    switch (this.getStatus()) {
      case BasketPlanStatus.OPEN:
        return "Aberto";
      case BasketPlanStatus.CONFIRMED:
        return "Confirmado";
      default:
        return "Entregue";
    }
  }

  public getDeliveryType() {
    return this.deliveryType;
  }

  public getPickupBranchId() {
    return this.pickupBranchId;
  }

  public getPickupBranch() {
    return this.pickupBranch;
  }
  
  public getPickupAddress() {
    return this.pickupAddress;
  }

  public setPickupBranch(branch: Branch | null) {
    this.pickupBranch = branch;
  }

  public setPickupAddress(address: AddressModel | null) {
    this.pickupAddress = address;
  }

  public getMillisecondsInMonth() {
    const days = 31;
    return 1000 * 60 * 60 * 24 * days;
  }
}