import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5b761f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tag-content",
    style: _normalizeStyle({ background: $setup.getColor().background, color: $setup.getColor().color, padding: $setup.getSize() })
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.icon)
        ? (_openBlock(), _createBlock($setup["FontAwesomeIcon"], {
            key: 0,
            icon: $props.icon,
            class: "icon"
          }, null, 8 /* PROPS */, ["icon"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", null, _toDisplayString($props.iconTitle), 1 /* TEXT */)
    ]),
    _createElementVNode("p", null, _toDisplayString($props.message), 1 /* TEXT */)
  ], 4 /* STYLE */))
}