class Constants {
  static readonly project = "foodpass-413017";
  static readonly stage = (window.localStorage.getItem("STAGE") as "prod" | "dev") ?? 'dev';
  
  static readonly childCompanyId = Constants.stage === "dev" ? "cda55fa1-e454-4b79-9223-ee9ebda13847" : "9a0b883f-c8a5-4254-b1c3-336851513762";
  static suportEmail = "contato@foodpass.me"
  static foodPassSupportNumber = "+55 (11) 93484-1553";
  static readonly analyticsTag = "G-D4W5JQRYZ8";

  static getBffEndpoint(): string {
    switch (Constants.stage) {
      case "prod":
        return "https://tf-bff-foodpass-backoffice-5jaenfzqfq-rj.a.run.app";
      default:
        return "https://tf-bff-foodpass-backoffice-be3gha3ygq-rj.a.run.app";
    }
  }

} export default Constants;