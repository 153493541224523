<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps<{
  count: number,
  isReverse?: boolean,
  hasSize?: number,
  color?: 'default' | 'pink',
  hasStroke?: boolean,
}>();

function getColor() {
  switch (props.color) {
    case 'pink': return 'var(--option-color-pink-dark, #E02867)';
    default: return 'var(--options-color-red-base, #F64664)';
  }
}

</script>

<template>
  <div class="counter" 
    :style="{
      background: isReverse ? `var(--options-color-neutral-white, #FFF)` : getColor(),
      color: isReverse ? getColor() : `var(--options-color-neutral-white, #FFF)`,
    }">
    <p :style="{
      color: isReverse ? getColor() : `var(--options-color-neutral-white, #FFF)`,
      fontSize: hasSize ? `${hasSize}px` : `12px`,
      fontWeight: hasStroke ? 800 : 600,
    }">
      {{ count }}
    </p>
    <FontAwesomeIcon class="icon" 
      :style="{
        color: isReverse ? getColor() : `var(--options-color-neutral-white, #FFF)`,
        width: hasSize ? `${hasSize-4}px` : `var(--semantic-size-icons-100, 8px)`,
        height: hasSize ? `${hasSize-4}px` : `var(--semantic-size-icons-100, 8px)`,
      }"
      :icon="Icons.imported.faUser" />
  </div>
</template>

<style scoped>

.counter {
  display: flex;
  padding: 2px 5px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: var(--semantic-spacing-inline-025, 2px);
  border-radius: var(--semantic-border-radius-small, 4px);
}

.counter p {
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  line-height: normal;
}

.counter .icon {
  display: flex;
  padding: 0px 0.5px;
  justify-content: center;
  align-items: center;
}

</style>