<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ref, defineExpose } from 'vue';
import CommonModal from './CommonModal.vue';
import CommonButton from '../CommonButton.vue';

const titleElem = ref<HTMLHeadElement>();
const contentElem = ref<HTMLParagraphElement>();
const buttons = ref<{
  text: string; 
  action: () => void;
  cta?: 'cta' | 'secondary' | 'error';
  type?: 'filled' | 'outlined';
  size?: 'small' | 'medium' | 'large';
}[]>([]);
const icon = ref<IconDefinition | null>(null);
const titleColor = ref<string>();

const alertModal = ref<InstanceType<typeof CommonModal>>();
const isFullScreen = ref<boolean>(false);
const canClose = ref<boolean>(true);
const size = ref<'small' | 'medium'>('small');

defineExpose({
  open: async (params: {
    title: string,
    content: string,
    buttons?: {
      text: string;
      action: () => void;
      cta?: 'cta' | 'secondary' | 'error';
      type?: 'filled' | 'outlined';
      size?: 'small' | 'medium' | 'large';
    }[],
    icon?: IconDefinition,
    titleColor?: "cta" | "success" | "error",
    zIndex?: number,
    isFullScreen?: boolean,
    canClose?: boolean
    size?: 'small' | 'medium'
  }) => {
    titleElem.value!.innerText = params.title ?? '';
    contentElem.value!.innerHTML = params.content ?? '';
    buttons.value = params.buttons ?? [
      {
        text: 'Ok',
        action: () => {
          alertModal.value?.close();
        },
      },
    ];
    icon.value = params.icon ?? null;
    titleColor.value = params.titleColor ?? 'cta';
    isFullScreen.value = params.isFullScreen ?? false;
    canClose.value = params.canClose ?? true;
    size.value = params.size ?? 'small';
    return await alertModal.value?.open({ zIndex: params.zIndex ?? 1000 });
  },
  close: async () => {
    return alertModal.value?.close();
  },
});

function getTitleColor() {
  switch (titleColor.value) {
    case 'success':
      return 'var(--components-modal-color-success-title-color, #31812A)';
    case 'error':
      return 'var(--components-modal-color-error-title-color, #C63D3D)';
    default:
      return 'var(--components-modal-color-default-title-color, #434049)';
  }
}
</script>

<template>
  <CommonModal ref="alertModal" :can-close="canClose" :is-full-screen="isFullScreen">
    <div :class="size === 'small' ? ' alert-modal-small' : ' alert-modal-medium'">
      <div class="d-flex flex-column gap-3">
        <div :class="`d-flex align-items-center ${size === 'small' ? 'gap-3' : 'gap-2'}`">
          <FontAwesomeIcon v-if="icon" :icon="icon" class="alert-icon" :style="{color: getTitleColor()}"></FontAwesomeIcon>
          <h3 ref="titleElem" class="title" :style="{color: getTitleColor()}"></h3>
        </div>
        <p ref="contentElem" class="content-paragraph"></p>
        <slot></slot>
      </div>
      <div class="buttons">
        <CommonButton
          v-if="buttons[0] != undefined"
          :action="buttons[0]?.cta ?? 'cta'"
          :size="buttons[0]?.size ?? 'medium'"
          :type="buttons[0]?.type ?? 'filled'"
          @click="buttons[0]?.action"
          :text="buttons[0]?.text"
          :class="size === 'small' ? 'w-100' : ''"
        ></CommonButton>
        <CommonButton
          v-if="buttons[1] != undefined"
          :action="buttons[1]?.cta ?? 'cta'"
          :size="buttons[1]?.size ?? 'medium'"
          :type="buttons[1]?.type ?? 'filled'"
          @click="buttons[1]?.action"
          :text="buttons[1]?.text"
          :class="size === 'small' ? 'w-100' : ''"
        ></CommonButton>
      </div>
    </div>
  </CommonModal>
</template>

<style scoped>

.alert-icon {
  width: 24px;
  height: 24px;
}

.alert-modal-small {
  padding: 8px;
  max-width: 75vw;
  width: 635px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 16px;
  gap: var(--semantic-spacing-stack-400, 32px);
}

.alert-modal-medium {
  display: flex;
  max-width: 635px;
  width: 635px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--semantic-spacing-stack-600, 48px);
}

.title {
  color: var(--semantic-color-fg-emphasis, #434049);
  font-size: 21px;
  font-weight: 800;
}

.content-paragraph {
  color: var(--semantic-color-fg-default, #5D5867);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

.content-paragraph b {
  font-weight: 800 ;
}

.buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

</style>