<script setup lang="ts">
import { ref, defineExpose, inject, Ref } from 'vue';
import CommonModal from './CommonModal.vue';
import { AlertModalInjection, LoadingModalInjection } from '@/infra/constants/injections';
import LoadingModal from './LoadingModal.vue';
import AlertModal from './AlertModal.vue';
import { BasketPlansBFFBackofficeModule } from '@/infra/bff_backoffice/basket_plans/basket_plans_backoffice_module';
import LoggedAdminStorage from '@/infra/local_storage/logged_admin_storage';
import CommonInput from '../CommonInput.vue';
import Datepicker from '@vuepic/vue-datepicker';
import CommonButton from '../CommonButton.vue';
import { ValueResult } from '@/models/value-result';
import { AccountBalanceUpdate } from '@/models/users/account_balance_update';
import DeliverySelector from '../custom_selector/DeliverySelector.vue';
import { BranchAddresses } from '@/models/branch_addresses_model';
import FormatUtils from '@/utils/format_utils';
import { DeliveryType } from '@/models/delivery_type_enum';

const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection)
const renewBasketPlanModal = ref<InstanceType<typeof CommonModal>>();

const basketPlanId = ref<string>();
const deliverySelector = ref<InstanceType<typeof DeliverySelector>>();

const zIndex = ref<number | undefined>();
defineExpose({
  open: async (params: {
    basketPlanId: string,
    zIndex?: number,
    branchAddresses: BranchAddresses | null,
  }) => {
    zIndex.value = params.zIndex;
    branchAddresses.value = params.branchAddresses;
    const promise = renewBasketPlanModal.value?.open({
      zIndex: params.zIndex,
    });
    basketPlanId.value = params.basketPlanId;
    const res = (await promise) as ValueResult<AccountBalanceUpdate[] | null> | undefined;
    basketPlanId.value = '';
    basketName.value = '';
    basketValue.value = '';
    invoiceId.value = '';
    newDeliveryDate.value = null;
    newLimitDate.value = null;
    clearAll();
    return res;
  },
  close: () => {
    renewBasketPlanModal.value?.close();
  },
});

const branchAddresses = ref<BranchAddresses | null>();
const basketName = ref<string>('');
const basketValue = ref<string>('');
const invoiceId = ref<string>('');
const newDeliveryDate = ref<Date | null>();
const newLimitDate = ref<Date | null>();
const deliveryType = ref<DeliveryType | null>(null);
const pickupBranchId = ref<string | null>(null);

function validate(): boolean {
  basketValue.value = FormatUtils.formatNumberText(basketValue.value);
  if (basketName.value?.length < 6) {
    alertModal?.value.open({
      zIndex: zIndex.value ? zIndex.value + 1 : undefined,
      title: 'Erro',
      content: 'O nome da cesta deve ter pelo menos 6 caracteres',
    });
    return false;
  }
  if ((basketValue.value !== '' && isNaN(parseFloat(basketValue.value))) || parseFloat(basketValue.value) <= 0) {
    alertModal?.value.open({
      zIndex: zIndex.value ? zIndex.value + 1 : undefined,
      title: 'Erro',
      content: 'O valor da cesta deve ser um valor numérico maior que 0',
    });
    return false;
  }
  if (!newDeliveryDate.value || !newLimitDate.value) {
    alertModal?.value.open({
      zIndex: zIndex.value ? zIndex.value + 1 : undefined,
      title: 'Erro',
      content: 'Data de entrega e data limite devem ser preenchida corretamente.',
    });
    return false;
  }
  if (!pickupBranchId.value && deliveryType.value !== "DOOR_TO_DOOR") {
    alertModal?.value.open({
      zIndex: zIndex.value ? zIndex.value + 1 : undefined,
      title: 'Erro',
      content: "É necessário que o endereço de retirada seja informado para o tipo de entrega 'Retirada'. Caso não tenha criado, crie no painel de sedes.",
    });
    return false;
  }
  return true;
}

async function renewBasketPlan() {
  try {
    loadingModal?.value?.open({
      zIndex: zIndex.value ? zIndex.value + 1 : undefined
    });
    if (!validate()) return;
    const res = await BasketPlansBFFBackofficeModule.renewBasketPlan({
      basketPlanId: basketPlanId.value as string,
      data: {
        basketName: basketName.value,
        value: basketValue.value !== '' ? parseFloat(basketValue.value) : undefined,
        newDeliveryDateUtc: newDeliveryDate.value!,
        newLimitDateUtc: newLimitDate.value!,
        operatorId: LoggedAdminStorage.getUser()?.getId(),
        invoiceId: invoiceId.value !== '' ? invoiceId.value : undefined,
        pickupBranchId: pickupBranchId.value,
        deliveryType: deliveryType.value,
      }
    });
    if (res.isError()) alertModal?.value?.open({
      title: 'Erro',
      content: res.getError() ?? 'Erro desconhecido',
      zIndex: zIndex.value ? zIndex.value + 1 : undefined,
    });
    else {
      clearAll();
      renewBasketPlanModal.value?.close(res);
    }
  } finally {
    loadingModal?.value?.close();
  }
}

const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

function getMaxLimitDate() {
  const deliveryDate = newDeliveryDate.value ?? new Date(864e13);
  return new Date(new Date(deliveryDate).setDate(new Date(deliveryDate).getDate() - 1));
}

function getMinDeliveryDate() {
  const limitDate = newLimitDate.value ?? tomorrow;
  return new Date(new Date(limitDate).setDate(new Date(limitDate).getDate() + 1));
}

function clearAll() {
  deliverySelector.value?.clearSelection();
  deliverySelector.value?.setSelected(0);
}

</script>

<template>
  <CommonModal ref="renewBasketPlanModal" :is-full-screen="true">
    <section class="data">
      <p class="title">Renovar plano de cestas</p>
      <div style="height: 16px;"></div>
      <div class="middle-content">
        <CommonInput label="Nome da cesta" state="default" type="text" :model-value="basketName" @update:model-value="(v) => basketName = v" @tap-trailing="basketName = ''"/>
        <CommonInput label="ID da Nota Fiscal" state="default" type="text" :model-value="invoiceId" @update:model-value="(v) => invoiceId = v" @tap-trailing="invoiceId = ''"/>
        <CommonInput label="Valor" state="default" type="text" :mask="FormatUtils.formatMoney(basketValue)" :model-value="basketValue" @update:model-value="(v) => basketValue = v" @tap-trailing="basketValue = ''"/>
        <DeliverySelector
          ref="deliverySelector"
          :branch-addresses="branchAddresses"
          @update:delivery-type="deliveryType = $event"
          @update:pickup-branch-id="pickupBranchId = $event"
          :z-index="zIndex ? zIndex + 1 : undefined">
        </DeliverySelector>
      </div>
      <div style="height: 24px;"></div>
      <div class="date-info">
        <p>Data limite para personalização</p>
        <Datepicker
          v-model="newLimitDate"
          :enable-time-picker="false"
          :min-date="tomorrow"
          :max-date="getMaxLimitDate()"
          :teleport="true"
          locale="pt-br"
          month-name-format="long"
          :day-names="FormatUtils.getDaysName(true)"
          format="dd/MM/yyyy"
          select-text="Selecionar"
          cancel-text="Cancelar" />
      </div>
      <div style="height: 4px;"></div>
      <div class="date-info">
        <p>Data de entrega</p>
        <Datepicker
          v-model="newDeliveryDate"
          :enable-time-picker="false"
          :min-date="getMinDeliveryDate()"
          label="Nova data de entrega"
          :teleport="true"
          locale="pt-br"
          month-name-format="long"
          :day-names="FormatUtils.getDaysName(true)"
          format="dd/MM/yyyy"
          select-text="Selecionar"
          cancel-text="Cancelar" />
      </div>
      <div style="height: 24px;"></div>
      <footer class="footer">
        <CommonButton
          type="outlined"
          border-radius="small"
          action="secondary"
          @click="renewBasketPlanModal?.close()"
          text="Cancelar"
        ></CommonButton>
        <CommonButton
          type="filled"
          border-radius="small"
          @click="renewBasketPlan()"
          text="Renovar"
        ></CommonButton>
      </footer>
      <div style="height: 4px;"></div>
    </section>
  </CommonModal>
</template>

<style scoped>
.data {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 4px;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
}

.date-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-weight: 700;
}

.middle-content {
  display: flex;
  flex-direction: column;
  gap: var(--semantic-spacing-stack-200, 16px);
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
}

.footer button {
  width: 200px;
}
</style>