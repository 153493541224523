<script setup lang="ts">
</script>

<template>
  <div class="divider"></div>
</template>

<style scoped>

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--semantic-color-neutral-subtle, #F2F1F3);
}

</style>