<script setup lang="ts">
import QrcodeVue from 'qrcode.vue';

defineProps<{
  value: string,
}>();

const backgroundColor = '#FFF';
const foregroundColor = '#5D5867';

</script>

<template>
  <div class="qrcode" :style="{
    backgroundColor,
  }">
    <QrcodeVue
      :size="153"
      render-as="canvas"
      level="L"
      :value="value"
      :foreground="foregroundColor"
    ></QrcodeVue>
  </div>
</template>

<style scoped>

.qrcode {
  padding: 8px;
  border-radius: 7px;
}

</style>