import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "69",
  height: "110",
  viewBox: "0 0 69 110",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M-92.6389 62.0904C-90.9895 68.2337 -88.8658 74.2112 -86.3066 79.9879C-89.4799 74.5027 -91.6614 68.4684 -92.6389 62.0904Z",
      fill: $props.fill
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M-53.4405 -63.7936C-48.652 -68.9084 -42.9664 -73.3137 -36.6083 -76.8208C-42.5775 -72.8603 -48.2055 -68.5062 -53.4405 -63.7936Z",
      fill: $props.fill
    }, null, 8 /* PROPS */, _hoisted_3),
    _createElementVNode("path", {
      d: "M-96.8023 30.7408C-96.8648 -6.20405 -80.2736 -39.6574 -53.4419 -63.7951C-48.207 -68.5076 -42.579 -72.8618 -36.6098 -76.8223C-36.6055 -76.8223 -36.594 -76.8311 -36.5839 -76.8369C-26.1813 -82.5553 -13.9798 -85.8621 -0.921525 -85.8843C37.1827 -85.9473 68.1206 -58.0315 68.179 -23.5348C68.2093 -5.66455 59.9495 10.4664 46.6768 21.8759C45.1536 22.9267 43.7084 24.0766 42.35 25.295C34.1172 32.7002 29.0157 42.9455 28.9887 54.2625L28.989 54.4172C28.9468 84.9038 1.66452 109.636 -32.0278 109.693C-55.608 109.733 -76.0866 97.6774 -86.3066 79.9879C-88.8658 74.2112 -90.9895 68.2337 -92.6389 62.0904C-95.3437 52.0408 -96.784 41.5439 -96.8023 30.7408Z",
      fill: $props.fill
    }, null, 8 /* PROPS */, _hoisted_4)
  ]))
}