import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { CardCategoryType } from "./card_category_type";
import FormatUtils from "@/utils/format_utils";

@JsonObject()
export class BenefitsCardPlanCategory {

  @JsonProperty()
  private benefitsCardPlanId?: string | null;

  @JsonProperty()
  private category: CardCategoryType;

  @JsonProperty()
  private value?: number;

  constructor(params?: {
    benefitsCardPlanId?: string,
    category: CardCategoryType,
    value?: number
  }) {
    if (!params) return;
    this.benefitsCardPlanId = params.benefitsCardPlanId ?? null;
    this.category = params.category;
    this.value = params.value;
  }

  public getBenefitsCardPlanId() {
    return this.benefitsCardPlanId;
  }

  public getCategory() {
    return this.category;
  }

  public getCustomCategory() {
   if (this.category === CardCategoryType.MULTI) return 'Flex';
   return this.category === CardCategoryType.AM ? 'Mobilidade' : this.category;
  }

  public getValue() {
    return this.value ?? 0;
  }  
}

  