<script setup lang="ts">
import { ref } from 'vue';
import CommonModal from './CommonModal.vue';
import CommonButton from '../CommonButton.vue';

const welcomeModal = ref<InstanceType<typeof CommonModal>>();

defineExpose({
  open: (params?: {
    zIndex?: number
  }) => {
    welcomeModal.value?.open({
      zIndex: params?.zIndex ?? 10000,
    });
  },
  close: () => {
    welcomeModal.value?.close();
  }
})

</script>

<template>
  <CommonModal ref="welcomeModal" :is-full-screen="false" :can-close="true">
    <section class="welcome-modal">
      <div style="display: flex;">
        <img src="@/assets/image/bean_cheer.svg" alt="">
      </div>
      <div class="main-title">
        <h2 class="title">
          Sua conta foi configurada com sucesso!
        </h2>
        <p class="description"
            ><b>Tudo pronto para começar!</b> 
          Agora você pode aproveitar as vantagens e facilidades da sua conta Foodpass!
        </p>
      </div>
      <div class="btn-action">
        <CommonButton 
          @click="welcomeModal?.close()" 
          text="Começar a usar"
        />
      </div>
    </section>
  </CommonModal>
</template>

<style scoped>

.btn-action {
  display: flex; 
  justify-content: center; 
  align-items: center;
}

.welcome-modal {
  border-radius: var(--semanticsizecomponentsmodalborder-radiusdefault, 7px);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--semantic-spacing-stack-600, 48px);
  text-align: center;
}

.welcome-modal-card {
  text-align: center;
}

.description b {
  color: var(--semantic-color-fg-default, #5D5867);
  font-size: 14px;
  font-weight: 600;
}

.description {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-weight: 300;
  color: var(--semantic-color-fg-default, #5D5867);
}

.title {
  color: var(--components-modal-color-default-title-color, #434049);
  font-size: 21px;
  font-weight: 800;
  line-height: 25px;
}

.main-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--semantic-size-components-modal-spacing-gap-title-slot, 16px);
}

@media screen and (max-width: 1300px) {
  .welcome-modal {
    align-items: center;
  }

  .title {
    font-size: 21px;
  }

  .description {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 18px;
    text-align: center;
  }

  .description {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    width: 300px;
  }
}
</style>
