import { GlobalData } from "@/global";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from 'uuid';

export enum MinimizedType {
  BASKET = 'BASKET',
  BENEFITS_CARD = 'BENEFITS_CARD',
  DEFAULT = 'DEFAULT',
}

export class MinimizedTypeUtils {
  static getColor(type: MinimizedType) {
    switch (type) {
      case MinimizedType.BASKET:
        return 'var(--semantic-color-tertiary-default, #FF508C)';
      case MinimizedType.BENEFITS_CARD:
        return 'var(--semantic-color-primary-default, #8300C7)';
      default:
        return 'gray';
    }
  }
}

export interface IMinimized {
  id?: string;
  type: MinimizedType;
  name: string;
  onMaximize?: () => void;
  onClose?: () => void;
}

export interface IState {
  modals: IMinimized[];
}

export const useMinimizedModalStore = defineStore('minimized_modal', {
  state: (): IState => ({
    modals: [],
  }),
  actions: {
    addMinimized(params: IMinimized): string {
      
      const id = params.id ?? uuidv4();
      this.modals.push({ ...params, id });

      return id;
    },
    removeMinimized(index: number) {
      this.modals.splice(index, 1);
    },
    removeMinimizedById(id: string) {
      const index = this.modals.findIndex((modal) => modal.id === id);
      if (index < 0) return;
      this.removeMinimized(index);
    },
    getAllMinimized() {
      return this.modals;
    },
  },
})(GlobalData.Pinia);