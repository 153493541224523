import { Permission } from '@/models/admins/permission_model';
import { Resource } from '@/models/admins/resources_enum';
import loginGuard from '@/nav_guards/login_guard';
import LoginView from '@/views/LoginView.vue';
import { createRouter, createWebHashHistory } from 'vue-router';

export interface IRoute {
  path: string;
  name: string;
  component: any;
  authType?: AuthRequirement;
  isSuperAdminRoute?: boolean;
  requiredPermissions?: Permission[];
}

export enum AuthRequirement {
  LOGGED = 'logged',
  NOT_LOGGED = 'not-logged',
  DONT_CARE = 'both',
}

export const routes: { [key: string]: IRoute } = {
  login: {
    path: '/',
    name: 'login',
    component: LoginView,
    authType: AuthRequirement.NOT_LOGGED,
  },
  deliveryAreas: {
    path: '/delivery-areas',
    name: 'delivery-areas',
    component: () => import('@/views/delivery_areas/DeliveryAreasView.vue'),
    isSuperAdminRoute: true,
  },
  welcome: {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/welcome/WelcomeView.vue'),
    authType: AuthRequirement.LOGGED,
    requiredPermissions: [Permission.fromResource(Resource.DELIVERY_AREAS, "read")],
  },
  singleBasketPlan: {
    path: '/basket-plans/:id',
    name: 'single-basket-plan',
    component: () => import('@/views/basket_plans/SingleBasketPlanView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.BASKET_PLANS, "read")],
  },
  singleBenefitCardPlan: {
    path: '/single-benefit-card-plan/:id',
    name: 'single-benefit-card-plan',
    component: () => import('@/views/benefits_card_plans/SingleBenefitCardPlanView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.CREDIT_CARD_PLANS, "read")],
  },
  createEditDeliveryArea: {
    path: '/create-delivery-area/:id?',
    name: 'create-delivery-area',
    component: () => import('@/views/delivery_areas/CreateDeliveryAreaView.vue'),
    isSuperAdminRoute: true,
    requiredPermissions: [Permission.fromResource(Resource.DELIVERY_AREAS, "write")],
  },
  deleteUser: {
    path: '/delete-users/deliveryAreas/:deliveryAreaId',
    name: 'delete-users',
    component: () => import('@/views/users/DeleteUsersView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.USERS, "delete")],
  },
  updateCartProducts: {
    path: '/update-products/basketPlans/:basketPlanId',
    name: 'update-products',
    component: () => import('@/views/basket_plans/UpdateCartProductsView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.BASKET_PLANS, "write")],
    isSuperAdminRoute: true,
  },
  createUsersAddresses: {
    path: '/create-users-addresses/deliveryAreas/:deliveryAreaId',
    name: 'create-users-addresses',
    component: () => import('@/views/address/CreateUsersAddressesView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.ADDRESSES, "write")],
  },
  basketPlansList: {
    path: '/basket-plans-list/:deliveryAreaId',
    name: 'basket-plans-list',
    component: () => import('@/views/basket_plans/BasketPlansListView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.BASKET_PLANS, "read")],
  },
  benefitsCardPlansList: {
    path: '/benefits-card-plans-list/:deliveryAreaId',
    name: 'benefits-card-plans-list',
    component: () => import('@/views/benefits_card_plans/BenefitsCardPlansListView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.CREDIT_CARD_PLANS, "read")],
  },
  adminsList: {
    path: '/admin-list/:deliveryAreaId',
    name: 'admin-list',
    component: () => import('@/views/hr_admins/AdminListView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.HR_ADMINS, "read")],
  },
  usersList: {
    path: '/users-list/:deliveryAreaId',
    name: 'users-list',
    component: () => import('@/views/users/UsersListView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.USERS, "read")],
  },
  settings: {
    path: '/settings/:deliveryAreaId',
    name: 'settings',
    component: () => import('@/views/settings/SettingsView.vue'),
  },
  branches: {
    path:  '/settings/branches/:deliveryAreaId',
    name: 'branches',
    component: () => import('@/views/settings/BranchesListView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.BRANCHES, "read")],
  },
  accessAndPermissionsList: {
    path: '/settings/access-and-permissions/:deliveryAreaId',
    name: 'access-and-permissions',
    component: () => import('@/views/settings/AccessAndPermissionsListView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.HR_ADMINS, "read")],
  },
  unauthorized: {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/UnauthorizedView.vue')
  },
  resetPassword: {
    path: '/reset-password',
    name: 'reset-password',
    authType: AuthRequirement.DONT_CARE,
    component: () => import('@/views/two_factor_auth/ResetPasswordView.vue')
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'forgot-password',
    authType: AuthRequirement.DONT_CARE,
    component: () => import('@/views/two_factor_auth/ForgotPasswordView.vue')
  }, 
  singleUser: {
    path: '/single-user/:id',
    name: 'single-user',
    component: () => import('@/views/users/SingleUserView.vue'),
    requiredPermissions: [Permission.fromResource(Resource.USERS, "read")],
  }
};

const router = createRouter({
  history: createWebHashHistory(),
  routes: Object.values(routes),
});

router.beforeEach(async (to, from, next) => {
  const route = await loginGuard(to, from);
  if (!route) return next();
  return next({ path: route });
});

export default router;
