import { Icons } from "@/font-awesome";

export enum CardCategoryType {
  VA = 'VA',
  VR = 'VR',
  MULTI = 'MULTI',
  AM = 'AM'
}

export class CardCategoryUtils {

  public static getIconFromCategory(category: CardCategoryType) {
    switch (category) {
      case CardCategoryType.VA:
        return Icons.imported.faCartShopping;
      case CardCategoryType.VR:
        return Icons.imported.faPizzaSlice;
      case CardCategoryType.MULTI:
        return Icons.imported.faEarthAmericas;
      case CardCategoryType.AM:
        return Icons.imported.faVanShuttle;
    }
  }

  public static getNameFromCategory(category: CardCategoryType) {
    switch (category) {
      case CardCategoryType.VA:
        return 'Alimentação';
      case CardCategoryType.VR:
        return 'Refeição';
      case CardCategoryType.MULTI:
        return 'Flex';
      case CardCategoryType.AM:
        return 'Mobilidade';
    }
  }
}