<script setup lang="ts">
import BenefitsCardPlanCard from '@/components/benefits_card_plans_view/BenefitsCardPlanCard.vue';
import CommonButton from '@/components/CommonButton.vue';
import Divider from '@/components/Divider.vue';
import { BenefitsCardPlan } from '@/models/benefits_cards/benefits_card_plan_model';
import { ReqBenCardsRenewScreens, useReqRenewBenefitsCardPlansStore } from '@/stores/benefits_card_plans/request_renew_benefits_cards_plan_store';
import FormatUtils from '@/utils/format_utils';
import { defineProps, inject, Ref } from 'vue';
import LoadingModalV2 from '../../LoadingModalV2.vue';
import { LoadingModalV2Injection } from '@/infra/constants/injections';
import PaymentMethodCard from '@/components/payment_method/PaymentMethodCard.vue';
import { PaymentMethodTypes } from '@/models/payment_method/payment_method_types_enum';

const reqRenewBenCardsPlanStore = useReqRenewBenefitsCardPlansStore;
const loadingModalV2 = inject<Ref<InstanceType<typeof LoadingModalV2>>>(LoadingModalV2Injection);

defineProps<{
  showScreen: boolean;
}>();

async function renewBenCardsPlan() {
  const promise = reqRenewBenCardsPlanStore.renewBenCardsPlan();
  await loadingModalV2?.value?.open({
    promise,
    loading: {
      text: 'Gerando PIX para renovação...'
    },
    loaded: {
      text: 'PIX gerado com sucesso',
      subtext: 'Redirecionando para a tela de pagamento...'
    },
  });
  const res = await promise;
  if (res.isError()) return;
  reqRenewBenCardsPlanStore.currentScreen = ReqBenCardsRenewScreens.PAYMENT;
}

</script>

<template>
  <div
    class="flex-column"
    :style="{
      display: showScreen ? 'flex' : 'none'
    }"
  >
    <div class="plan-card-container">
      <BenefitsCardPlanCard
        v-if="reqRenewBenCardsPlanStore.benefitsCardsPlan"
        :benefits-card-plan="(reqRenewBenCardsPlanStore.benefitsCardsPlan! as BenefitsCardPlan)"
        :hide-controls="true"
        style="width: fit-content;"
      />

      <div class="renew-information">

        <div class="info">
          <p class="info-total">Total por colaborador</p>
          <div style="height: var(--semantic-size-semantic-spacing-stack-025, 2px)"></div>
          <p class="info-value">R$ {{ FormatUtils.money((reqRenewBenCardsPlanStore.getTotalValuePerUser() ?? 0)) }}</p>
        </div>

        <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px)"></div>

        <div class="info">
          <p class="info-total">Colaboradores no plano</p>
          <div style="height: var(--semantic-size-semantic-spacing-stack-025, 2px)"></div>
          <p class="info-value">{{ reqRenewBenCardsPlanStore.benefitsCardsPlan?.getUserCount() ?? 0 }}</p>
        </div>

        <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px)"></div>
        <Divider />
        <div style="height: var(--semantic-size-semantic-spacing-stack-300, 24px)"></div>

        <div class="info">
          <p class="info-total">Total</p>
          <div style="height: var(--semantic-size-semantic-spacing-stack-025, 2px)"></div>
          <p class="info-value bigger">R$ {{ FormatUtils.money(reqRenewBenCardsPlanStore.benefitsCardsPlan?.getTotalPlanValue() ?? 0) }}</p>
        </div>

      </div>
    </div>
    <div style="height: var(--semantic-size-semantic-spacing-stack-600, 48px)"></div>

    <div class="payment-method">
      <div class="d-flex">
        <PaymentMethodCard :payment-methods="[{ type: PaymentMethodTypes.PIX }]" />
      </div>
    </div>

    <div style="height: 48px"></div>
    <div class="d-flex justify-content-between">
      <CommonButton @click="reqRenewBenCardsPlanStore.currentScreen = ReqBenCardsRenewScreens.INITIAL" type="outlined" text="Voltar" action="secondary"></CommonButton>
      <CommonButton text="Confirmar renovação" @click="renewBenCardsPlan"></CommonButton>
    </div>
  </div>
</template>

<style scoped>

.plan-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: end;
  gap: var(--semantic-size-semantic-spacing-inline-800, 64px);
}

.info-total {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  text-align: right;
}

.info-value {
  color: var(--semantic-color-tertiary-default, #FF508C);
  font-family: 'Montserrat';
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  text-align: right;
}

.info-value.bigger {
  font-size: 27px;
}

.payment-method-title {
  color: var(--semantic-color-fg-default, #5D5867);
  text-align: left;

  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.payment-method-card {
  display: flex;
  padding: var(--semantic-size-semantic-spacing-inset-squishy-xlarge-vertical, 48px) var(--semantic-size-semantic-spacing-inset-squishy-xlarge-horizontal, 64px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--semantic-size-semantic-spacing-stack-200, 16px);
  border-radius: 16px;
  border: 1px solid var(--semantic-color-neutral-border-subtle, #F2F1F3);
  background: #FFF;

  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 580px) {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .renew-information {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .plan-card-container {
    justify-content: center;
  }
}

</style>