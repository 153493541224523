import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { BenefitsCardPlanCategory } from "./benefits_card_plan_category_model";

@JsonObject()
export class UpdateCardPlan {

  @JsonProperty()
  planName?: string;

  @JsonProperty({ type: BenefitsCardPlanCategory })
  categories?: BenefitsCardPlanCategory[];
  
  @JsonProperty()
  branchId?: string;

  constructor(params: {
    planName?: string;
    categories?: BenefitsCardPlanCategory[];
    branchId?: string;
  }) {
    if (!params) return;
    this.planName = params.planName;
    this.categories = params.categories;
    this.branchId = params.branchId;
  }
}