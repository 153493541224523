import { defineStore } from 'pinia';
import { BenefitsCardPlan } from '@/models/benefits_cards/benefits_card_plan_model';
import { CardCategoryType } from '@/models/benefits_cards/card_category_type';
import { CreditCardPlansBFFBackofficeModule } from '@/infra/bff_backoffice/credit_card_plans/credit_card_plans_backoffice_module';
import { BenefitsCardPlanCategory } from '@/models/benefits_cards/benefits_card_plan_category_model';
import { UpdateCardPlan } from '@/models/benefits_cards/update_card_plan_model';
import { RequestRenew } from '@/models/benefits_cards/request_renew_model';
import { ValueResult } from '@/models/value-result';
import { RenewalCardPlan } from '@/models/benefits_cards/renewal_card_plan_model';
import FormatUtils from '@/utils/format_utils';
import { GlobalData } from '@/global';

export enum ReqBenCardsRenewScreens {
  INITIAL = 'initial',
  VALUE_CONFIRMATION = 'value_confirmation',
  PAYMENT = 'payment',
}

interface ICategoryParam {
  isActive: boolean;
  value: string | null;
  error: string | null;
}

interface IState {
  benefitsCardsPlan: BenefitsCardPlan | null;
  currentScreen: ReqBenCardsRenewScreens;
  categories: Map<CardCategoryType, ICategoryParam | null>;
  invoice: RenewalCardPlan | null,
}

export const useReqRenewBenefitsCardPlansStore = defineStore('request_renew_benefits_card_plans_store', {
  state: (): IState => ({
    benefitsCardsPlan: null,
    currentScreen: ReqBenCardsRenewScreens.INITIAL,
    categories: new Map<CardCategoryType, ICategoryParam>(),
    invoice: null,
  }),
  actions: {
    setBenefitPlan(benefitsCardsPlan: BenefitsCardPlan) {
      this.benefitsCardsPlan = benefitsCardsPlan;
      this.categories = new Map<CardCategoryType, ICategoryParam>();
      this.benefitsCardsPlan?.getCategories().forEach((category) => {
        if (category.getValue() <= 0) return;
        this.categories.set(category.getCategory(), {
          isActive: true,
          value: FormatUtils.replaceDecimalSeparator(category.getValue().toString()),
          error: null,
        });
      });
    },
    getTotalValuePerUser(): number {
      const valuesStr = Array.from(this.categories.values());
      const valuesNumber = valuesStr.filter(e => e?.isActive && parseFloat(e?.value?.replaceAll('R$', '').replace(',', '.').trim() ?? '0') > 0).map(e => {
        const valueText = FormatUtils.formatNumberText(e?.value ?? '0');
        return parseFloat(valueText);
      });
      if (valuesNumber.some(e => isNaN(e))) return 0;
      const total = valuesNumber.reduce((a, b) => a + b, 0);
      return total;
    },
    getBenefitCategory(type: CardCategoryType): string {
      const typedValueStr = this.categories.get(type)?.value;
      return typedValueStr ?? "";
    },
    getBenefitCategoryActive(type: CardCategoryType) {
      return this.categories.get(type)?.isActive ?? false;
    },
    getBenefitCategoryError(type: CardCategoryType) {
      return this.categories.get(type)?.error;
    },
    setBenefitCategory(params: {
      category: CardCategoryType,
      value: string,
      isActive?: boolean,
    }) {
      if (params.value && this.categories.get(params.category)?.value === params.value) return;
      this.categories.set(params.category, {
        value: params.value,
        error: null,
        isActive: params.isActive ?? true,
      });
    },
    validateBenefits(params?: {
      setErrors?: boolean;
    }): boolean {
      let isValid = true;
      for (const [key, value] of this.categories.entries()) {
        if (!value?.isActive) continue;
        if (value?.value === null) continue;
        const currentValue = parseFloat(value?.value.replaceAll('R$', '').replaceAll(',', '.').trim() ?? '');
        if (!isNaN(currentValue ?? 0) && (currentValue ?? 0) > 0) continue;
        if (params?.setErrors) this.categories.set(key, {
          value: value?.value ?? null,
          error: 'Preencha com um valor maior que R$ 0,00 ou desative o benefício.',
          isActive: value?.isActive ?? true,
        });
        isValid = false;
      }
      return isValid && this.getTotalValuePerUser() > 0;
    },
    canRenew(): boolean {
      for (const value of this.categories.values()) {
        if (value?.isActive) return true;
      }
      return false;
    },
    async updateBenefitsPlan(): Promise<ValueResult<BenefitsCardPlan | null>> {
      if (!this.validateBenefits({ setErrors: true })) return new ValueResult({ error: 'Valores inválidos.' });
      const categories = Array.from(this.categories.entries())
        .filter(([_, value]) => value?.isActive)
        .map((data) => {
          const [key, value] = data;
          return new BenefitsCardPlanCategory({
            benefitsCardPlanId: this.benefitsCardsPlan?.getId(),
            category: key,
            value: parseFloat(FormatUtils.formatNumberText(value?.value ?? '0')),
          })
        }
      );
      if (this.benefitsCardsPlan?.compare(categories)) return new ValueResult();
      const res = await CreditCardPlansBFFBackofficeModule.patchBenefitsCardPlan({
        id: this.benefitsCardsPlan?.getId() ?? '',
        data: new UpdateCardPlan({
          categories,
        }),
      });
      res.getValue()?.setUserCount(this.benefitsCardsPlan?.getUserCount() ?? 0);
      this.benefitsCardsPlan = res?.getValue() ?? this.benefitsCardsPlan;
      return res;
    },
    async renewBenCardsPlan() {
      const invoice = await CreditCardPlansBFFBackofficeModule.renew(new RequestRenew({
        cardPlanIds: [this.benefitsCardsPlan?.getId() ?? ''],
        expectedValue: this.getTotalValuePerUser() * (this.benefitsCardsPlan?.getUserCount() ?? 0),
        calculate: false,
      }));
      this.invoice = invoice.getValue();
      return invoice;
    },
  }
})(GlobalData.Pinia);