import { ValueResult } from "@/models/value-result";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";
import { JsonSerializer } from "typescript-json-serializer";
import { DeliveryAreaModel } from "@/models/delivery_area_model";
import { PaginatedList } from "@/models/paginated_list";

export class DeliveryAreasBFFBackofficeModule {

  static readonly controller = "delivery-areas";
  static readonly serializer = new JsonSerializer();

  static async getDeliveryAreasByCompanyId(params: {
    companyId: string;
    page: number;
    pageSize: number;
    searchTerm?: string;
  }): Promise<ValueResult<PaginatedList<DeliveryAreaModel | null>>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}/company/${params.companyId}`, {
        params: {
          page: params.page,
          pageSize: params.pageSize,
          searchTerm: params.searchTerm ?? undefined
        }
      });
      const deliveryAreas = this.serializer.deserializeObjectArray(res.data.items, DeliveryAreaModel) as DeliveryAreaModel[];
      const resInfo =  new ValueResult({
        value: new PaginatedList<DeliveryAreaModel | null>({
          items: deliveryAreas,
          pageCount: res.data.pageCount,
        })
      });
      return resInfo;
    } catch(e: any) {
      return new ValueResult({
        error: "Erro ao buscar areas de entrega!",
      });
    }
  }

  static async getDeliveryAreaById(params: {
    id: string;
  }): Promise<ValueResult<DeliveryAreaModel | null>> {
    try {
      const res = await BffFoodPassBackofficeApiRequester.getAxios().get(`/${this.controller}/${params.id}`);
      const deliveryArea = this.serializer.deserializeObject(res.data, DeliveryAreaModel) as DeliveryAreaModel;
      return new ValueResult({
        value: deliveryArea,
      });
    } catch(e: any) {
      return new ValueResult({
        error: "Erro ao buscar area de entrega!",
      });
    }
  }

  static async createDeliveryArea(params: {
    childCompanyId: string;
    deliveryArea: DeliveryAreaModel,
  }): Promise<ValueResult<DeliveryAreaModel | null>> {
    try {
      const json: any = this.serializer.serialize(params.deliveryArea);
      json.address.streetName = params.deliveryArea.getAddress()?.getStreet();
      json.address.uf = params.deliveryArea.getAddress()?.getState();
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/company/${params.childCompanyId}`, json);
      const newDeliveryArea = this.serializer.deserializeObject(res.data, DeliveryAreaModel) as DeliveryAreaModel;
      return new ValueResult({
        value: newDeliveryArea
      });
    } catch(e: any) {
      return new ValueResult({
        error: "Erro ao criar area de entrega!",
      });
    }
  }

}