import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AlertModal"], { ref: "alertModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["AlertModalV2"], { ref: "alertModalV2" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["LoadingModal"], { ref: "loadingModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["LoadingModalV2"], { ref: "loadingModalV2" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["CommonToast"], { ref: "commonToast" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["TwoFactorAuthModal"], { ref: "twoFactorAuthModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["RequestRenewBasketPlanModal"], { ref: "requestRenewBasketPlanModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["RequestRenewBenefitsCardsPlan"], { ref: "requestRenewBenefitsCardsPlan" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["MinimizedModal"]),
    _createVNode($setup["WelcomeModal"], { ref: "welcomeModal" }, null, 512 /* NEED_PATCH */),
    _createCommentVNode(" <LinkUsersModal ref=\"linkUsersModal\"/> "),
    _createVNode($setup["RouterView"])
  ], 64 /* STABLE_FRAGMENT */))
}