<script setup lang="ts">
import { useMinimizedModalStore } from '@/stores/minimized_modal_store/minimized_modal_store';
import MinimizedModalCard from './MinimizedModalCard.vue';

const minimizedModalStore = useMinimizedModalStore;

</script>

<template>
  <div class="minimized-modal">
    <MinimizedModalCard
      v-for="card in minimizedModalStore.modals"
      :key="card.id"
      :minimized="card"
    ></MinimizedModalCard>
  </div>
</template>

<style scoped>

.minimized-modal {
  position: fixed;
  width: 100svw;
  height: 100svh;
  z-index: 30;
  gap: 8px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;

  padding: 12.5px 32.5px;

  pointer-events: none;
}

</style>