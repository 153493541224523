<script setup lang="ts">
import CommonButton from '@/components/CommonButton.vue';
import { inject, onMounted, Ref, ref } from 'vue';
import CommonInput from '../CommonInput.vue';
import { AdminUser } from '@/models/admins/admin_model';
import { useEditHrAdmin } from '@/stores/users/edit_hr_admin_store';
import AlertModal from '@/components/modals/AlertModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import { AlertModalInjection, AlertModalInjectionV2, LoadingModalInjection } from '@/infra/constants/injections';
import AlertModalV2 from '../modals/AlertModalV2.vue';
import { Icons } from '@/font-awesome';

const props = defineProps<{
  currentPage: number;
  adminUser: AdminUser;
  qrCodeUrl: string;
  secret: string;
}>();

let editHrAdmin = useEditHrAdmin();
const alertModalV2 = inject<Ref<InstanceType<typeof AlertModalV2>>>(AlertModalInjectionV2);
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const fixedIcon = Icons.imported.faCircleExclamation;
const zIndexRef = ref<number>(2);



// async function setUserData() {
//   editHrAdmin.firstName = props.adminUser.getFirstName() ?? '';
//   editHrAdmin.lastName = props.adminUser.getLastName() ?? '';
//   editHrAdmin.phone = props.adminUser.getPhone() ?? '';
// }

async function saveParsonalInfo() {
  try {
    loadingModal?.value?.open();
    const res = await editHrAdmin.submit();
    if(!res) return;
    if (res?.isError()) alertModalV2?.value?.open({
        zIndex: 10000,
        title: 'Informação Incorreta',
        size: 'medium',
        icon: fixedIcon,
        modalType: 'error',
        content: res.getError() ?? 'Erro desconhecido'
      });
    else {
      editHrAdmin.$reset();
      emit('change-page', props.currentPage + 1);
    }
  }
  finally {
    loadingModal?.value?.close();
  }
}

// onMounted(() => {
//   setUserData();
// })

const emit = defineEmits<{
  (e: 'change-page', page: number): void
  (e: 'on-submit'): void
}>();

</script>

<template>
  <div class="welcome-two-factor-content">
    <img src="@/assets/icons/personal_data.svg" alt="Dados Pessoais"/>
    <div class="d-flex flex-column gap-2">
      <div style="height: var(--semantic-spacing-stack-300, 24px);"></div>
      <h1 class="title">Dados Pessoais</h1>
      <div>
        <p class="sub-title"><b>Preencha os campos</b> abaixo com seus dados</p>
      </div>
    </div>
    <form class="form">
      <div class="form-section">
        <!-- <CommonInput
          label="Nome Social ou apelido"
          supportingText="Nome pelo qual você gostaria que te chamássemos."
          class="w-100"
          :model-value="editHrAdmin.nickName ?? ''"
          @update:model-value="(v) => editHrAdmin.nickName = v"
          @tap-trailing="editHrAdmin.nickName = ''"
        /> -->
        <CommonInput
          :state="editHrAdmin.firstNameError ? 'error' : 'default'"
          label="Primeiro nome"
          class="w-100"
          :supportingText="editHrAdmin.firstNameError ?? undefined"
          :model-value="editHrAdmin.firstName ?? ''"
          @update:model-value="editHrAdmin.firstName = $event; editHrAdmin.firstNameError = null;"
          @tap-trailing="editHrAdmin.firstName = ''"
        />
        <CommonInput
        :state="editHrAdmin.lastNameError ? 'error' : 'default'"
          label="Sobrenome"
          class="w-100"
          :supportingText="editHrAdmin.lastNameError ?? undefined"
          :model-value="editHrAdmin.lastName ?? ''"
          @update:model-value="editHrAdmin.lastName = $event; editHrAdmin.lastNameError = null;"
          @tap-trailing="editHrAdmin.lastName = ''"
        />
        <!-- <CommonInput
          label="CPF" 
          :mask="'###.###.###-##'" 
          class="w-100" 
          :supportingText="editHrAdmin.cpfError ?? undefined"
          :model-value="editHrAdmin.cpf ?? ''"
          @update:model-value="(v) => editHrAdmin.cpf = v" 
          @tap-trailing="editHrAdmin.cpf = ''" 
        /> -->
        <CommonInput
          :state="editHrAdmin.phoneError ? 'error' : 'default'"
          label="Celular"
          :mask="'(##) #####-####'"
          class="w-100"
          :supportingText="editHrAdmin.phoneError ?? undefined"
          :model-value="editHrAdmin.phone ?? ''"
          @update:model-value="editHrAdmin.phone = $event; editHrAdmin.phoneError = null;"
          @tap-trailing="editHrAdmin.phone = ''"
        />
        <!-- <CommonInput
          label="Data de Nascimento"
          :mask="'##/##/####'"
          class="w-100"
          :supportingText="editHrAdmin.birthDateError ?? undefined"
          :model-value="editHrAdmin.birthDate ?? ''"
          @update:model-value="(v) => editHrAdmin.birthDate = v"
          @tap-trailing="editHrAdmin.birthDate = ''"
        /> -->
      </div>
      <div class="button-dev">
        <CommonButton class="w-100" style="max-width: 360px;" @click="saveParsonalInfo()" text="Confirmar dados">
        </CommonButton>
      </div>
      <!-- <div style="padding-top:6px"></div> -->
    </form>
  </div>
</template>

<style lang="scss" scoped>
.welcome-two-factor-content {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 360px;
}

.title {
  color: var(--components-modal-color-default-title-color, #434049);
  text-align: center;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.sub-title {
  color: var(--semantic-color-fg-muted, #6E6979);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 10%;
}

.sub-title b {
  font-weight: 600;
}


@media screen and (max-width: 395px) {

  .sub-title {
    font-size: 12px;
  }

}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.form-section {
  display: contents;
  gap: 8px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
}

.footer button {
  width: 200px;
}

.common-input {
  border-radius: var(--semantic-size-semantic-border-radius-default, 7px);
  border: 1px solid var(--components-input-default-border-color, #CDC8D7);
  background: var(--components-input-default-background-color, rgba(0, 0, 0, 0.00));
  padding: 16px;
  font-size: 16px;
  padding-left: 24px;
  width: 100%;
  position: relative;
  height: 49px;
  opacity: 0px;
}

.input {
  position: relative;
}

.input-after,
.input-before {
  position: absolute;
  top: 0;
  height: .1px;
}

.input-before {
  left: calc(var(--semantic-border-radius-default, 7px) / 2);
  width: 10px;
}

.input-after {
  right: calc(var(--semantic-border-radius-default, 7px) / 2);
}

.input-field-icons-container {
  position: absolute;
  display: flex;
  gap: 4px;
  align-items: center;
  bottom: 17px;
  right: 20px;
}

.input-field-icon {
  cursor: pointer;
  width: 20px;
}

.input-field-label {
  position: absolute;
  left: 21px;
  top: 50%;
  line-height: 16px;
  transition: all 0.1s;
  pointer-events: none;
  display: flex;
  padding: 0px var(--semantic-spacing-stack-025, 2px);
  gap: var(--semantic-spacing-inline-100, 8px);
  align-items: center;
  transform: translate(0%, -50%);
}

.input-field-label-focused {
  position: absolute;
  top: 0%;
  font-size: 12px;
  gap: var(--semantic-spacing-inline-100, 4px);
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.p {
  font-size: 35px;
}

.button-dev {
  align-self: stretch;
  padding-top: 5%;
}

.supporting-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 300;
}
</style>
