<script setup lang="ts">
import { PropType, defineProps } from 'vue';

interface Item {
  image: string | undefined;
  name: string;
}

defineProps({
  item: {
    type: Object as PropType<Item>,
  },
  canEdit: {
    type: Boolean,
    default: false,
    required: false
  },
  size: {
    type: String as PropType<'small' | 'large'>,
    default: "small",
    required: false
  }}
);

const emit = defineEmits(['on-edit']);

</script>

<template>
  <div class="avatar-container">
    <i class="bi bi-pencil-square edit-avatar" v-if="canEdit" @click="emit('on-edit')"></i>
    <div :class="`avatar avatar-${size}`">
      <img :src="item?.image" v-if="item?.image" />
      <p>{{ item?.name.substring(0, 1).toUpperCase() }}</p>
    </div>
  </div>
</template>

<style scoped>

.avatar-container {
  position: relative;
  display: inline-block;
}

.avatar {
  display: flex;
  padding: 12px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: #ECE1F4;

  color: var(--semantic-color-primary-default, #8300C7);
  font-size: 12px;
  font-weight: 800;
}

.edit-avatar {
  cursor: pointer;
  font-size: 12px;
  color: #999;
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 1;
}

.avatar-small {
  width: 35px;
  height: 35px;

  font-size: 12px;
}

.avatar-large {
  width: 88px;
  height: 72px;

  font-size: 24px;
}

</style>