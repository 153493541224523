import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { v4 as uuidv4 } from 'uuid';

@JsonObject()
export class AddressModel {
  @JsonProperty()
  private id: string;
  @JsonProperty('street')
  private street: string;
  @JsonProperty()
  private streetNumber: string;
  @JsonProperty()
  private complement?: string;
  @JsonProperty()
  private vicinity: string;
  @JsonProperty()
  private city: string;
  @JsonProperty()
  private country? = 'BRASIL';
  @JsonObject()
  private updatedAtUtc? = new Date();
  @JsonProperty()
  private state: string;
  @JsonProperty()
  private cep: string;
  @JsonProperty()
  private lat?: number | null;
  @JsonProperty()
  private long?: number | null;

  constructor(params: {
    id?: string;
    street: string;
    streetNumber: string;
    complement?: string;
    vicinity: string;
    city: string;
    country?: string;
    updatedAtUtc?: Date;
    state: string;
    cep: string;
    lat?: number;
    long?: number;
  }) {
    this.id = params.id ?? uuidv4();
    this.street = params.street;
    this.streetNumber = params.streetNumber;
    this.complement = params.complement;
    this.vicinity = params.vicinity;
    this.city = params.city;
    this.updatedAtUtc = params.updatedAtUtc ?? new Date();
    this.country = params.country ?? 'BRASIL';
    this.state = params.state;
    this.cep = params.cep;
    this.lat = params.lat;
    this.long = params.long;
  }

  public getId() {
    return this.id;
  }

  public getStreetNumber(): string {
    return this.streetNumber;
  }

  public setStreetNumber(streetNumber: string) {
    this.streetNumber = streetNumber;
  }

  public getUpdateAtUtc(): Date {
    return new Date();
  }

  public getStreet() {
    return this.street;
  }

  public setStreet(street: string) {
    this.street = street;
  }

  public getStreetFullName(): string {
    return `${this.street}, ${this.streetNumber}`
  }

  public getFullAddress(): string {
    return `${this.street}, ${this.streetNumber}, ${this.vicinity}, ${this.city}, ${this.state}, ${this.cep}`
  }

  public getComplement(): string {
    return `${this.complement}`
  }

  public getStreetAndStreetNumber() {
    return this.street + ', ' + this.streetNumber
  }

  public getCityAndState() {
   return this.city + '/' + this.state
  }

  public setComplement(complement: string) {
    this.complement = complement;
  }

  public getVicinity(): string {
    return this.vicinity;
  }

  public setVicinity(vicinity: string) {
    this.vicinity = vicinity;
  }

  public getCity(): string {
    return `${this.city}`
  }

  public setCity(city: string) {
    this.city = city;
  }

  public getState(): string {
    return `${this.state}`
  }

  public setState(state: string) {
    this.state = state;
  }

  static getStreetNameColumn(): string {
    return 'RUA';
  }

  static getStreetNumberColumn(): string {
    return 'NUMERO';
  }

  static getComplementColumn(): string {
    return 'COMPLEMENTO';
  }

  static getVicinityColumn(): string {
    return 'BAIRRO';
  }

  static getCityColumn(): string {
    return 'CIDADE';
  }

  static getUfColumn(): string {
    return 'ESTADO';
  }

  static getCepColumn(): string {
    return 'CEP';
  }

  public getLat() {
    return this.lat;
  }

  public getLong() {
    return this.long;
  }

  public getCep() {
    return this.cep;
  }

  public setCep(cep: string) {
    this.cep = cep;
  }
}