<script setup lang="ts">
import { ref } from 'vue';
import { BenefitsCardPlan } from '@/models/benefits_cards/benefits_card_plan_model';
import { ReqBenCardsRenewScreens, useReqRenewBenefitsCardPlansStore } from '@/stores/benefits_card_plans/request_renew_benefits_cards_plan_store';
import PatchBenCardsPlanScreen from './screens/PatchBenCardsPlanScreen.vue';
import ConfirmValueScreen from './screens/ConfirmValueScreen.vue';
import CommonModal from '../CommonModal.vue';
import PaymentScreen from './screens/PaymentScreen.vue';
import { MinimizedType } from '@/stores/minimized_modal_store/minimized_modal_store';

const commonModal = ref<InstanceType<typeof CommonModal>>();
const reqRenewBenCardsPlanStore = useReqRenewBenefitsCardPlansStore;

defineExpose({
  open: async (params: {
    zIndex?: number;
    benefitsCardsPlan: BenefitsCardPlan;
  }) => {
    if (reqRenewBenCardsPlanStore.benefitsCardsPlan?.getId() != params.benefitsCardsPlan.getId()) {
      reqRenewBenCardsPlanStore.$reset();
    }
    reqRenewBenCardsPlanStore.setBenefitPlan(params.benefitsCardsPlan);
    return await commonModal.value?.open({
      zIndex: params.zIndex ?? 50,
      name: `Renovação ${params.benefitsCardsPlan?.getPlanName()}`,
    });
  },
  close: () => {
    commonModal.value?.close();
  },
});

</script>

<template>
  <CommonModal
    ref="commonModal"
    :is-full-screen="true"
    :can-minimize="true"
    :minimized-data="{
      type: MinimizedType.BENEFITS_CARD,
    }"
  >
    <div class="req-renew-ben-cards-plan">
      <p class="main-title">Renovação de plano de cartão</p>
      <div style="height: var(--semantic-size-semantic-spacing-stack-600, 48px);"></div>
      
      <!-- INITIAL SCREEN -->
      <PatchBenCardsPlanScreen
        :show-screen="reqRenewBenCardsPlanStore.currentScreen == ReqBenCardsRenewScreens.INITIAL"
        @close="commonModal?.close()"
      />

      <!-- CONFIRM VALUE SCREEN -->
      <ConfirmValueScreen
        :show-screen="reqRenewBenCardsPlanStore.currentScreen == ReqBenCardsRenewScreens.VALUE_CONFIRMATION"
      />

      <!-- PAYMENT SCREEN -->
      <PaymentScreen
        :show-screen="reqRenewBenCardsPlanStore.currentScreen == ReqBenCardsRenewScreens.PAYMENT"
        @close="commonModal?.close()"
      />
    </div>
  </CommonModal>
</template>

<style scoped>

.main-title {
  color: var(--components-modal-color-default-title-color, #434049);

  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.req-renew-ben-cards-plan {
  padding: 0 8px;
}

</style>