import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "counter",
    style: _normalizeStyle({
      background: $props.isReverse ? `var(--options-color-neutral-white, #FFF)` : $setup.getColor(),
      color: $props.isReverse ? $setup.getColor() : `var(--options-color-neutral-white, #FFF)`,
    })
  }, [
    _createElementVNode("p", {
      style: _normalizeStyle({
      color: $props.isReverse ? $setup.getColor() : `var(--options-color-neutral-white, #FFF)`,
      fontSize: $props.hasSize ? `${$props.hasSize}px` : `12px`,
      fontWeight: $props.hasStroke ? 800 : 600,
    })
    }, _toDisplayString($props.count), 5 /* TEXT, STYLE */),
    _createVNode($setup["FontAwesomeIcon"], {
      class: "icon",
      style: _normalizeStyle({
        color: $props.isReverse ? $setup.getColor() : `var(--options-color-neutral-white, #FFF)`,
        width: $props.hasSize ? `${$props.hasSize-4}px` : `var(--semantic-size-icons-100, 8px)`,
        height: $props.hasSize ? `${$props.hasSize-4}px` : `var(--semantic-size-icons-100, 8px)`,
      }),
      icon: $setup.Icons.imported.faUser
    }, null, 8 /* PROPS */, ["style", "icon"])
  ], 4 /* STYLE */))
}