import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { Resource } from "./resources_enum";

@JsonObject()
export class Permission {
  @JsonProperty()
  id?: string;
  @JsonProperty()
  hrAdminId?: string;
  @JsonProperty()
  resource: Resource;
  @JsonProperty()
  read: boolean;
  @JsonProperty()
  write: boolean;
  @JsonProperty()
  delete: boolean;
  @JsonProperty()
  createdBy?: string;
  @JsonProperty()
  createdAtUtc?: Date;

  constructor(params?: {
    id?: string;
    hrAdminId?: string;
    resource: Resource;
    read: boolean;
    write: boolean;
    delete: boolean;
    createdBy?: string;
    createdAtUtc?: Date;
  }) {
    if (!params) return;
    this.id = params.id;
    this.hrAdminId = params.hrAdminId;
    this.resource = params.resource;
    this.read = params.read;
    this.write = params.write;
    this.delete = params.delete;
    this.createdBy = params.createdBy;
    this.createdAtUtc = params.createdAtUtc;
  }

  static fromResource(resource: Resource, type: "read" | "write" | "delete") {
    return new Permission({
      resource: resource,
      read: type === "read",
      write: type === "write",
      delete: type === "delete",
    })
  }

  isSufficient(...permissions: Permission[]) {
    return permissions.some(permission => {
      if (this.resource !== permission.resource) return false;
      if (this.read && permission.read) return true;
      if (this.write && permission.write) return true;
      if (this.delete && permission.delete) return true;
      return false;
    });
  }

  public getHrAdminId() {
    return this.hrAdminId;
  }
}