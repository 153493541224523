import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { AdminUser } from "./admins/admin_model";

@JsonObject()
export class LoginResponseModel {
  @JsonProperty()
  private token: string;
  @JsonProperty({ type: AdminUser })
  private user: AdminUser;
  @JsonProperty()
  private isTwoFactorAuthorized: boolean;
  @JsonProperty()
  private expiresAt: number;

  constructor(params: {
    token: string;
    user: AdminUser;
    isTwoFactorAuthorized: boolean;
    expiresAt: number;
  }) {
    this.token = params.token;
    this.user = params.user;
    this.isTwoFactorAuthorized = params.isTwoFactorAuthorized;
    this.expiresAt = params.expiresAt;
  }

  public getToken() {
    return this.token;
  }

  public getUser() {
    return this.user;
  }

  public getIsTwoFactorAuthorized() {
    return this.isTwoFactorAuthorized;
  }

  public getExpiresAt(): Date {
    return new Date(this.expiresAt);
  }
}