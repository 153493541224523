<script setup lang="ts">
import { defineProps } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BasketPlan } from '@/models/basket_plans/basket_plan_model';
 defineProps<{
  basketPlan?: BasketPlan;
}>();

</script>
<template>
  <div class="status-plan-container" >
    <div class="status-plan" :style="{color:basketPlan!.getTextColor(),background:basketPlan!.getBackGroundColor()}">
      <FontAwesomeIcon :fixed-width="true" :style="{
        width: '12px',
        height: '12px',
        color:basketPlan!.getTextColor()
      }" :icon="basketPlan!.getStatusIcon()" />
      <p >{{ basketPlan!.getStatusPlan() + ' ' + basketPlan?.getMonthString() }} </p>
    </div>
  </div>
</template>

<style scoped>
.status-plan {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  width: fit-content;
  font-size: 12px;
}

.status-plan-container {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

</style>