<script setup lang="ts">
import { provide, ref } from 'vue';
import { RouterView } from 'vue-router';
import AlertModal from './components/modals/AlertModal.vue';
import LoadingModal from './components/modals/LoadingModal.vue';
import LoadingModalV2 from './components/modals/LoadingModalV2.vue';
import MinimizedModal from './components/minimized_modal/MinimizedModal.vue';
import RequestRenewBasketPlanModal from './components/modals/RequestRenewBasketPlanModal.vue';
import RequestRenewBenefitsCardsPlan from './components/modals/request_renew_benefits_cards_plan_modal/RequestRenewBenefitsCardsPlan.vue';
import { AlertModalInjection, CommonToastInjection, LoadingModalV2Injection, LoadingModalInjection, TwoFactorAuthModalInjection, ReqRenBenfitsCardsPlanInjection, ReqRenBasketPlanModalInjection, WelcomeModalInjection, AlertModalInjectionV2, LinkUsersModalInjection } from './infra/constants/injections';
import TwoFactorAuthModal from './components/modals/TwoFactorAuthModal.vue';
import './registerServiceWorker';
import CommonToast from './components/CommonToast.vue';
import WelcomeModal from './components/modals/WelcomeModal.vue';
import AlertModalV2 from './components/modals/AlertModalV2.vue';
import LinkUsersModal from './components/modals/LinkUsersModal.vue';

const alertModalV2 = ref<InstanceType<typeof AlertModalV2>>();
const alertModal = ref<InstanceType<typeof AlertModal>>();
const loadingModal = ref<InstanceType<typeof LoadingModal>>();
const loadingModalV2 = ref<InstanceType<typeof LoadingModal>>();
const commonToast = ref<InstanceType<typeof CommonToast>>();
const twoFactorAuthModal = ref<InstanceType<typeof TwoFactorAuthModal>>();
const requestRenewBasketPlanModal = ref<InstanceType<typeof RequestRenewBasketPlanModal>>();
const requestRenewBenefitsCardsPlan = ref<InstanceType<typeof RequestRenewBenefitsCardsPlan>>();
const welcomeModal = ref<InstanceType<typeof WelcomeModal>>();
const linkUsersModal = ref<InstanceType<typeof LinkUsersModal>>();

provide(AlertModalInjectionV2, alertModalV2);
provide(AlertModalInjection, alertModal);
provide(LoadingModalInjection, loadingModal);
provide(LoadingModalV2Injection, loadingModalV2);
provide(CommonToastInjection, commonToast);
provide(TwoFactorAuthModalInjection, twoFactorAuthModal);
provide(ReqRenBasketPlanModalInjection, requestRenewBasketPlanModal);
provide(ReqRenBenfitsCardsPlanInjection, requestRenewBenefitsCardsPlan);
provide(WelcomeModalInjection, welcomeModal);
provide(LinkUsersModalInjection, linkUsersModal);

</script>

<template>
  <AlertModal ref="alertModal" />
  <AlertModalV2 ref="alertModalV2" />
  <LoadingModal ref="loadingModal" />
  <LoadingModalV2 ref="loadingModalV2" />
  <CommonToast ref="commonToast" />
  <TwoFactorAuthModal ref="twoFactorAuthModal"/>
  <RequestRenewBasketPlanModal ref="requestRenewBasketPlanModal" />
  <RequestRenewBenefitsCardsPlan ref="requestRenewBenefitsCardsPlan" />

  <MinimizedModal />
  <WelcomeModal ref="welcomeModal"/>
  <!-- <LinkUsersModal ref="linkUsersModal"/> -->

  <RouterView />
</template>

<style scoped>
</style>
