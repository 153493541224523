import { Icons } from "@/font-awesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export enum PaymentMethodTypes {
  TICKET = 1,
  PIX = 2,
}

export class PaymentMethodUtils {

  static getName(type: PaymentMethodTypes) {
    switch (type) {
      case PaymentMethodTypes.TICKET:
        return 'Boleto';
      case PaymentMethodTypes.PIX:
        return 'Pix';
    }
  }

  static getIcon(type: PaymentMethodTypes): IconDefinition {
    switch (type) {
      case PaymentMethodTypes.TICKET:
        return Icons.imported.faFileInvoiceDollar;
      case PaymentMethodTypes.PIX:
        return Icons.imported.faPix;
    }
  }
  
}