<script setup lang="ts">
import { inject, onMounted, ref, Ref, shallowRef } from 'vue';
import { useRouter } from 'vue-router';
import { AlertModalInjection, LoadingModalInjection } from '@/infra/constants/injections';
import { useTwoFactorAuthStore } from '@/stores/two_factor_auth/two_factor_auth_store';
import NavigatorController from '@/components/NavigatorController.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import CodeTwoFactorContent from '@/components/two_factor_auth/CodeTwoFactorContent.vue';
import QRCodeTwoFactorContent from '@/components/two_factor_auth/QRCodeTwoFactorContent.vue';
import WelcomeTwoFactorContent from '@/components/two_factor_auth/WelcomeTwoFactorContent.vue';
import { AdminUser } from '@/models/admins/admin_model';
import PersonalInformationContent from '../two_factor_auth/PersonalInformationContent.vue';
import CommonModal from './CommonModal.vue';

enum Pages {
  Welcome = 1,
  QRCode = 2,
  Code = 3,
  Personal = 4
}

const twoFactorAuthStore = useTwoFactorAuthStore();
const router = useRouter();
const user = ref<AdminUser | null>(null);

const commonModal = ref<InstanceType<typeof CommonModal>>();
const loadingModal = inject<Ref<InstanceType<typeof LoadingModal>>>(LoadingModalInjection);
const alertModal = inject<Ref<InstanceType<typeof AlertModal>>>(AlertModalInjection);

const currentPage = ref(1);
const zIndexRef = ref<number>(2);
const promise = ref<Promise<any>>();
const resolve = ref<(value: any) => void>();
const redirectTo = ref<string | null>(null);
const onCancel = ref<(() => void) | null>();
let components = ref({});

defineExpose({
  open: open,
  close: closeButton,
});

async function open<T>(params: {
  zIndex?: number,
  whenSubmitRedirectTo?: string,
  user: AdminUser,
  code?: string,
  isInitial?: boolean,
  onCancel?: () => void,
}) {
  if (!params.user?.getHasTwoFactorKey()) {
    currentPage.value = Pages.Welcome;
    components.value = {
      [Pages.Welcome]: shallowRef(WelcomeTwoFactorContent),
      [Pages.QRCode]: shallowRef(QRCodeTwoFactorContent),
      [Pages.Code]: shallowRef(CodeTwoFactorContent),
      [Pages.Personal]: shallowRef(PersonalInformationContent),
    }
  }
  else {
    currentPage.value = Pages.Code;
    components.value = {
      [Pages.Welcome]: shallowRef(WelcomeTwoFactorContent),
      [Pages.QRCode]: shallowRef(QRCodeTwoFactorContent),
      [Pages.Code]: shallowRef(CodeTwoFactorContent),
    }
  }
  onCancel.value = params.onCancel ?? null;
  redirectTo.value = params.whenSubmitRedirectTo ?? null;
  user.value = params.user;
  commonModal.value?.open({
    zIndex: params.zIndex ?? 2,
  });
  zIndexRef.value = params?.zIndex ?? 2;
  promise.value = new Promise<T>((res) => resolve.value = res);
  return await promise.value;
}

async function closeButton<T>(value?: T) {
  commonModal.value?.close();
  resolve.value?.call(null, value);
}

async function submit() {
  if(redirectTo.value) await router.push({ name: redirectTo.value })
  closeButton();
}

function handleCodeInput(value: string) {
  twoFactorAuthStore.setCode(value );
}

async function initialize(enable?: boolean) {
  try {
    loadingModal?.value?.open({ zIndex: (zIndexRef.value + 1) } );
    const res = await twoFactorAuthStore.initialize({ enable });
    if (res.isError()) alertModal?.value?.open({ zIndex: (zIndexRef.value + 1), title: 'Erro', content: res.getError() ?? 'Erro desconhecido' });
    else currentPage.value = currentPage.value + 1;
  } finally {
    loadingModal?.value?.close();
  }
}

function backButton() {
  const isFirstPage = (currentPage.value == Pages.Code && user.value?.getHasTwoFactorKey()) || currentPage.value == Pages.Welcome;
  if (isFirstPage) {
    if (onCancel.value) onCancel.value?.call(null);
    else closeButton();
    return;
  }
  currentPage.value = currentPage.value - 1;
}

onMounted(() => {
  window.onbeforeunload = function (event) {
    event.preventDefault();
    event.returnValue = 'Texto escolhido pelo João que perguntará se o usuário tem certeza que vai travar a propria conta bloqueando o 2FA';
  };
});

</script>

<template>
  <CommonModal :can-close="false" :is-full-screen="true" ref="commonModal">
    <div class="two-factor-auth-modal-card">
      <!-- <NavigatorController
        v-show="!user?.getHasTwoFactorKey()"
        :currentPage="currentPage"
        :totalPages="Object.keys(components).length"
      /> -->
      <div style="height: 40px;"></div>
      <div class="two-factor-auth-modal-content">
        <component 
          :is="components[currentPage]" 
          :admin-user="user" 
          :current-page="currentPage" 
          @change-page="(page: number) => {
            currentPage = page;
            if (currentPage > Object.values(components).length) {
              submit();
            }
          }"
          :secret="twoFactorAuthStore.secret"
          :qr-code-url="twoFactorAuthStore.qrCodeUrl"
          @on-initialize="initialize"
          @on-submit=""
          @on-code-input-change="(value: string) => handleCodeInput(value)"
        />
      </div>
    </div>
  </CommonModal>
</template>

<style scoped>

.two-factor-auth-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: var(--semantic-color-bg-subtle, #F6F5F9);
  display: none;
}

.two-factor-auth-modal.open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-factor-auth-modal-card {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;

  border-radius: calc(var(--components-button-spacing-large-padding-horizontal-with-icon, 20px) * 1px);
  background: var(--semantic-color-bg-surface, #FFF);
  box-shadow: 0 4px 16px 3px rgba(81, 77, 85, 0.07);
  width: 600px;
  max-width: 90%;
  height: 640px;
}

.two-factor-auth-modal-content {
  width: 100%;
  max-width: 392px;
  height: 100%;
}

.back-btn {
  position: absolute;
  width: 16px;
  top: 40px;
  left: 40px;
  transform: translate(0%, 0%);
  z-index: 3;
  cursor: pointer;
  color: var(--token-modal-color-close-icon-color);
}

@media screen and (max-width: 420px) {
  .back-btn {
    left: 32px;
  }
}
</style>
