import FormatUtils from "@/utils/format_utils";
import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class RequestRenew {
  @JsonProperty()
  cardPlanIds?: string[];
  @JsonProperty()
  userIds?: string[];
  
  @JsonProperty()
  expectedValue?: number;
  @JsonProperty()
  calculate: boolean;

  constructor(params?: {
    cardPlanIds?: string[];
    userIds?: string[];
    expectedValue?: number;
    calculate: boolean;
  }) {
    if (!params) return;
    this.cardPlanIds = params.cardPlanIds;
    this.userIds = params.userIds;
    this.expectedValue = params.expectedValue;
    this.calculate = params.calculate;
  }
}