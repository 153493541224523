import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { AddressModel } from "./address_model";
import { v4 as uuidv4 } from 'uuid';
import { Coordinate } from "./coordinate_model";

@JsonObject()
export class DeliveryAreaModel {
  @JsonProperty()
  private id: string;
  @JsonProperty()
  private nickname: string;
  @JsonProperty()
  private cnpj: string;
  @JsonProperty()
  private childCompanyId: string;
  @JsonProperty({ type: Coordinate })
  private coordinates: Coordinate[];
  @JsonProperty()
  private imgUrl: string | null;

  @JsonProperty({ type: AddressModel, })
  private address: AddressModel;

  constructor(params?: {
    id?: string;
    nickname: string;
    cnpj: string;
    childCompanyId: string;
    address: AddressModel;
    coordinates: Coordinate[];
    imgUrl?: string | null;
  }) {
    if (!params) return;
    this.id = params.id ?? uuidv4();
    this.nickname = params.nickname;
    this.cnpj = params.cnpj;
    this.childCompanyId = params.childCompanyId;
    this.address = params.address;
    this.coordinates = params.coordinates;
    this.imgUrl = params.imgUrl ?? null;
  }

  public getId() {
    return this.id;
  }

  public getNickname() {
    return this.nickname;
  }

  public getCnpj() {
    return this.cnpj;
  }

  public getChildCompanyId() {
    return this.childCompanyId;
  }

  public getAddress() {
    return this.address;
  }

  public getCoordinates() {
    return this.coordinates;
  }

  public getImgUrl() {
    return this.imgUrl;
  }

  public setImgUrl(imgUrl: string | null) {
    this.imgUrl = imgUrl;
  }
}