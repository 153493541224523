export class OpenModalsGlobal {
  private static modalCounter = 0;

  public static getModalCounter() {
    return this.modalCounter;
  }

  public static incrementModalCounter() {
    this.modalCounter++;
    if (OpenModalsGlobal.getModalCounter() > 0) document.body.style.overflow = 'hidden';
  }

  public static decrementModalCounter() {
    this.modalCounter--;
    if (OpenModalsGlobal.getModalCounter() <= 0) document.body.style.overflow = 'auto';
  }
}