import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["CreateBranchModal"], { ref: "createBranchModal" }, null, 512 /* NEED_PATCH */),
    _createVNode($setup["CustomSelector"], {
      ref: "customSelector",
      icon: $setup.Icons.imported.faLocationDot,
      "empty-message": "Endereço de entrega da sede",
      options: $props.branches.getBranches().map((branch, index) => ({
      title: $props.branches.getIndex(index)?.branch?.getName() ?? 'Title',
      subtitle: $props.branches.getIndex(index)?.address?.getFullAddress(),
    })),
      button: {
      icon: $setup.Icons.imported.faPlus,
      text: 'Criar nova sede',
      click: (_) => {
        $setup.createBranchModal?.open({
          zIndex: $props.zIndex,
          onCreate: (newBranch) => {
            if (newBranch) {
              _ctx.$emit('create', null, newBranch);
            }
            $setup.createBranchModal?.close();
          }
        });
      },
    },
      "no-options-message": "Você ainda não possui nenhuma sede cadastrada",
      onChange: _cache[0] || (_cache[0] = (evt, index) => _ctx.$emit('change', evt, index))
    }, null, 8 /* PROPS */, ["icon", "options", "button"])
  ], 64 /* STABLE_FRAGMENT */))
}