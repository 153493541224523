import { JsonObject, JsonProperty } from "typescript-json-serializer";

@JsonObject()
export class TwoFactorAuthInitializeModel {

  @JsonProperty()
  secret: string;

  @JsonProperty()
  qrCodeUrl: string;

  constructor(params: {
    secret: string,
    qrCodeUrl: string,
  }) {
    if (!params) return;
    this.secret = params.secret;
    this.qrCodeUrl = params.qrCodeUrl;
  }
}