<script setup lang="ts">
import { Icons } from '@/font-awesome';
import { IMinimized, MinimizedType, MinimizedTypeUtils } from '@/stores/minimized_modal_store/minimized_modal_store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BeanPattern from './BeanPattern.vue';

defineProps<{
  minimized: IMinimized,
}>();

</script>

<template>
  <div class="minimized-modal-card">
    <BeanPattern :color="MinimizedTypeUtils.getColor(minimized.type)" class="blob" />
    <img width="18" v-if="minimized.type == MinimizedType.BASKET" src="@/assets/icons/basket_icon.svg" alt="Basket Icon" />
    <img width="18" v-if="minimized.type == MinimizedType.BENEFITS_CARD" src="@/assets/icons/credit_card_plan.svg" alt="Basket Icon" />
    <div style="width: var(--semantic-size-semantic-spacing-inline-150, 12px);"></div>
    <p class="title">{{ minimized.name }}</p>
    <div style="width: var(--semantic-size-semantic-spacing-inline-300, 24px);"></div>
    <FontAwesomeIcon
      @click="minimized.onMaximize?.call(null)"
      class="maximize-icon"
      :icon="Icons.imported.faUpRightAndDownLeftFromCenter"
    />
  </div>
</template>

<style scoped>

.blob {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: var(--semantic-size-semantic-border-radius-default, 7px);
  height: 100%;
}

.minimized-modal-card {
  display: flex;
  padding: var(--semantic-size-semantic-spacing-inset-200, 16px);
  padding-left: var(--semantic-size-semantic-spacing-inset-400, 32px);
  align-items: center;
  border-radius: var(--semantic-size-semantic-border-radius-default, 7px);
  background: var(--semantic-color-bg-overlay, #FFF);
  box-shadow: 0px 0px 6px 0px rgba(81, 77, 85, 0.40);

  height: fit-content;
  width: fit-content;
  
  pointer-events: all;
}

.maximize-icon {
  cursor: pointer;
  color: var(--semantic-color-fg-muted, #6E6979);
}

.title {
  color: var(--semantic-color-fg-default, #5D5867);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

</style>