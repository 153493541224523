import { JsonSerializer } from "typescript-json-serializer";
import BffFoodPassBackofficeApiRequester from "../bff_backoffice_api_requester";
import { AdminUser } from "@/models/admins/admin_model";
import { ValueResult } from "@/models/value-result";
import { TwoFactorAuthInitializeModel } from "@/models/two_factor_auth/two_factor_auth_initialize_model";
import { LoginResponseModel } from "@/models/login_response_model";

export class TwoFactorAuthBFFBackofficeModule {

  static readonly controller = "two-factor-auth";
  static readonly serializer = new JsonSerializer();

  static async initializeTwoFactorAuth(params: {
    enable: boolean
  }): Promise<ValueResult<TwoFactorAuthInitializeModel | null>> {
    try {
      const json = this.serializer.serializeObject(params);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}`, json);
      const twoFactorInitialize  = this.serializer.deserializeObject(res.data, TwoFactorAuthInitializeModel) as TwoFactorAuthInitializeModel;
      return new ValueResult({
        value: twoFactorInitialize
      });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

  static async verifyTwoFactorAuth(params: {
    code: string
  }): Promise<ValueResult<LoginResponseModel | null>> {
    try {
      const json = this.serializer.serializeObject(params);
      const res = await BffFoodPassBackofficeApiRequester.getAxios().post(`/${this.controller}/verify`, json);
      const value  = this.serializer.deserializeObject(res.data, LoginResponseModel);
      return new ValueResult({ value });
    } catch(e: any) {
      return ValueResult.fromError(e);
    }
  }

}